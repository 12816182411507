import { Grid, Typography, Card, Avatar, TextField, Divider, Accordion, AccordionDetails, AccordionSummary, Skeleton, InputAdornment, IconButton } from '@mui/material';
import { Formik } from 'formik';
import { CustomFormFooter } from '../../components/common/CustomForm/CustomFormFooter';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthStore } from '../../hooks';
import { Role } from '../../types/roles/roleTypes';
import { useOwnerStore } from '../../hooks/useOwnerStore';
import { useUserStore } from '../../hooks/UseUserStore';
import { useAgentStore } from '../../hooks/useAgentStore';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ModalIdentification } from './ModalIdentification';

import '../../assets/styles/pewen-app.css';
import { IdentificationModal } from './IdentificationModal';

type Values = {
    userType: string,
    name: string,
    rut: string,
    phone: string,
    email: string,
    address: string,
    pin: string,
    password: string,
    pintext: string,
    identification_status: string,
    identification: string,
}

export const MyProfileView = () => {

    const { findOwner, findTenant, findInvitedByOwner, editOwner, editInvitedByOwner } = useOwnerStore();
    const { findAdmin, editAdmin, changePIN, createPIN } = useUserStore();
    const { findAgent, editAgent } = useAgentStore();

    const { user } = useAuthStore()
    const datalogin:any = user || ''
    const dataUser:any = user?.data || ''
    const role:any = user?.userType || ''

    const [withPin, setWithPin] = useState<boolean>(false);
    const [withIdentification, setWithIdentification] = useState<any>('');

    //states for general dates
    const [edit, setEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadEdit, setLoadEdit] = useState<boolean>(false);

    //states for pin
    const [editpin, setEditpin] = useState<boolean>(false);
    const [loadEditpin, setLoadEditpin] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPin, setShowPin] = useState<boolean>(false);
    
    const [showModalIdentification, setShowModalIdentification] = useState<boolean>(false);
    const [editIdentification, setEditIdentification] = useState<boolean>(false);

    const [data, setData] = useState<Values>({
        userType: '',
        name: '',
        rut: '',
        phone: '',
        email: '',
        address: '',
        pin: '',
        password:'',
        pintext:'',
        identification_status:'',
        identification: '',
    });

    useEffect(()=>{
        datausersession(role, dataUser.id)
    }, [datalogin])
  
    const getInvitedByOwnerApi = async(idinvitedbyowner) => {
        try {
          setLoading(true);
          const response = await findInvitedByOwner(idinvitedbyowner);
          if(response){
            setLoading(false);
            if(response.pin && response.pin != '') setWithPin(true);
            if(response.identification_status && response.identification_status != '') setWithIdentification(response.identification_status);
            setData({
                ...response,
                userType: datalogin ? datalogin.userType : '',
                name: response ? response.name : '',
                rut: response ? response.rut : '',
                phone: response ? response.phone : '',
                email: response ? response.email : '',
                address: response ? response.address : '',
                pin: response ? response.pin : '',
                pintext: response ? response.signing : '',
                password: response ? response.password : '',
                identification_status: response ? response.identification_status : '',
                identification: response ? response.identification : '',
            })
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const getOwnerApi = async(idowner) => {
        try {
          setLoading(true);
          const response = await findOwner(idowner);
          if(response){
            setLoading(false);
            if(response.pin && response.pin != '') setWithPin(true);
            if(response.identification_status && response.identification_status != '') setWithIdentification(response.identification_status);
            setData({
                userType: datalogin ? datalogin.userType : '',
                name: response ? response.name : '',
                rut: response ? response.rut : '',
                phone: response ? response.phone : '',
                email: response ? response.email : '',
                address: response ? response.address : '',
                pin: response ? response.pin : '',
                pintext: response ? response.signing : '',
                password: response ? response.password : '',
                identification_status: response ? response.identification_status : '',
                identification: response ? response.identification : '',
            })
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const getAdminApi = async(idadmin) => {
        try {
          setLoading(true);
          const response = await findAdmin(idadmin);
          if(response){
            setLoading(false);
            setData({
                userType: datalogin ? datalogin.userType : '',
                name: response ? response.name : '',
                rut: response ? response.rut : '',
                phone: response ? response.phone : '',
                email: response ? response.email : '',
                address: '',
                pin: '',
                pintext:'',
                password:'',
                identification_status: response ? response.identification_status : '',
                identification: response ? response.identification : '',
            })
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const getTenantApi = async(idowner) => {
        try {
          setLoading(true);
          const response = await findTenant(idowner);
          if(response){
            setLoading(false);
            if(response.pin && response.pin != '') setWithPin(true);
            if(response.identification_status && response.identification_status != '') setWithIdentification(response.identification_status);
            setData({
                userType: datalogin ? datalogin.userType : '',
                name: response ? response.name : '',
                rut: response ? response.rut : '',
                phone: response ? response.phone : '',
                email: response ? response.email : '',
                address: response ? response.address : '',
                pin: response ? response.pin : '',
                pintext: response ? response.signing : '',
                password: response ? response.password : '',
                identification_status: response ? response.identification_status : '',
                identification: response ? response.identification : '',
            })
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const getAgentApi = async(idowner) => {
        try {
          setLoading(true);
          const response = await findAgent(idowner);
          if(response){
            setLoading(false);
            if(response.pin && response.pin != '') setWithPin(true);
            if(response.identification_status && response.identification_status != '') setWithIdentification(response.identification_status);
            setData({
                userType: datalogin ? datalogin.userType : '',
                name: response ? response.name : '',
                rut: response ? response.rut : '',
                phone: response ? response.phone : '',
                email: response ? response.email : '',
                address: response ? response.address : '',
                pin: response ? response.pin : '',
                pintext: response ? response.signing : '',
                password: response ? response.password : '',
                identification_status: response ? response.identification_status : '',
                identification: response ? response.identification : '',
            })
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const datausersession = (typeuser, id) => {
        switch (typeuser) {
            case Role.SUPER_ADMIN:
                setData({
                    userType: datalogin ? datalogin.userType : '',
                    name: '',
                    rut: '',
                    phone: '',
                    email: '',
                    address: '',
                    pin: '',
                    password:'',
                    pintext: '',
                    identification_status: '',
                    identification: '',
                })
              break
            case Role.ADMIN:
                getAdminApi(id)
              break
            case Role.OWNER:
                getOwnerApi(id)
              break
            case Role.TENANT:
                getTenantApi(id)
              break
            case Role.AGENT:
                getAgentApi(id)
              break
            case Role.FAMILY:
                getInvitedByOwnerApi(id)
              break
            default:
              break
        }
    }

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.email) errors.email = "correo requerido";
        if(!values.phone) errors.phone = "telefono requerido";
        return errors;
    }

    const onSubmit = async(values)  => {
        if(role == Role.ADMIN){
            setLoadEdit(true)
            let res = await editAdmin(dataUser.id, {...values, idcompany: dataUser.idcompany})
            if(res) getAdminApi(dataUser.id)
            setLoadEdit(false)
        }
        if(role == Role.OWNER){
            setLoadEdit(true)
            const res = await editOwner(dataUser.id, {...values, privilege: '1'})
            if(res) getOwnerApi(dataUser.id)
            setLoadEdit(false)
        }
        if(role == Role.TENANT){
            setLoadEdit(true)
            const res = await editOwner(dataUser.id, {...values, privilege: '2'})
            if(res) getTenantApi(dataUser.id)
            setLoadEdit(false)
        }
        if(role == Role.AGENT){
            setLoadEdit(true)
            const res = await editAgent(dataUser.id, values)
            if(res) getAgentApi(dataUser.id)
            setLoadEdit(false)
        }
        if(role == Role.FAMILY){
          setLoadEdit(true)
          const getData:any = data;
            
          const res = await editInvitedByOwner(dataUser.id, {...values, idowner: getData?.idowner, idguest_type: getData?.idguest_type })
          if(res) getInvitedByOwnerApi(dataUser.id)
          setLoadEdit(false)
        }
        setEdit(false)
    }

    const validateFormPIN = (values) => {
        let errors:any = {};
        if(!values.pin) errors.pin = "PIN requerido";
        if(!values.password) errors.password = "correo requerido";
        return errors;
    }

    const onSubmitPIN = async(values)  => {
        setLoadEditpin(true)
        if(withPin){
            let res = await changePIN({pin: values.pin, password:values.password})
            if(res){
                switch (role) {
                    case Role.OWNER:
                        getOwnerApi(dataUser.id)
                        setLoadEditpin(false)
                    break;
                    case Role.TENANT:
                        getTenantApi(dataUser.id)
                        setLoadEditpin(false)
                    break;
                    case Role.AGENT:
                        getAgentApi(dataUser.id)
                        setLoadEditpin(false)
                        break;
                }
                setEditpin(false)
            }else{
                setLoadEditpin(false)
                return false;
            }
        }else{
            let res = await createPIN({pin: values.pin, password:values.password, signing: values.pintext})
            if(res){
                switch (role) {
                    case Role.OWNER:
                        getOwnerApi(dataUser.id)
                        setLoadEditpin(false)
                    break;
                    case Role.TENANT:
                        getTenantApi(dataUser.id)
                        setLoadEditpin(false)
                    break;
                    case Role.AGENT:
                        getAgentApi(dataUser.id)
                        setLoadEditpin(false)
                        break;
                }
                setEditpin(false)
            }else{
                setLoadEditpin(false)
                return false;
            }
        }
        
        setLoadEditpin(false)
        setEditpin(false)
    }

    const handleClickShowPin = () => {
        if(showPin) setShowPin(false)
        if(!showPin) setShowPin(true)
    }
    const handleMouseDownPin = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleClickShowPassword = () => {
        if(showPassword) setShowPassword(false)
        if(!showPassword) setShowPassword(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    return (
        <>
        <Grid container  sx={{padding:'30px 30px 30px 30px'}}>
            <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                <Card sx={{ width: '700px', display:'flex', justifyContent:'start', alignItems:'center', border:'solid 1px #808080', padding:'10px' }}>
                    <Grid sx={{mr:3}}>
                        <Avatar
                            alt="Remy Sharp"
                            src=""
                            sx={{ width: 56, height: 56 }}
                        />
                    </Grid>
                    <Grid sx={{width: '100%'}}>
                        <Grid xs={12}>
                            {
                                !loading ? (
                                    <Typography color='#212D39' sx={{ fontSize:24, fontWeight:600}}>
                                        {role == Role.SUPER_ADMIN ? 'Super Administrador' : data.name || '' }
                                    </Typography>
                                ) : (
                                    <Skeleton width="100%" />
                                )
                            }
                            
                        </Grid>
                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'Modifica los datos relacionados a tu perfil'}</Typography></div>
                    </Grid>
                </Card>
            </Grid>

            <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                {
                    !loading && withIdentification == '' && (
                        <div><Typography className='text-info-red'>{'Ud. aún no ha enviado sus documentos de verificación.'}</Typography></div>
                    )
                }

                {
                    !loading && withIdentification === '0' && (
                        <div><Typography className='text-info-red'>{'El estado de verificación de sus documentos de identidad se encuentra pendiente.'}</Typography></div>
                    )
                }


                {
                    !loading && withIdentification == '3' && (
                        <div><Typography className='text-info-red'>{'Su solictud ha sido rechazada. Por favor vuelva ha subir sus documentos.'}</Typography></div>
                    )
                }

                {
                    !loading && withIdentification == '1' && !withPin && (
                        <div><Typography className='text-info-red'>{'No cuenta con un PIN activo.'}</Typography></div>
                    )
                }

            </Grid>

            <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                <Accordion  sx={{width:'700px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Mis Datos</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                            {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container sx={{padding:'20px'}}>

                                            {
                                                role != Role.SUPER_ADMIN && (
                                                    <>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Nombre:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="name"
                                                                        variant="standard"
                                                                        type="text" 
                                                                        name="name"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.name}
                                                                        onChange={handleChange}
                                                                        placeholder={"name"}
                                                                        error={errors.name && touched.name ? true : false}
                                                                        helperText={errors.name && touched.name ? errors.name: ''}
                                                                        inputProps={{ readOnly: edit ? false : true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Rut:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="rut"
                                                                        variant="standard"
                                                                        type="text" 
                                                                        name="rut"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.rut}
                                                                        onChange={handleChange}
                                                                        placeholder={"rut"}
                                                                        error={errors.rut && touched.rut ? true : false}
                                                                        helperText={errors.rut && touched.rut ? errors.rut: ''}
                                                                        inputProps={{ readOnly: true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Correo:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="email"
                                                                        variant="standard"
                                                                        type="email" 
                                                                        name="email"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.email}
                                                                        onChange={handleChange}
                                                                        placeholder={"email"}
                                                                        error={errors.email && touched.email ? true : false}
                                                                        helperText={errors.email && touched.email ? errors.email: ''}
                                                                        inputProps={{ readOnly: edit ? false : true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Teléfono:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="phone"
                                                                        variant="standard"
                                                                        type="number" 
                                                                        name="phone"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.phone}
                                                                        onChange={(e) => {
                                                                            const regex = /^[0-9\b]+$/;
                                                                            if (e.target.value === "" || regex.test(e.target.value)) {
                                                                              setFieldValue('phone', e.target.value)
                                                                            }
                                                                        }}
                                                                        placeholder={"phone"}
                                                                        error={errors.phone && touched.phone ? true : false}
                                                                        helperText={errors.phone && touched.phone ? errors.phone: ''}
                                                                        inputProps={{ readOnly: edit ? false : true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Dirección:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="address"
                                                                        variant="standard"
                                                                        type="number" 
                                                                        name="address"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.address}
                                                                        onChange={handleChange}
                                                                        placeholder={"address"}
                                                                        error={errors.address && touched.address ? true : false}
                                                                        helperText={errors.address && touched.address ? errors.address: ''}
                                                                        inputProps={{ readOnly: edit ? false : true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    </>
                                                )
                                            }
                                            
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <Typography sx={{ width: '60%', flexShrink: 0 }}>Tipo de usuario:</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                {
                                                    !loading ? (
                                                        <TextField
                                                            id="userType"
                                                            variant="standard"
                                                            type="text" 
                                                            name="userType"
                                                            fullWidth
                                                            value={values.userType}
                                                            onChange={handleChange}
                                                            placeholder={"userType"}
                                                            error={errors.userType && touched.userType ? true : false}
                                                            helperText={errors.userType && touched.userType ? errors.userType: ''}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                    ) : (
                                                        <Skeleton width="100%" />
                                                    )
                                                }
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Divider />

                                        {
                                            (edit && role != Role.SUPER_ADMIN && !loadEdit) && (
                                                <CustomFormFooter
                                                    buttonType="submit"
                                                    confirmText={'Guardar'}
                                                    onConfirm={handleSubmit}
                                                    cancelText={"Descartar"}
                                                    onCancel={()=>{setEdit(false)}}
                                                />
                                            )
                                        }
                                        {
                                            (!edit && role != Role.SUPER_ADMIN && !loadEdit) && (
                                                <CustomFormFooter 
                                                    buttonType="submit"
                                                    secondaryText={"Editar mis datos"}
                                                    onSecondaryConfirm={()=>{setEdit(true)}}
                                                />
                                            )
                                        }
                                        {
                                            loadEdit && (
                                                <Grid sx={{width: '100%', textAlign:'end', mt:2}}>
                                                <Typography color='#212D39' sx={{ fontSize:14, fontWeight:400}}>{'Espere un momento...'}</Typography>
                                                </Grid>
                                            )
                                        }
                                        
                                    </form>
                                )
                            }} 
                        </Formik>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            {
                (role != Role.SUPER_ADMIN && role != Role.ADMIN) && (
                    <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                        <Accordion  sx={{width:'700px'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Identificación</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                                <Formik initialValues={data} enableReinitialize validate={ (values) => validateFormPIN(values) } onSubmit={onSubmitPIN}>
                                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                {
                                                    withIdentification == '' && (
                                                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'Ud. aún no ha enviado sus documentos de verificación.'}</Typography></div>
                                                    )
                                                }

                                                {
                                                    withIdentification === '0' && (
                                                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'El estado de verificación de sus documentos de identidad se encuentra pendiente.'}</Typography></div>
                                                    )
                                                }

                                                {
                                                    withIdentification == '1' && (
                                                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'La verificación de sus documentos de identidad, ha sido aprobada.'}</Typography></div>
                                                    )
                                                }

                                                {
                                                    withIdentification == '3' && (
                                                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'Su solictud ha sido rechazada. Por favor vuelva ha subir sus documentos.'}</Typography></div>
                                                    )
                                                }

                                                <Divider />

                                                {/* {
                                                    (withIdentification == '' || withIdentification == '3') && (
                                                        <CustomFormFooter 
                                                            buttonType="submit"
                                                            secondaryText={'Subir documentos'}
                                                            onSecondaryConfirm={()=>{setShowModalIdentification(true); setEditIdentification(false)}}
                                                        />
                                                    )
                                                }
                                                {
                                                    (withIdentification == '0' || withIdentification == '1') && (
                                                        <CustomFormFooter 
                                                            buttonType="submit"
                                                            secondaryText={"Ver"}
                                                            onSecondaryConfirm={()=>{setShowModalIdentification(true); setEditIdentification(true)}}
                                                        />
                                                    )
                                                } */}
                                                {
                                                    <CustomFormFooter 
                                                        buttonType="submit"
                                                        secondaryText={"Ver"}
                                                        onSecondaryConfirm={()=>setShowModalIdentification(true)}
                                                    />
                                                }
                                            </form>
                                        )
                                    }} 
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )
            }

            {
                (role != Role.SUPER_ADMIN && role != Role.ADMIN) && (
                    <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                        <Accordion  sx={{width:'700px'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>PIN</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                                <Formik initialValues={data} enableReinitialize validate={ (values) => validateFormPIN(values) } onSubmit={onSubmitPIN}>
                                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                {
                                                    withIdentification == '' && !editpin && (
                                                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'Ud. aún no ha enviado sus documentos de verificación.'}</Typography></div>
                                                    )
                                                }

                                                {
                                                    withIdentification === '0' && !editpin && (
                                                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'El estado de verificación de sus documentos de identidad se encuentra pendiente.'}</Typography></div>
                                                    )
                                                }

                                                {
                                                    withIdentification === '3' && !editpin && (
                                                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'Su solictud de validación de identidad ha sido rechazada.'}</Typography></div>
                                                    )
                                                }

                                                {
                                                    withIdentification == '1' && !withPin && !editpin && (
                                                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'No cuenta con un PIN activo.'}</Typography></div>
                                                    )
                                                }

                                                {
                                                    withIdentification == '1' && (
                                                        <>
                                                        <Grid container sx={{padding:'20px'}}>
                                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                                <Typography sx={{ width: '60%', flexShrink: 0 }}>PIN:</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                                    {
                                                                        !loading ? (
                                                                            <TextField
                                                                                id="pin"
                                                                                name="pin"
                                                                                fullWidth
                                                                                variant='outlined'
                                                                                type={showPin ? 'text' : 'password'}
                                                                                value={values.pin}
                                                                                onChange={handleChange}
                                                                                size='small'
                                                                                placeholder='Ingresa su PIN'
                                                                                inputProps={{ readOnly: editpin ? false : true, maxLength: 6, }}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                    <InputAdornment position='start'  >
                                                                                        <LockIcon sx={{ color: '#29333B' }} />
                                                                                    </InputAdornment>),
                                                                                    endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton
                                                                                        aria-label="toggle pin visibility"
                                                                                        onClick={handleClickShowPin}
                                                                                        onMouseDown={handleMouseDownPin}
                                                                                        edge="end"
                                                                                        sx={{ color: '#29333B' }}
                                                                                        >
                                                                                        {showPin ? <VisibilityOff /> : <Visibility />}
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                    )
                                                                                }}
                                                                                error={errors.pin && touched.pin ? true : false}
                                                                                helperText={errors.pin && touched.pin ? errors.pin : ''}
                                                                            />
                                                                        ) : (
                                                                            <Skeleton width="100%" />
                                                                        )
                                                                    }
                                                                
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                editpin && (
                                                                    <>
                                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Contraseña:</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                                            {
                                                                                !loading ? (
                                                                                    <TextField
                                                                                        id="password"
                                                                                        name="password"
                                                                                        fullWidth
                                                                                        variant='outlined'
                                                                                        type={showPassword ? 'text' : 'password'}
                                                                                        value={values.password}
                                                                                        onChange={handleChange}
                                                                                        size='small'
                                                                                        placeholder='Ingresa su clave'
                                                                                        inputProps={{ readOnly: editpin ? false : true}}
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                            <InputAdornment position='start'  >
                                                                                                <LockIcon sx={{ color: '#29333B' }} />
                                                                                            </InputAdornment>),
                                                                                            endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton
                                                                                                aria-label="toggle password visibility"
                                                                                                onClick={handleClickShowPassword}
                                                                                                onMouseDown={handleMouseDownPassword}
                                                                                                edge="end"
                                                                                                sx={{ color: '#29333B' }}
                                                                                                >
                                                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                            )
                                                                                        }}
                                                                                        error={errors.password && touched.password ? true : false}
                                                                                        helperText={errors.password && touched.password ? errors.password : ''}
                                                                                    />
                                                                                ) : (
                                                                                    <Skeleton width="100%" />
                                                                                )
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Descripción:</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                                            {
                                                                                !loading ? (
                                                                                    <TextField
                                                                                        id="pintext"
                                                                                        variant="outlined"
                                                                                        type="text" 
                                                                                        name="pintext"
                                                                                        size='small'
                                                                                        multiline
                                                                                        fullWidth
                                                                                        value={values.pintext}
                                                                                        onChange={handleChange}
                                                                                        placeholder={"descripcion"}
                                                                                        error={errors.pintext && touched.pintext ? true : false}
                                                                                        helperText={errors.pintext && touched.pintext ? errors.pintext: ''}
                                                                                        inputProps={{ readOnly: editpin ? false : true }}
                                                                                    />
                                                                                ) : (
                                                                                    <Skeleton width="100%" />
                                                                                )
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                    </>
                                                                )
                                                            }
                                                        </Grid>
                                                        </>
                                                    )
                                                }

                                                <Divider />

                                                {
                                                    (editpin && role != Role.SUPER_ADMIN && role != Role.ADMIN && !loadEdit) && (
                                                        <CustomFormFooter 
                                                            buttonType="submit"
                                                            confirmText={'Guardar'}
                                                            onConfirm={handleSubmit}
                                                            cancelText={"Descartar"}
                                                            onCancel={()=>{setEditpin(false)}}
                                                        />
                                                    )
                                                }
                                                {
                                                    (!editpin && role != Role.SUPER_ADMIN && role != Role.ADMIN  && !loadEdit && withPin && withIdentification == '1') && (
                                                        <CustomFormFooter 
                                                            buttonType="submit"
                                                            secondaryText={"Editar PIN"}
                                                            onSecondaryConfirm={()=>{setEditpin(true)}}
                                                        />
                                                    )
                                                }
                                                {
                                                    (!editpin && role != Role.SUPER_ADMIN && role != Role.ADMIN  && !loadEdit && !withPin && withIdentification == '1') && (
                                                        <CustomFormFooter 
                                                            buttonType="submit"
                                                            secondaryText={"Crear PIN"}
                                                            onSecondaryConfirm={()=>{setEditpin(true)}}
                                                        />
                                                    )
                                                }
                                                {
                                                    loadEditpin && (
                                                        <Grid sx={{width: '100%', textAlign:'end', mt:2}}>
                                                        <Typography color='#212D39' sx={{ fontSize:14, fontWeight:400}}>{'Espere un momento...'}</Typography>
                                                        </Grid>
                                                    )
                                                }
                                                
                                            </form>
                                        )
                                    }} 
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )
            }

        </Grid>

        {/* {
            showModalIdentification && (
                <ModalIdentification
                    open={showModalIdentification}
                    closeModal={()=>{setShowModalIdentification(false)}}
                    edit={editIdentification}
                    datausersession={datausersession}
                    identification={data.identification || ''}
                />
            )
        } */}
        {
            showModalIdentification && (
                <IdentificationModal
                    open={showModalIdentification}
                    closeModal={()=>{setShowModalIdentification(false)}}
                />
            )
        }
        </>
    )
}