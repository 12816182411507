import { useDispatch, useSelector } from "react-redux"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { changeStatus, onFetchContractHistory, onFetchContracts, onSetSelectedContract, selectContractState, setAction } from "../redux/slices/contractSlice";
import { ContractApi } from "../apis/ContractApi";

export const useContractStore = () => {
    const dispatch = useDispatch();
    const { status, contract, contractHistory, selectedContract, action } = useSelector(selectContractState)

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    const idcompany = userData?.data?.idcompany

    /* ADMIN */

    const listVariablesContract = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ContractApi.listVariablesContract({idcompany: idcompany})
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const listContractTemplates = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ContractApi.listContractTemplates({idcompany: idcompany})
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const findContractTemplates = async (data:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ContractApi.findContractTemplates({...data, idcompany: idcompany})
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const getContract = async (data:any) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.getContract({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false;
        }
        const { detail } = response.data
        dispatch(onFetchContracts(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
      } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
      }
    }

    const getContractHistory = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.getContractHistory({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false;
        }
        const { detail } = response.data
        dispatch(onFetchContractHistory(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
        }
    }

    const createContract = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.createContract({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const registerVariable = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.registerVariable({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const registerVariableMassive = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.registerVariableMassive({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const createAsignature = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.createAsignature({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const renewContract = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.renewContract({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const downloadContract = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.downloadContract({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const editContract = async (id:number, data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.editContract(id, {...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const updateContract = async (id:number, data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.updateContract(id, {...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const updateClausule = async (id:number, data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.updateClausule(id, {...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const deleteContract = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.deleteContract(data)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false;
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const updateContractStatus = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.updateContractStatus({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const validateContract = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.validateContract({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false;
        }
        const { detail } = response.data
        dispatch(changeStatus(ApiStatus.FETCHED))
        // CustomSnackbar('success', response.data.message || '')
        return detail
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    /* OWNER */

    const listContractTemplatesOwner = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ContractApi.listContractTemplatesOwner({idcompany: idcompany})
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const updateClausuleOwner = async (id:number, data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.updateClausuleOwner(id, {...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const findContractTemplatesOwner = async (data:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ContractApi.findContractTemplatesOwner({...data, idcompany: idcompany})
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const getContractOwner = async (data:any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.getContractOwner({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false;
        }
        const { detail } = response.data
        dispatch(onFetchContracts(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
        }
    }

    const editContractOwner = async (id:number, data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.editContractOwner(id, {...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const editContractOwnerMassive = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ContractApi.editContractOwnerMassive({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    /* AGENT */
    const listContractTemplatesForAgent = async (iddepartment) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ContractApi.listContractTemplatesForAgent({iddepartment: iddepartment, idcompany: idcompany})
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    /* LISTADO DE TABLAS DE LA BASE DE DATOS CON SUS COLUMNAS */

    const listTablesOfDatabase = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ContractApi.listTablesOfDatabase()
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    /* ------------------- */

    const setSelectContract = async (data: any) => {
        try {
            dispatch(onSetSelectedContract(data))
        } catch (error) {
            console.log(error)
        }
    }

    const setActionContract = async (state: any) => {
        try {
            dispatch(setAction(state))
        } catch (error) {
            console.log(error)
        }
    }
    
    const resetGetContract = () => {
      try {
        dispatch(onFetchContracts([]))
      } catch (error) {
        console.log(error)
      }
    }

    return {
        status,
        contract,
        contractHistory,
        selectedContract,
        action,

        getContract,
        getContractHistory,
        createContract,
        createAsignature,
        renewContract,
        downloadContract,
        editContract,
        updateContract,
        updateClausule,
        deleteContract,
        updateContractStatus,
        validateContract,
        listVariablesContract,
        listContractTemplates,
        findContractTemplates,

        listTablesOfDatabase,
        registerVariable,
        registerVariableMassive,

        getContractOwner,
        listContractTemplatesOwner,
        findContractTemplatesOwner,
        updateClausuleOwner,
        editContractOwnerMassive,
        editContractOwner,

        listContractTemplatesForAgent,

        setSelectContract,
        setActionContract,
        resetGetContract,
    }
}