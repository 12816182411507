import { useEffect } from "react";
import { Carrousel } from "./Carrousel/Carrousel";
import { Card, Grid, Skeleton, Slider, Typography } from "@mui/material";
import { useSeasonStore } from "../../hooks/useSeasonStore";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Season } from "../../types/slices/seasonType";
import { ApiStatus } from "../../types/api/status";
import { useConfigurationCompanyStore } from "../../hooks/useConfigurationCompanyStore";
import { useConfigurationDepartmentStore } from "../../hooks/useConfigurationDepartmentStore";
import moment from "moment";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";

export const HomeView = () => {

  const { user } = useAuthStore()
  const { status:statusSeason, seasonSelectedByOwner, seasons, getSeasonsByDepartment, getSeasonsByOwner } = useSeasonStore();
  const { status:statusDepartment, selectedDepartment } = useDepartmentStore();
  const { status: statusConfigDepartment, configurationDepartment, getConfigurationDepartmentByOwner } = useConfigurationDepartmentStore();
  
  const idowner:any = user?.userType == Role.FAMILY ? user?.data?.idowner :  user?.data?.id

  useEffect(() => {
    if (selectedDepartment.id) {
      const params = { iddepartment: selectedDepartment.id }
      getConfigurationDepartmentByOwner(params);
      getSeasonsByDepartment(params);
      getSeasonsByOwner(idowner)
    }
  },[selectedDepartment])

  const setMark = (config) => {
    const marks = [
      {
        value: config.start_cycle,
        label: `Día ${config.start_cycle}`,
      },
      {
        value: config.day_issuance_document,
        label: `Día ${config.day_issuance_document}`,
      },
      {
        value: config.last_day_payment,
        label: `Día ${config.last_day_payment}`,
      },
      {
        value: config.end_cycle,
        label: `Día ${config.end_cycle}`,
      },
    ];
    
    return marks
  }

  const getColor = (config) => {
    const day = moment().date();
    if (day >= config.start_cycle && day < config.day_issuance_document) {
      return "#1976d2 !important"
    }else if (day >= config.day_issuance_document && day < config.last_day_payment) {
      return "#1976d2 !important"
    }else if (day >= config.last_day_payment && day < config.end_cycle) {
      return "#1976d2 !important"
    } else if (day == config.end_cycle) {
      return "#1976d2 !important"
    }
  }
  
    return (
      <>
      <Grid container >
        <Carrousel/>

        <Grid item container xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"20px" }}>
          <Card sx={{ textAlign: 'center', padding: '5px', marginX: '10px', border: 'solid 1px #ebebeb' }}>
            {
              
              statusSeason === ApiStatus.FETCHING || statusDepartment === ApiStatus.FETCHING ? (
                <Grid item container xs={12} spacing={2} justifyContent='center'>
                  <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                    <Skeleton width="60%" />
                  </Grid>
                  <Grid item xs={12} display='flex' alignItems='center' justifyContent='center' sx={{ padding: '10px 0px 0px 0px' }}>
                    <Skeleton width="60px" />
                  </Grid>
                  <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                    <Skeleton width="30px" />
                  </Grid>
                  <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                    <Skeleton width="60px" />
                  </Grid>
                </Grid>
              ) : (
                seasonSelectedByOwner && seasonSelectedByOwner.length > 0 ? (
                  <>
                    <Typography gutterBottom color='#808080' sx={{ fontWeight:400, fontSize:'16px' }}>
                      Días Disponibles
                    </Typography>
                    <Grid sx={{ display: 'flex' }}>
                      {
                        seasonSelectedByOwner.map((value:any, index:number) => {
                          return (
                            value.season_iddepartment == selectedDepartment.id && (
                              <Grid sx={{ borderRight: `${index === seasonSelectedByOwner.length -1 ? "" : "solid 1px #101010"}`,textAlign:'center', padding:'10px 60px 20px 60px'}}>
                                <div><Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>{value?.season_name || ""}</Typography></div>
                                <div><Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>{value?.current_avaibality+' días' || '0 días'}</Typography></div>
                                <div><Typography color='#808080' sx={{ fontWeight:400, fontSize:'15px'}}>{'disponibles'}</Typography></div>
                              </Grid>
                            )
                          )
                        })
                      }
                    </Grid>
                  </>
                ) : (
                  <>
                    <Typography gutterBottom color='#808080' sx={{ fontWeight:400, fontSize:'16px' }}>
                      {selectedDepartment.name}
                    </Typography>
                    <Grid sx={{textAlign:'center', padding:'20px 60px 20px 60px'}}>
                      <div><Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>{"Sin temporas disponibles"}</Typography></div>
                    </Grid>
                  </>
                )
              )
            }
          </Card>

          <Card sx={{padding:'5px', marginX:'10px', border:'solid 1px #ebebeb'}}>
            <Typography gutterBottom color='#212D39' sx={{ fontWeight:600, paddingLeft:'10px', fontSize: '21px'}}>
                {'Ciclo de Rendición por Departamento'}
            </Typography>
            <div>
              {
                  statusConfigDepartment === ApiStatus.FETCHING || statusDepartment === ApiStatus.FETCHING ? (
                    <Skeleton />
                  ) : (
                    <>
                      <Typography color='#808080' sx={{ fontWeight: 300, paddingLeft: '10px', fontSize: '14px' }}>
                        {`Apertura (Día ${configurationDepartment.start_cycle || ""}) `}
                      </Typography>
                      <Typography color='#808080' sx={{ fontWeight: 300, paddingLeft: '10px', fontSize: '14px' }}>
                        {` Emisión (Día ${configurationDepartment.day_issuance_document || ""}) `}
                      </Typography>
                      <Typography color='#808080' sx={{ fontWeight: 300, paddingLeft: '10px', fontSize: '14px' }}>
                        {` Pago Anterior (Día ${configurationDepartment.last_day_payment || ""}) `}
                      </Typography>
                      <Typography color='#808080' sx={{ fontWeight: 300, paddingLeft: '10px', fontSize: '14px' }}>
                        {` Cierre (Día ${configurationDepartment.end_cycle || ""}) `}
                      </Typography>
                    </>
                  )
                }
            </div>
            <Grid sx={{padding:'10px 75px 10px 75px'}}>
              <Grid container item xs={12} sm={12}>
                {
                  statusConfigDepartment === ApiStatus.FETCHING || statusDepartment === ApiStatus.FETCHING ? (
                    <Skeleton style={{width: "500px", height:"50px"}}/>
                  ) : (
                    <Slider
                      sx={{color: getColor(configurationDepartment), width: "500px"}}
                      value={moment().date()}
                      disabled
                      marks={setMark(configurationDepartment)}
                      max={configurationDepartment.end_cycle}
                      min={configurationDepartment.start_cycle}
                    />
                  )
                }
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      </>
    )
}