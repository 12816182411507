import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { FC } from "react";

import EditIcon from '@mui/icons-material/Edit'
import CustomTable from "../../components/common/CustomTable/CustomTable";
import '../../assets/styles/pewen-app.css';

const columns = [
    { type: 'text', field: 'column1', label: 'FECHA DE MODIFICACIÓN' },
    { type: 'text', field: 'column2', label: 'DEPARTAMENTO' },
    { type: 'text', field: 'column3', label: 'CONFIGURACIÓN DE DÍAS' }
]

const datatable = [
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '7 días'
    },
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '7 días'
    },
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '7 días'
    },
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '7 días'
    },
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '7 días'
    }
]

export const SettingSuperAdminReservation : FC = () => {
    return (
        <Grid container sx={{ margin: '0px 20px' }}>
            <Grid item xs={12} md={12} sx={{ margin: '10px 0px' }}>
                <Typography sx={{ display: 'inline-block', fontSize: '16px', fontWeight: 'bolder' }} variant="h6">Politicas de reservación</Typography>
                <Button
                onClick={ () => null }
                sx={{ float: 'right', height: 30, margin: '0px 20px', padding: '6px 16px', textTransform: 'none' }}
                className='btn-secondary'
                >
                    Configurar departamento
                </Button>
            </Grid>
            <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Tiempo límite para los días consecutivos para la siguiente reservación:</Typography>
                <TextField 
                size="small"
                sx={{ width: '60px' }}
                type="number"
                InputProps={{
                    inputProps: {
                        min: 0
                    }
                }}
                />
                <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>días antes.</Typography>
                <IconButton size="small" color="inherit" aria-label="edit">
                    <EditIcon fontSize='small' />
                </IconButton>
            </Grid>
            <Grid item xs={12} md={12}>
                <CustomTable
                columns={columns} 
                rows={datatable || []}
                />
            </Grid>
        </Grid>
    )
}