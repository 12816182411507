import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../types/api/status"
import { RootState } from "../store"

const initialState: any =
{
    status: ApiStatus.FETCHED,
    identifications: [],
    errorMessage: undefined,
    selectedIdentify: {} as any,
    findIdentify: {} as any,
    action: '',
}

const identificacionSlice = createSlice({
    name: 'identificacionSlice',
    initialState,
    reducers: {
        onFetchIdentify(state, { payload }: { payload: any[] }) {
            state.status = ApiStatus.FETCHED;
            state.identifications = payload;
            state.errorMessage = undefined;
        },
        onFetchFindIdentify(state, { payload }: { payload: any }) {
            state.status = ApiStatus.FETCHED;
            state.findIdentify = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedIdentify(state, { payload }: { payload: any }) {
            state.selectedIdentify = payload;
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectIdentificationState = (state: RootState) => state.identificacionSlice
export default identificacionSlice.reducer

export const {
    onFetchIdentify,
    onFetchFindIdentify,
    changeStatus,
    onSetSelectedIdentify,
    setAction,
} = identificacionSlice.actions