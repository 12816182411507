import { Formik } from "formik";
import { Button, Divider, Grid, TextField, Typography, InputAdornment, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useAuthStore } from "../../../hooks";
import { useRenditionStore } from "../../../hooks/useRenditionStore";
import { ApiStatus } from "../../../types/api/status";
import { CustomBackdrop } from "../../../components/common/CustomBackdrop/CustomBackdrop";
import { useDepartmentStore } from "../../../hooks/useDepartmentStore";
import { useOwnerStore } from "../../../hooks/useOwnerStore";
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar";
import { useBuildingStore } from "../../../hooks/useBuildingStore";
import { BuildingByDepartment } from "../../../types/slices/buildingType";
import { useRenditionBuildingStore } from "../../../hooks/useRenditionBuildingStore";
import { useRenditionBuildingPaidStore } from "../../../hooks/useRenditionBuildingPaidStore";

type Values = {
    iddepartment: any
    doc: any,
    observation: string,
    amount: number,
}

export const UploadTranfersBuildingModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, clearState, isAdmin, isPayment, department, building } = props;

    const { user } = useAuthStore()
    const { renditionOwner, getRenditionOwner } = useRenditionStore()
    const { selectedDepartment } = useDepartmentStore()
  
  const { status: renditionPaidStatus, getRenditionBuildingPaids, createRenditionBuildingPaid, validateRenditionBuildingPaid } = useRenditionBuildingPaidStore();
  
  const { idrenditionBuilding, renditionBuildingOwner, getRenditionBuildingOwner } = useRenditionBuildingStore();
  
  const { buildingByDepartment, selectedBuilding, selectedBuildingByDepartment, setSelectedBuildingByDepartment } = useBuildingStore();

    useEffect(() => {
      setSelectedBuildingByDepartment({} as BuildingByDepartment);
    },[])
  
    const idcompany:any = user?.data?.idcompany
    const idowner:any = user?.data?.id
    
    const [data, setData] = useState<Values>({
        doc: rowSelected ? rowSelected.doc :'',
        observation: rowSelected ? rowSelected.observation :'',
        amount: rowSelected ? rowSelected.amount :'',
        iddepartment: ''
    });

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const currentDoc = files[0];
        
        if(currentDoc){
            const nameAsArray = currentDoc.name.split(".") || [];
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
            const nameFile = nameWithoutExtension.join(".") || "";
            return currentDoc;
        }
        return "";
    };

    const validateForm = (values) => {
        let errors:any = {};
        if(!isPayment && !values.doc) errors.doc = "documento requerido";
        if(!values.amount) errors.amount = "monto requerido";
        if(!values.observation) errors.observation = "Observación requerido";
        return errors;
    }

    const onSubmit = async (values, e)  => {
        if (isAdmin && !selectedBuildingByDepartment) return CustomSnackbar('warning', 'Seleccione un propietario.')

        const date = new Date();
        const day   = date.getDate()
        const month = date.getMonth() + 1
        const year  = date.getFullYear()
        const today = year + '-' + month + '-' + day
        const getDepartamentId: any = department ? department?.id : selectedDepartment.id;
        const formData = new FormData()

        if (!isPayment) {
            formData.append('idreport_building', isAdmin ? idrenditionBuilding : renditionBuildingOwner.id)
        }
        formData.append('iddepartment', isAdmin ? selectedBuildingByDepartment.iddepartment : getDepartamentId)
        formData.append('date_pay', today)
        formData.append('amount', values.amount)
        formData.append('comment', values.observation)
        if (values.doc) {
            formData.append('img_transfer', values.doc)
        }
        formData.append('idcompany', idcompany)

        const renditionPaid = await createRenditionBuildingPaid(formData)
        if (renditionPaid.id) {
            if (isAdmin) {
                await validateRenditionBuildingPaid(renditionPaid.id, { validation_status: '1', comment: '' })
                if (!isPayment) {
                  await getRenditionBuildingPaids({ idreport: idrenditionBuilding, idbuilding: renditionBuildingOwner.id })
                }
            } else {
              closeModal()
              await getRenditionBuildingOwner({iddepartment: getDepartamentId, idbuilding: building ? building.id : selectedBuilding?.id})
            }
            closeModal()
            
        }
    }

    return (
        <>
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
        { renditionPaidStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            <ModalHeader
                text={isPayment ? 'Registrar abono' : 'Subir transferencia'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                    {
                                        isAdmin
                                        ?
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{"Departamento (*)"}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-owner"
                                                value={selectedBuildingByDepartment}
                                                options={buildingByDepartment || []}
                                                getOptionLabel={(option) => option.department_name || ""}
                                                isOptionEqualToValue={(option, value) => option?.department_name === value?.department_name}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" variant="standard" />}
                                                onChange={(e, newValue) => {
                                                  setSelectedBuildingByDepartment(newValue || {} as BuildingByDepartment);
                                                }}
                                            />
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                    }

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Seleccionar archivo"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Button 
                                        variant="contained"
                                        component="label"
                                        style={{ maxWidth: '100%', width: '100%' }}
                                        sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#212D39', "&:hover": {backgroundColor: "#212D39" } }}        
                                        >
                                            <FileUploadIcon fontSize="small" />
                                            {values.doc.name || ''}
                                            <input
                                                id={`doc`}
                                                type="file"
                                                name={`doc`}
                                                style={{ display: 'none' }}
                                                onChange={(event) => {
                                                    setFieldValue(`doc`, setNameFile(event));
                                                }}
                                            />
                                        </Button>
                                        {
                                            
                                            !isPayment && errors[`doc`] && touched[`doc`] && (
                                                <Typography sx={{color: '#d32f2f', fontSize:'12px', marginTop: '10px'}}>
                                                    {'documento requerido'}
                                                </Typography>
                                            ) 
                                        }
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Observación (*)"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observation"
                                            variant="standard"
                                            type="text" 
                                            name="observation"
                                            multiline
                                            fullWidth
                                            value={values.observation}
                                            onChange={handleChange}
                                            placeholder={"observation"}
                                            error={errors.observation && touched.observation ? true : false}
                                            helperText={errors.observation && touched.observation ? errors.observation: ''}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Monto"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="amount"
                                            variant="standard"
                                            type="text" 
                                            name="amount"
                                            fullWidth
                                            value={values.amount}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                  setFieldValue('amount', e.target.value)
                                                }
                                            }}
                                            placeholder={"amount"}
                                            error={errors.amount && touched.amount ? true : false}
                                            helperText={errors.amount && touched.amount ? errors.amount: ''}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                        />
                                        </Grid>
                                    </Grid>
                                
                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={'Enviar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={closeModal}
                                />
                            </form>
                        )
                    }} 
                </Formik>
            </ModalBody>
        </Modal>
        </>
    )
}