import { useDispatch, useSelector } from "react-redux"
import { ApiStatus } from "../types/api/status"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { changeStatus, onFetchDocuments, selectDocumentState, setAction } from "../redux/slices/documentSlice"
import { DocumentsAPI } from "../apis/DocumentsAPI"

export const useDocumentsStore = () => {

  const dispatch = useDispatch()
  const { documents, historyDocuments, status, selectedDocument, action } = useSelector(selectDocumentState)
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

    const listByUser = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.listByUser(idcompany, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            // dispatch(onFetchDocuments(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const historyByUser = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.historyByUser(idcompany, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            // dispatch(onFetchDocuments(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const listMyDocuments = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.listMyDocuments(idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            // dispatch(onFetchDocuments(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const listMyHistory = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.listMyHistory(idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            // dispatch(onFetchDocuments(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const listSharedCompany = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.listSharedCompany(idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            // dispatch(onFetchDocuments(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const documentRegister = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.documentRegister(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const registerCategory = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.registerCategory({...data, idcompany: idcompany})
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            const { detail } = response.data
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const listCategories = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.listCategories(idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const documentReplace = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.documentReplace(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const documentDelete = async (iddetail: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DocumentsAPI.documentDelete(iddetail, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    // const setSelectedOwner = (owner: Owner) => {
    //     try {
    //         dispatch(onSetSelectedOwner(owner))
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const setActionDocument = (state) => {
        try {
            dispatch(setAction(state))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        documents,
        historyDocuments,
        status,
        selectedDocument,
        action,
        //actions
        listByUser,
        historyByUser,
        listMyDocuments,
        listMyHistory,
        listSharedCompany,
        documentRegister,
        documentDelete,
        documentReplace,
        setActionDocument,
        registerCategory,
        listCategories,
    }
}