import { DialogActions, Button, CircularProgress } from '@mui/material';
import '../../../assets/styles/modals.css';
import '../../../assets/styles/pewen-app.css'; 

export const ModalFooter = (props:any) => {

  const { 
    confirmText, onConfirm, cancelText, onCancel, cancelColor, confirmColor, className, backgroundColor, buttonType, secondaryText, onSecondaryConfirm, clearState, loadingConfirmText, disabledConfirmText, justify
  } = props;

    return (
      <DialogActions className={className || "modal-footer"} style={{position: 'sticky', justifyContent: justify ? justify : '' }}>
        {
          cancelText && (
            <Button onClick={()=>{onCancel && onCancel(); clearState && clearState()}} size="small" variant="contained" className='btn-cancel'>
              {/* {cancelText} */}
              {cancelText.charAt(0).toUpperCase() + cancelText.slice(1).toLowerCase()}
            </Button>
          )
        }
        {
          confirmText && (
            <Button onClick={onConfirm} disabled={loadingConfirmText || disabledConfirmText} type={buttonType} size="small" variant="contained" className='btn-confirm'>
              {/* {confirmText} */}
              {confirmText.charAt(0).toUpperCase() + confirmText.slice(1).toLowerCase()}
              {
                loadingConfirmText && (
                  <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                )
              }
            </Button>
          )
        }
        {
          secondaryText && (
            <Button onClick={onSecondaryConfirm} type={buttonType} size="small" color={"error" || confirmColor} variant="contained" className='btn-secondary'>
              {/* {secondaryText} */}
              {secondaryText.charAt(0).toUpperCase() + secondaryText.slice(1).toLowerCase()}
            </Button>
          )
        }
      </DialogActions>
    )
}