
import React, { useRef, useEffect } from 'react';
import {  Grid, TextField } from "@mui/material";

export const PIN = (props) => {

    const { setnumberpin, numberspin } = props;

    const [otp, setOtp] = React.useState(['', '', '', '', '', '']);
    const inputRefs:any = useRef([]);

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    const handleInputChange = (index, event) => {
        const value = event.target.value;
        const keyCode = event.keyCode || event.which; 

        if (!isNaN(value) && keyCode!=32) {
            const newOtp = [...numberspin];
            newOtp[index] = value;
            setnumberpin(newOtp);
    
          if (index < 5 && value !== '') {
            inputRefs.current[index + 1].focus();
          }
        }
    };

    const handleKeyDown = (index, event) => {

        const keyCode = event.keyCode || event.which;

        if (event.key === 'Backspace' && numberspin[index] === '' && keyCode!=32) {
          if (index > 0) {
            const newOtp = [...numberspin];
            newOtp[index - 1] = '';
            setnumberpin(newOtp);
            inputRefs.current[index - 1].focus();
          }
        } else if (event.key === ' ') {
            event.preventDefault(); 
        }
      };

    return (
        <Grid xs={12} display='flex' justifyContent='space-between'>
            {numberspin.map((digit, index) => (
                <TextField
                    key={index}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                    value={digit}
                    onChange={(e) => handleInputChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    type={'password'}
                    InputProps={{
                        style: { fontSize: '2rem', textAlign: 'center' },
                        disableUnderline: true,
                    }}
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                    }}
                    sx={{width:'50px'}}
                />
            ))}
        </Grid>
    );  
}