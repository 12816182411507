import { FC, useEffect, useState } from "react"
import { Skeleton, Button, Grid, IconButton, TextField, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import CustomTable from "../../components/common/CustomTable/CustomTable"
import { readLocalStorage } from "../../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../../types"
import { useConfigurationCompanyStore } from "../../hooks/useConfigurationCompanyStore"
import { useConfigurationDepartmentStore } from "../../hooks/useConfigurationDepartmentStore"
import { ModalCessation } from "./component/ModalCessation"
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm"
import '../../assets/styles/pewen-app.css';

const columns = [
  { type: 'options',  field: 'options', align:'center', label: 'Opciones',  },
  { type: 'text', field: 'update_date', label: 'Fecha de Modificación' },
  { type: 'text', field: 'business_name_department', label: 'Departamento' },
  { type: 'text', field: 'billing_cycle_cutoff_time', label: 'Tiempo Límite para el Corte (Dias)' },
  { type: 'detail',   field: 'detail',  align:'center', label: '' },
]

export const SettingAdminCessation : FC = () => {

  const { getConfigurationCompany, configurationCompany, editConfigurationCompany, setBillingCycleCutoffTime} = useConfigurationCompanyStore();
  const { status:statusApiDepartment, configurationDepartments, selectedConfigurationDepartment, getConfigurationDepartment, setSelectedConfigurationDepartment, editConfigurationCompany: editConfigurationDepartment} = useConfigurationDepartmentStore();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [loadingDepartment, setLoadingDepartment] = useState<boolean>(false);
  const [loadingDepartmentDelete, setLoadingDepartmentDelete] = useState<boolean>(false);

  useEffect(() => {
    getConfigurationCompanyApi();
    getConfigurationDepartmentApi();
  }, [])

  const getConfigurationCompanyApi = async() => {
    try {
      setLoadingCompany(true);
      const response = await getConfigurationCompany();
      setLoadingCompany(false);
    } catch (error) {
      setLoadingCompany(false);
    }
  }

  const getConfigurationDepartmentApi = async() => {
    try {
      setLoadingDepartment(true);
      const response = await getConfigurationDepartment("billing");
      setLoadingDepartment(false);
    } catch (error) {
      setLoadingDepartment(false);
    }
  }

  const onEditCompany = async () => {
    setLoadingCompany(true);
    const response = await editConfigurationCompany(configurationCompany);
    setLoadingCompany(false);
  }

  const onAdd = () => {
    setOpenModal(true);
    setEditMode(false);
  }

  const onEdit = (rowSelected:any) => {
    setOpenModal(true);
    setEditMode(true);
    setSelectedConfigurationDepartment(rowSelected)
  }

  const onDelete = (rowSelected: any) => {
    setOpenModalDelete(true);
    setSelectedConfigurationDepartment(rowSelected)
  }

  const closeModal = (submit:boolean = false) => {
    submit && getConfigurationDepartmentApi();
    setOpenModal(false);
  }

  const confirmDelete = async () => {
    setLoadingDepartmentDelete(true);
    const response = await editConfigurationDepartment(selectedConfigurationDepartment.iddepartment, {
      status_billing: "0"
    })
    setSelectedConfigurationDepartment({});
    getConfigurationDepartmentApi();

    setLoadingDepartmentDelete(false);
    setOpenModalDelete(false);
  }

  return (
    <Grid container sx={{ margin: '0px 20px' }}>
      <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
        <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
            POLÍTICAS DE CORTE O SUSPENSIÓN
        </Typography>
      </Grid>
      <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
        {
          loadingCompany ? (
            <Skeleton width="450px" height="40px"/>
          ) : (
            <>
              <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>
                Tiempo límite para días de corte por Compañia:
              </Typography>
              <TextField 
                size="small"
                sx={{ width: '70px' }}
                type="number"
                value={configurationCompany.billing_cycle_cutoff_time}
                onChange={(e)=> setBillingCycleCutoffTime(parseInt(e.target.value))}
              />
              <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>
                días.
              </Typography>
              <IconButton size="small" color="inherit" aria-label="edit" onClick={onEditCompany}>
                  <EditIcon fontSize='small' />
              </IconButton>
            </>
          )
        }
      </Grid>
      <Grid item xs={12} md={12} sx={{ margin: '10px 0px -20px 0px' }}>
        <Button
        onClick={onAdd}
        sx={{ float: 'right', height: 30, padding: '6px 16px', textTransform: 'none'}}
        className='btn-secondary'
        >
            Configurar departamento
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomTable
          columns={columns} 
          rows={configurationDepartments || []}
          loading={loadingDepartment}
          onRowClick={() => {}}
          hasOptions
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Grid>
      {
        openModal &&
        <ModalCessation
          open={openModal}
          editMode={editMode}
          closeModal={(e:boolean) => closeModal(e)}
          clearState={() => null}
        />
      }
      {
        openModalDelete &&
        <ModalConfirm
          open={openModalDelete}
          title={"Eliminar la Configuración por Departamento"}
          text={"¿Desea realmente eliminar la configuración por departamento?"}
          onConfirm={confirmDelete}
          closeModal={() => setOpenModalDelete(false)}
          status2={loadingDepartmentDelete}
        />
      }
    </Grid>
  )
}