import { api } from "./configs/axiosConfigs"

export const OwnerAPI = {
    getOwners: async (idcompany: number, data?: any) => {
        const response = await api.get(
            '/owner/company',
            {
                params: {
                    ...data,
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    findOwner: async (idowner) => {
        const response = await api.get(
            `/owner/find/${idowner}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    findTenant: async (idowner) => {
        const response = await api.get(
            `/owner/findTenant/${idowner}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    findInvitedByOwner: async (idinvitedbyowner) => {
        const response = await api.get(
            `/owner/find/invitedByOwner/${idinvitedbyowner}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    createOwner: async (data: any, idcompany) => {
        const response = await api.post(
            '/owner/register',
            {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    editOwner: async (idowner: number, data: any, idcompany: number) => {
      const response = await api.patch(
      `/owner/${idowner}`,
      {
          ...data,
          idcompany: idcompany
      }
      ).then(response => response.data)
      .catch((error) => {
          return error.response.data
      })
      return response
    },
    editInvitedByOwner: async (idinvitedbyowner: number, data: any) => {
      const response = await api.patch(
      `/owner/invited/${idinvitedbyowner}`,
      {
          ...data
      }
      ).then(response => response.data)
      .catch((error) => {
          return error.response.data
      })
      return response
    },
    deleteOwner: async (idowner: number, idcompany: number) => {
        const response = await api.delete(
        `/owner/${idowner}`,
        {
            params: {
                idcompany: idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
  departmentByOwner: async () => {
    const response = await api.get(
      `/owner/departments`
    ).then(response => response.data)
    .catch((error) => {
        return error.response.data
    })
    return response
  }
}