import { UserDataForm } from '../types/api/UserApi.type'
import { api } from './configs/axiosConfigs'

export const UserAPI = {

  getUsers: async () => {
      const response = await api.get(
        '/user',
      ).then(response => response.data)
        .catch((error) => {
          console.error(error)
          return error.response.data
        })
      return response
  },

  getUserType: async () => {
    const response = await api.get(
      '/user/type',
    ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
    return response
  },

  createUser: async (data: UserDataForm) => {
    const response = await api.post(
        '/user/register', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  editUser: async (iduser: number, data: UserDataForm) => {
    const response = await api.patch(
    `/user/${iduser}`,
    { ...data }
    ).then(response => response.data)
    .catch((error) => {
        return error.response.data
    })
    return response
  },

  deleteUser: async (iduser: number) => {
      const response = await api.delete(
      `/user/${iduser}`
      ).then(response => response.data)
      .catch((error) => {
          return error.response.data
      })
      return response
  },

  findAdmin: async (idadmin) => {
    const response = await api.get(
        `/admin/find/${idadmin}`
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  editAdmin: async (idadmin: number, data: any) => {
    const response = await api.patch(
    `/admin/${idadmin}`,
      { ...data }
    ).then(response => response.data)
    .catch((error) => {
        return error.response.data
    })
    return response
  },

  changePassword: async (data: any) => {
    const response = await api.post(
        '/user/changePassword', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  forgotPassword: async (data: any) => {
    const response = await api.post(
        '/user/forgotPassword', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  findPIN: async () => {
    const response = await api.get(
      `/signings/find`,
    ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
    return response
  },

  createPIN: async (data: any) => {
    const response = await api.post(
        '/signings/register', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  validatePIN: async (data: any) => {
    const response = await api.post(
        '/signings/validatePIN', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  changePIN: async (data: any) => {
    const response = await api.post(
        '/signings/changePIN', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

}