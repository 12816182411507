import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../types/api/status"
import { RootState } from "../store"

const initialState: any =
{
  status: ApiStatus.FETCHED,
  tenants: [],
  tenantsDistincs: [],
  errorMessage: undefined,
  selectedTenant: {} as any,
  action: '',
}

const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        onFetchTenants (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.tenants       = payload
            state.errorMessage = undefined
        },
        onFetchTenantsDistincs (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.tenantsDistincs       = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedTenant (state, { payload }: { payload: any }) {
            state.selectedTenant = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
        settenants (state) {
            state.tenants = initialState.tenants
        },
    }
})

export const selectTenantState = (state: RootState) => state.tenantSlice
export default tenantSlice.reducer

export const {
  onFetchTenants,
  onFetchTenantsDistincs,
  changeStatus,
  onSetSelectedTenant,
  setAction,
  settenants,
} = tenantSlice.actions