import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, FormLabel, Autocomplete, InputAdornment, Checkbox, Typography, Button, FormControlLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useDepartmentStore } from "../../hooks/useDepartmentStore"
import { useNavigate } from "react-router-dom"
import { Department } from "../../types/slices/departmentType"
import { useOwnerStore } from "../../hooks/useOwnerStore"
import { useServiceStore } from "../../hooks/useServiceStore"
import { Service } from "../../types/slices/serviceSlice"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../types/api/status"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"

type DepartmentDataForm = {
    id: number
    name: string
    address: string
    location_url: string
    capacitance: number
    num_rooms: number
    num_bathrooms: number 
    num_kitchen: number 
    dimensions: number
    idservices: number[]
    idcompany: number | null
    idowner: number | null
    num_owner: number
}

export const DepartamentForm = (props) => {

    const navigate = useNavigate()

    const { status: statusDepartament, selectedDepartment, action, createDepartment, setSelectedDepartment, editDepartmentAll, setActionDepartment } = useDepartmentStore()
    const { status: statusOwnerApi, owners, getOwners }     = useOwnerStore()
    const { status: statusServiceApi, services, getServices } = useServiceStore()
    
    const [ selectedServices, setSelectedServices ] = useState<Service[]>([])
    const [ selectedOwners, setSelectedOwners ]     = useState<any>([])

    const [ownersToCustomizePercentage, setOwnersToCustomizePercentage] = useState<any>([]);
    const [checked, setChecked] = useState<string>('equitable');
    const [previewLoading, setPreviewLoading] = useState<boolean>(false);
    const [showDistribution, setShowDistribution] = useState<any>('');
    const [btnSend, setBtnSend] = useState<any>('equitable');

    const [percentagetaken, setpercentagetaken] = useState<any>(0)

    const [ data, setData ] = useState<DepartmentDataForm>({
        id: 0,
        name: "",
        address: "",
        location_url: "",
        capacitance: 0,
        num_rooms: 0,
        num_bathrooms: 0,
        num_kitchen: 0,
        dimensions: 0,
        idservices: [],
        idcompany: null,
        idowner: null,
        num_owner: 1
    });

    useEffect(()=>{
        if(!selectedDepartment.id && action=='') {
            navigate({ pathname: RoutesMap.DEPARTAMENT}, { replace: true })
        }
    }, [!selectedDepartment.id])

    useEffect(()=>{
        getOwners()
        getServices()
        if(owners.length > 0 ){
            setSelectedOwners( owners.filter(item => item.is_admin === "1"))
            let arrayOwnerInput:any = [];
            owners.map((item)=>{
                const newOwner:any = {id: item.id, name: item.name, inputactive: false, percentage: '', is_admin: item.is_admin}
                arrayOwnerInput.push(newOwner)
            })
            setOwnersToCustomizePercentage(arrayOwnerInput);
        }

    }, [])

    useEffect(()=>{
        if(selectedDepartment.id && owners.length > 0 && services.length > 0) {
            setData({
                id: selectedDepartment.id,
                name: selectedDepartment.name,
                address: selectedDepartment.address,
                location_url: selectedDepartment.location_url,
                capacitance: selectedDepartment.capacitance,
                num_rooms: selectedDepartment.num_rooms,
                num_bathrooms: selectedDepartment.num_bathrooms,
                num_kitchen: selectedDepartment.num_kitchen,
                dimensions: selectedDepartment.dimensions,
                idservices: selectedDepartment.idservices,
                idcompany: selectedDepartment.idcompany,
                idowner: selectedDepartment.idowner,
                num_owner: selectedDepartment.num_owner
            })
            setSelectedOwners(setCustomOwner(selectedDepartment.idowner, owners) || [])
            setSelectedServices(setCustomService(selectedDepartment.idservices, services) || [])
        }
    },[selectedDepartment.id && owners.length > 0 && services.length > 0])

    /* custom owners */
    const setCustomOwner = (idowners, originalowners) => {
        const data = idowners ? idowners.map( idowner => originalowners.find(value => value.id == idowner)) : [];
        return getCustomOwners(data);
    }
    const getCustomOwners = (originalOwners) => {
        const res = originalOwners ? originalOwners.map(owner => ({...owner})) : [];
        return originalOwners ? originalOwners.map(owner => ({...owner})) : [];
    }
    const customOwners = getCustomOwners(owners || []);

    /* custom services */
    const setCustomService = (idservices, originalservices) => {
        const data = idservices ? idservices.map( idservice => originalservices.find(value => value.id == idservice)) : [];
        return getCustomOwners(data);
    }
    const getCustomServices = (originalServices) => {
        const res = originalServices ? originalServices.map(service => ({...service})) : [];
        return originalServices ? originalServices.map(service => ({...service})) : [];
    }
    const customServices = getCustomServices(services || []);

    const onCancel = ()  => {
        navigate({ pathname: RoutesMap.DEPARTAMENT })
    }

    const clearState = () => {
        setSelectedDepartment({} as Department)
        setSelectedServices([])
        setSelectedOwners([])
        setActionDepartment('')
    }

    const handleKeyDown = (event) => {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 189 || keyCode === 109) {
          event.preventDefault();
        }
    };

    const handleKeyDownPercentage = (event) => {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 189 || keyCode === 109 || parseInt(event.target.value + event.key) > 100) {
          event.preventDefault();
        }
    };

    const validateForm = (values) => {
        let errors:any = {};
        const regex = /^[0-9\b]+$/;
        if(!values.name) errors.name = "nombre requerido";
        if(!values.capacitance) errors.capacitance = "capacidad requerido";
        if(values.capacitance && !(regex.test(values.capacitance))) errors.capacitance = "cantidad no válida";
        if(!values.num_rooms) errors.num_rooms = "cantidad requerido";
        if(values.num_rooms && !(regex.test(values.num_rooms))) errors.num_rooms = "cantidad no válida";
        if(!values.num_bathrooms) errors.num_bathrooms = "cantidad requerido";
        if(values.num_bathrooms && !(regex.test(values.num_bathrooms))) errors.num_bathrooms = "cantidad no válida";
        if(!values.num_kitchen) errors.num_kitchen = "cantidad requerido";
        if(values.num_kitchen && !(regex.test(values.num_kitchen))) errors.num_kitchen = "cantidad no válida";
        if(!values.dimensions) errors.dimensions = "dimensiones requerido";
        if(!values.num_kitchen) errors.num_kitchen = "cantidad requerido";
        if(values.num_kitchen && !(regex.test(values.num_kitchen))) errors.num_kitchen = "cantidad no válida";
        if(!values.num_owner) errors.num_owner = "cantidad requerido";
        if(selectedServices && selectedServices.length == 0) errors.idservices = "servicios requerido";
        return errors;
    }

    const selectedOner = (owner, values) => {        
        if(!(owner.find(item => item.is_admin === "1"))) return CustomSnackbar('warnung', 'No se puede eliminar el propietario por defecto.')
        setSelectedOwners(owner);

        if(owner && owner.length > 0){
            let arrayOwnerInput:any = [];
            owner.map((item)=>{
                const newOwner:any = {id: item.idowner, name: item.name, inputactive: false, percentage: '', is_admin: item.is_admin}
                arrayOwnerInput.push(newOwner)
            })
            setOwnersToCustomizePercentage(arrayOwnerInput);
        }
    }

    const handleActiveInput = (e, element:any) => {
        e.preventDefault();
        setpercentagetaken('')

        if (ownersToCustomizePercentage.some(owner => owner.id == element.idowner)) {
            const currentOwner = ownersToCustomizePercentage.find(item => item.id === element.idowner);
            if(currentOwner){
                currentOwner.inputactive = currentOwner.inputactive ? false : true;
                currentOwner.percentage = !(currentOwner.inputactive) ? '' : 10;
                setOwnersToCustomizePercentage([...ownersToCustomizePercentage])
            }else{
                setOwnersToCustomizePercentage([...ownersToCustomizePercentage])
            }
        }
    };

    const activeChecked = (type) => {
        setShowDistribution('')
        setBtnSend('')
        if(type == 'equitable'){
            if(checked == 'equitable'){
                setChecked('')
                setBtnSend('')

            }else{
                setChecked('equitable')
                setBtnSend('equitable')

            }
        }else{
            if(checked == 'custom'){
                setChecked('')
                setBtnSend('')

            }else{
                setChecked('custom')
                setBtnSend('custom')

            }
        }
    }

    const customizeDistribution = (value, element) => {
        const updatedInputs = ownersToCustomizePercentage.map((input) => {

            if (input.id === element.idowner) {
              return { ...input, percentage: Number(value) };
            }
            return input;
        });
      
        setOwnersToCustomizePercentage(updatedInputs);
    }

    const ShowEquitableDistribution = async(e, values) => {
        e.preventDefault();

        setPreviewLoading(true);
        if(values.num_owner == 0) { setChecked(''); return  CustomSnackbar('warning', 'El N° de propiertarios no puede ser 0.')}
        let percentage =  parseFloat((100/values.num_owner).toFixed(2));

        let arrayPercentage:any = []

        for (let index = 0; index < values.num_owner; index++) {
            arrayPercentage.push(percentage)
        }

        const a_idservice = selectedServices.map(service => service.id)

        const formdata = {
            ...values,
            capacitance: (values.capacitance).toString(),
            dimensions: (values.dimensions).toString(),
            idowner: values.idowner,
            idservices: JSON.stringify(a_idservice),
            distribution_percentage: arrayPercentage,
            distribution_example: true,
        }

        const response:any = await createDepartment(formdata);
        if(response.data){
            setShowDistribution(response.data.detail)
            setPreviewLoading(false);
            setBtnSend('equitable')
        }else{
            setShowDistribution('');
            setPreviewLoading(false);
            setBtnSend('')
            return CustomSnackbar('warning', 'Asegurese de completar todos los campos.')
        }
        setPreviewLoading(false);
    }

    const showCustomDistribution = async (e, values)  => {
        e.preventDefault();

        setPreviewLoading(true);
        const a_idservice = selectedServices.map(service => service.id)

        const verify = ownersToCustomizePercentage.find(owner=> owner.inputactive && owner.percentage == '')
        if(verify) return CustomSnackbar('warning', 'el campo de % no puede estar vacío.')

        //percentaje taken
        const percentaje_taken = ownersToCustomizePercentage.filter(owner=> owner.percentage != '');
        let sumpercentage = 0;
        if(percentaje_taken.length > 0){
            percentaje_taken.map((item, index) => {
                sumpercentage = sumpercentage + item.percentage;
            })
        }

        setpercentagetaken(sumpercentage)
      
        //array de %
        const arrayPercentage = ownersToCustomizePercentage.map(owner => owner.percentage)

        const formdata = {
            ...values,
            capacitance: (values.capacitance).toString(),
            dimensions: (values.dimensions).toString(),
            idowner: values.idowner,
            idservices: JSON.stringify(a_idservice),
            distribution_percentage: arrayPercentage,
            distribution_example: true,
        }

        const response:any = await createDepartment(formdata);
        if(response.data){
            setShowDistribution(response.data.detail)
            setPreviewLoading(false);
            setBtnSend('custom')
        }else{
            setShowDistribution('');
            setPreviewLoading(false);
            setBtnSend('')
            return CustomSnackbar('warning', 'Asegurese de completar todos los campos.')
        }
        setPreviewLoading(false);

    }

    const onSubmit = async (values)  => {
        const a_idservice = selectedServices.map(service => service.id)

        const formdata = {
            ...values,
            capacitance: (values.capacitance).toString(),
            dimensions: (values.dimensions).toString(),
            idowner: values.idowner,
            idservices: JSON.stringify(a_idservice)
        }
        if(selectedDepartment.id){
            const response = await editDepartmentAll(selectedDepartment.id, formdata);
            if (response) {
                navigate({ pathname: RoutesMap.DEPARTAMENT })
                clearState()
            }
        }else{
            if(btnSend=='equitable'){
              
                const formdataEquitable = {
                    ...values,
                    capacitance: (values.capacitance).toString(),
                    dimensions: (values.dimensions).toString(),
                    idowner: values.idowner,
                    idservices: JSON.stringify(a_idservice),
                }
                const response:any = await createDepartment(formdataEquitable);
                if (response.status) {
                    navigate({ pathname: RoutesMap.DEPARTAMENT })
                    clearState()
                }
            }
            if(btnSend=='custom'){
                const verify = ownersToCustomizePercentage.find(owner=> owner.inputactive && owner.percentage == '')
                if(verify) return CustomSnackbar('warning', 'el campo de % no puede estar vacío.')
                //array de %
                const arrayPercentage = ownersToCustomizePercentage.map(owner => owner.percentage)

                const formdataCustom = {
                    ...values,
                    capacitance: (values.capacitance).toString(),
                    dimensions: (values.dimensions).toString(),
                    idowner: values.idowner,
                    idservices: JSON.stringify(a_idservice),
                    distribution_percentage: arrayPercentage,
                }
                const response:any = await createDepartment(formdataCustom);
                if (response.status) {
                    navigate({ pathname: RoutesMap.DEPARTAMENT })
                    clearState()
                }
            }
            if(btnSend==''){
                const response:any = await createDepartment(formdata);
                if (response.status) {
                    navigate({ pathname: RoutesMap.DEPARTAMENT })
                    clearState()
                }
            }
            
        }
    }

    return (
      <>
      { statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
      { statusServiceApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
      { statusDepartament === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
      <CustomForm >

        <CustomFormHeader
          title={!selectedDepartment.id ? 'Nuevo Departamento' : 'Editar Departamento'}
          text={!selectedDepartment.id ? 'Ingrese los datos del nuevo Departamento' : 'Ingrese los datos del Departamento'}
          goBack={RoutesMap.DEPARTAMENT}
          clearState={clearState}
        >
        </CustomFormHeader>

        <CustomFormBody>
          <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values)} onSubmit={onSubmit} >
            {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container sx={{padding:'20px'}}>
                      <Grid item container xs={12} spacing={2}>
                          <Grid item xs={12} md={4}>
                            <FormLabel>Nombre:</FormLabel>
                            <TextField
                              id="name"
                              type="text" 
                              name="name"
                              fullWidth
                              size="small"
                              value={values.name}
                              onChange={handleChange}
                              error={errors.name && touched.name ? true : false}
                              helperText={errors.name && touched.name ? errors.name : ''}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormLabel>Dimensión:</FormLabel>
                            <TextField
                              id="dimensions"
                              type="number"
                              name="dimensions"
                              fullWidth
                              size="small"
                              value={values.dimensions}
                              onChange={handleChange}
                              InputProps={{
                                  endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                                  inputProps: {
                                      min: 0
                                  }
                              }}
                              error={errors.dimensions && touched.dimensions ? true : false}
                              helperText={errors.dimensions && touched.dimensions ? errors.dimensions : ''}
                              onKeyDown={handleKeyDown}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormLabel>Capacidad:</FormLabel>
                            <TextField
                              id="capacitance"
                              type="number"
                              name="capacitance"
                              fullWidth
                              size="small"
                              value={values.capacitance}
                              onChange={handleChange}
                              error={errors.capacitance && touched.capacitance ? true : false}
                              helperText={errors.capacitance && touched.capacitance ? errors.capacitance : ''}
                              onKeyDown={handleKeyDown}
                              InputProps={{
                                inputProps: {
                                  min: 0
                                }
                              }}
                            />
                          </Grid>
                      </Grid>

                      <Grid item container xs={12} spacing={2} sx={{marginTop:'5px'}}>
                          <Grid item xs={12} md={4}>
                            <FormLabel>N° Habitaciones:</FormLabel>
                            <TextField
                              id="num_rooms"
                              type="number" 
                              name="num_rooms"
                              fullWidth
                              size="small"
                              value={values.num_rooms}
                              onChange={handleChange}
                              error={errors.num_rooms && touched.num_rooms ? true : false}
                              helperText={errors.num_rooms && touched.num_rooms ? errors.num_rooms : ''}
                              onKeyDown={handleKeyDown}
                              InputProps={{
                                inputProps: {
                                  min: 0
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormLabel>N° Baños:</FormLabel>
                            <TextField
                              id="num_bathrooms"
                              type="number" 
                              name="num_bathrooms"
                              fullWidth
                              size="small"
                              value={values.num_bathrooms}
                              onChange={handleChange}
                              error={errors.num_bathrooms && touched.num_bathrooms ? true : false}
                              helperText={errors.num_bathrooms && touched.num_bathrooms ? errors.num_bathrooms : ''}
                              onKeyDown={handleKeyDown}
                              InputProps={{
                                inputProps: {
                                  min: 0
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormLabel>N° Cocinas:</FormLabel>
                            <TextField
                              id="num_kitchen"
                              type="number" 
                              name="num_kitchen"
                              fullWidth
                              size="small"
                              value={values.num_kitchen}
                              onChange={handleChange}
                              error={errors.num_kitchen && touched.num_kitchen ? true : false}
                              helperText={errors.num_kitchen && touched.num_kitchen ? errors.num_kitchen : ''}
                              onKeyDown={handleKeyDown}
                              InputProps={{
                                inputProps: {
                                  min: 0
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <FormLabel>Servicios:</FormLabel>
                            <Autocomplete
                              multiple={true}
                              disablePortal
                              id="combo-box-demo"
                              value={selectedServices|| []}
                              options={customServices || []}
                              getOptionLabel={(option) => option?.name || ""}
                              fullWidth
                              isOptionEqualToValue={(option, value) => option?.name === value?.name}
                              // renderInput={(params) => <TextField {...params} size="small" />}
                              renderInput={(params) => <TextField 
                                {...params} 
                                size='small'
                                error={errors.idservices && touched.idservices ? true : false}
                                helperText={errors.idservices && touched.idservices ? errors.idservices : ''}
                              />}
                              onChange={(e, newValue) => {
                                setSelectedServices(newValue);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li key={option.id} value={option.id} {...props} >
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={selected}
                                  />
                                  { option.name }
                                </li>
                              )}
                            />
                          </Grid>
                          
                          {/* <Grid item xs={12} md={4}>
                              <FormLabel>N° Propietarios:</FormLabel>
                              <TextField
                                  id="num_owner"
                                  type="number" 
                                  name="num_owner"
                                  fullWidth
                                  size="small"
                                  value={values.num_owner}
                                  onChange={(e)=>{handleChange(e); setChecked('equitable'); setShowDistribution('')}}
                                  error={errors.num_owner && touched.num_owner ? true : false}
                                  helperText={errors.num_owner && touched.num_owner ? errors.num_owner : ''}
                                  InputProps={{
                                      readOnly: true,
                                      // inputProps: {
                                      //     min: 0
                                      // }
                                  }}
                                  onKeyDown={handleKeyDown}
                              />
                          </Grid>

                          <Grid item xs={12} md={8}>
                              <FormLabel>Propietarios:</FormLabel>
                              <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  value={selectedOwners|| []}
                                  options={customOwners || []}
                                  getOptionLabel={(option) => option?.name || ""}
                                  disabled={selectedDepartment.id ? true : false}
                                  fullWidth
                                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                  renderInput={(params) => <TextField {...params} size="small" />}
                                  onChange={(e, newValue) => {
                                      selectedOner(newValue, values);
                                  }}
                                  renderOption={(props, option, { selected }) => (
                                      <li key={option.id} value={option.id} {...props} >
                                          <Checkbox
                                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                                              checked={selected}
                                          />
                                          { option.name }
                                      </li>
                                  )}
                              />
                          </Grid> */}
                      </Grid>
                  </Grid>

                  <Divider />
                  {
                    (btnSend=='equitable' || btnSend=='') && checked=='equitable' && (
                      <CustomFormFooter 
                        buttonType="submit"
                        confirmText={ !selectedDepartment.id ? 'Guardar' : 'Actualizar' }
                        cancelText={"Cancelar"}
                        onConfirm={handleSubmit}
                        onCancel={onCancel}
                        clearState={clearState}
                      />
                    )
                  }
                </form>
              )
            }} 
          </Formik>
        </CustomFormBody>

      </CustomForm>
      </>
    )
}