import { useNavigate } from "react-router-dom"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { useEffect, useState } from "react"
import { useTenantStore } from "../../hooks/useTenantStore"
import { RoutesMap } from "../../types"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { Formik } from "formik"
import { Divider, FormLabel, Grid, TextField } from "@mui/material"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"

type OwnerDataForm = {
  id: number
  name: string
  rut: string
  email: string
  phone: string
  privilege: string
  address: string
}

export const TenantForm = (props) => {

  const navigate = useNavigate();
  
  const { status: statusTenantApi, selectedTenant, action, createTenant, editTenant, setSelectTenant, setActionTenant } = useTenantStore()

  const [ isEdit, setIsEdit ] = useState<boolean>(false)
  const [data, setData] = useState<OwnerDataForm>({
    id: 0,
    name: '',
    rut: '',
    email: '',
    phone: '',
    privilege: "2",
    address: "",
  });

  useEffect(()=>{
    if(!selectedTenant.id && action=='') {
      navigate({ pathname: RoutesMap.TENANT}, { replace: true })
    }
  }, [!selectedTenant.id])

  useEffect(()=>{
    if(Object.entries(selectedTenant).length > 0) {
      setIsEdit(true)
      setData({
        id: selectedTenant.id,
        rut: selectedTenant.rut,
        name: selectedTenant.name,
        email: selectedTenant.email,
        phone: selectedTenant.phone,
        privilege: selectedTenant.privilege,
        address: selectedTenant.address
      })
      setSelectTenant({} as any)
    }
  },[])

  const validateForm = (values) => {
    let errors:any = {};
    if(!values.name) errors.name = "Nombre es requerido";
    if(!values.rut) errors.rut = "RUT es requerido";
    if(!values.email) errors.email = "Email es requerido";
    if(!values.phone) errors.phone = "Celular es requerido";
    if(!values.address) errors.address = "direccion es requerido";
    return errors;
  }

  const onCancel = async()  => {
    navigate({ pathname: RoutesMap.TENANT })
  }

  const onSubmit = async (values)  => {
    const method = !isEdit ? createTenant(values) : editTenant(values.id, {...values})
    const response = await method
    if (response === true) {
      setActionTenant('')
      navigate({ pathname: RoutesMap.TENANT })
    }
  }

  return (
    <CustomForm >
      {
          statusTenantApi === ApiStatus.FETCHING && <CustomBackdrop open={true} />
      }

      <CustomFormHeader
        title={!isEdit ? 'Nuevo Arrendatario' : 'Editar Arrendatario' }
        text={!isEdit ? 'Ingrese los datos del nuevo Arrendatario' : 'Ingrese los datos del Arrendatario'}
        goBack={RoutesMap.TENANT}
      >
      </CustomFormHeader>

      <CustomFormBody>
        <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
          {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container sx={{padding:'20px'}}>
                  <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                    <Grid item xs={12} md={4}>
                      <FormLabel>Nombre:</FormLabel>
                      <TextField
                        id="name"
                        type="text" 
                        name="name"
                        fullWidth
                        size="small"
                        value={values.name}
                        onChange={handleChange}
                        error={errors.rut && touched.rut ? true : false}
                        helperText={errors.rut && touched.rut ? errors.rut : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormLabel>RUT:</FormLabel>
                      <TextField
                        id="rut"
                        type="text" 
                        name="rut"
                        fullWidth
                        size="small"
                        value={values.rut}
                        onChange={handleChange}
                        error={errors.rut && touched.rut ? true : false}
                        helperText={errors.rut && touched.rut ? errors.rut : ''}
                        InputProps={{
                          inputProps: {
                            maxLength: 10
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormLabel>Email:</FormLabel>
                      <TextField
                        id="email"
                        type="email" 
                        name="email"
                        fullWidth
                        size="small"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email ? true : false}
                        helperText={errors.email && touched.email ? errors.email : ''}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2} sx={{marginTop:'10px'}}>
                    <Grid item xs={12} md={4}>
                      <FormLabel>Celular:</FormLabel>
                      <TextField
                        id="phone"
                        type="text" 
                        name="phone"
                        fullWidth
                        size="small"
                        value={values.phone}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          if (e.target.value === "" || regex.test(e.target.value)) {
                            setFieldValue('phone', e.target.value)
                          }
                        }}
                        error={errors.phone && touched.phone ? true : false}
                        helperText={errors.phone && touched.phone ? errors.phone : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormLabel>Dirección:</FormLabel>
                      <TextField
                        id="address"
                        type="text" 
                        name="address"
                        fullWidth
                        size="small"
                        value={values.address}
                        onChange={handleChange}
                        error={errors.address && touched.address ? true : false}
                        helperText={errors.address && touched.address ? errors.address : ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <CustomFormFooter
                  buttonType="submit"
                  confirmText={ !isEdit ? 'Guardar' : 'Actualizar' }
                  cancelText={"Cancelar"}
                  onConfirm={handleSubmit}
                  onCancel={onCancel}
                />
              </form>
            )
          }} 
        </Formik>

      </CustomFormBody>

    </CustomForm>
  )
}