import { useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import CustomTable from '../../../components/common/CustomTable/CustomTable';
import { useNavigate } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from "../../../redux/store";
import { selectHeaderState } from "../../../redux/slices/headerSlice";
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar";
import { useDocumentsStore } from "../../../hooks/useDocumentsStore";
import { useAuthStore } from "../../../hooks/useAuthStore";
import { ModalUploadField } from "../components/ModalUploadField";
import { ModalConfirm } from "../../../components/common/ModalConfirm/ModalConfirm";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { ModalReplaceField } from "../components/ModalReplaceField";
import moment from 'moment'
import { Role } from "../../../types/roles/roleTypes";

const columns = [
    { type: 'options',  field: 'options', align:'center', label: 'Opciones',  },
    { type: 'text', field: 'namecategory', align:'center', label: 'Categoria'},
    { type: 'text', field: 'namedocument', align:'center', label: 'Nombre del Archivo'},
    { type: 'text', field: 'extension', align:'center', label: 'Tipo' },
    { type: 'text', field: 'creator_user_type', align:'center', label: 'Creado por', 
        format:(row) => row.creator_user_type == '2' ? 'Admin' : 
        (row.creator_user_type == '3' ? 'Propietario' : (row.creator_user_type == '5' ? 'Arrendatario' : 'Corredor'))},
    { type: 'text', field: 'created_at', align:'center', label: 'Fecha de Creación', format:(row) => row.created_at ? moment(row.created_at).format('YYYY-MM-DD') : '-----' },
    { type: 'detail',   field: 'detail',  align:'center', label: '' },
];

const columnshistory = [
    { type: 'text', field: 'namecategory', align:'center', label: 'Categoria' },
    { type: 'text', field: 'namedocument', align:'center', label: 'Nombre del Archivo'},
    { type: 'text', field: 'extension', align:'center', label: 'Tipo' },
    { type: 'text', field: 'creator_user_type', align:'center', label: 'Creado por', 
        format:(row) => row.creator_user_type == '2' ? 'Admin' : 
        (row.creator_user_type == '3' ? 'Propietario' : (row.creator_user_type == '5' ? 'Arrendatario' : 'Corredor'))},
    { type: 'text', field: 'created_at', align:'center', label: 'Fecha de Creación', format:(row) => row.created_at ? moment(row.created_at).format('YYYY-MM-DD') : '-----' },
    { type: 'detail',   field: 'detail',  align:'center', label: '' },
]

const columnsAdmin = [
    { type: 'options',  field: 'options', align:'center', label: 'Opciones',  },
    { type: 'text', field: 'namecategory', align:'center', label: 'Categoria'},
    { type: 'text', field: 'namedocument', align:'center', label: 'Nombre del Archivo'},
    { type: 'text', field: 'extension', align:'center', label: 'Tipo' },
    { type: 'text', field: 'creator_user_type', align:'center', label: 'Creado por', 
        format:(row) => row.creator_user_type == '2' ? 'Admin' : 
        (row.creator_user_type == '3' ? 'Propietario' : (row.creator_user_type == '5' ? 'Arrendatario' : 'Corredor'))},
    { type: 'text', field: 'sharedCompany', align:'center', label: 'Compartido', format:(row) => row.sharedCompany == '1' ? 'Sí' : 'No' },
    { type: 'text', field: 'created_at', align:'center', label: 'Fecha de Creación', format:(row) => row.created_at ? moment(row.created_at).format('YYYY-MM-DD') : '-----' },
    { type: 'detail',   field: 'detail',  align:'center', label: '' },
];

export const MyDocumentsView = () => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { listMyDocuments, setActionDocument, documentDelete, listMyHistory, listSharedCompany} = useDocumentsStore()

    const { user } = useAuthStore()
    const role:any = user?.userType || ''

    const [filterBy, setFilterBy] = useState<any>('1');
    const [rowSelected, setRowSelected] = useState<any>('');
    const [documents, setDocuments] = useState<any>([]);
    const [history, setHistory] = useState<any>([]);
    const [sharedCompany, setSharedCompany] = useState<any>([]);
    const [showModalReplace, setShowModalReplace] = useState<boolean>(false);
    const [showModalUploadField, setShowModalUploadField] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    useEffect(() => {
        getDocumentsApi()
    },[search])

    const getDocumentsApi = async() => {
        try {

            switch (filterBy) {
                case '1':
                    mydocuments();
                    break;
                case '2':
                    myhistory();
                    break;
                case '3':
                    sharedcompany();
                    break;
                default:
                    break;
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const onChangeFilter = async(type) => {
        try {

            switch (type) {
                case '1':
                    mydocuments();
                    break;
                case '2':
                    myhistory();
                    break;
                case '3':
                    sharedcompany();
                    break;
                default:
                    break;
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const mydocuments = async() => {
        setLoading(true);
        const response = await listMyDocuments();
            if(!response){
                setLoading(false);
                setDocuments([])
                return false;
            }else{
                setLoading(false);
                setDocuments(response)
            }
        setLoading(false);
    }

    const sharedcompany = async() => {
        setLoading(true);
        const response = await listSharedCompany();
            if(!response){
                setLoading(false);
                setSharedCompany([])
                return false;
            }else{
                setLoading(false);
                setSharedCompany(response)
            }
        setLoading(false);
    }

    const myhistory = async() => {
        setLoading(true);
        const response = await listMyHistory();
            if(!response){
                setLoading(false);
                setHistory([])
                return false;
            }else{
                setLoading(false);
                setHistory(response)
            }
        setLoading(false);
    }

    const onAdd = () => {
        setActionDocument('add')
        setShowModalUploadField(true);
    }
    
    const onDelete = (rowSelected) => {
        if(role != Role.ADMIN && rowSelected.created_by == 2) return CustomSnackbar("warning", "No puedes eliminar este documento.") 
        setActionDocument('delete')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        setLoadingDelete(true)
        const response = await documentDelete(rowSelected.id)
        if(response){
            setLoadingDelete(false)
            setShowModalConfirm(false)
            getDocumentsApi()
        }else{
            setLoadingDelete(false)
            return false;
        }
    }
    
    const onDownload = (rowSelected) => {
        window.open(`${process.env.REACT_APP_ROOT_URL}/` + rowSelected.filePath, '_blank')
    }

    const onReplace = (rowSelected) => {
        if(role != Role.ADMIN && rowSelected.created_by == 2) return CustomSnackbar("warning", "No puedes reemplazar este documento.") 
        setRowSelected(rowSelected)
        setShowModalReplace(true)
    }

    return (
        <>

            <Grid item xs={12}>
                <Grid item container xs={12} direction="row" sx={{ marginBottom:"10px", display:"flex", alignItems:"center", paddingLeft:'23px'}}>
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }}>filtrar por</InputLabel>
                    <Select
                        id="combo-box-department"
                        size="small"
                        value={filterBy}
                        onChange={(event) => {setFilterBy(event.target.value); onChangeFilter(event.target.value)}}
                        sx={{width:'240px', fontSize:"12px"}}
                        label='filtrar por'
                    >
                        <MenuItem value={'1'}>{"Vigentes"}</MenuItem>
                        <MenuItem value={'2'}>{"Historial"}</MenuItem>
                        <MenuItem value={'3'}>{"Compartidos por la Empresa"}</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
                {
                    filterBy == '1' && (
                        <CustomTable 
                            title={'Mis Documentos'}
                            columns={ role == Role.ADMIN ? columnsAdmin : columns} 
                            loading={loading}
                            rows={documents || []}
                            onRowClick={() => {}}
                            hasOptions
                            onDelete={onDelete}
                            onAddFn={onAdd}
                            onHelper={onDownload}
                            icon_helper={<DownloadIcon fontSize="small" />}
                            tooltip_helper={'descargar'}
                            onHelper2={onReplace}
                            icon_helper2={<AutorenewIcon fontSize="small" />}
                            tooltip_helper2={'reemplazar'}
                        />
                    )
                }

                {
                    filterBy == '2' && (
                        <CustomTable 
                            title={'Mi Historial de Documentos'}
                            columns={columnshistory} 
                            loading={loading}
                            rows={history || []}
                            onRowClick={() => {}}
                            onHelper={onDownload}
                            icon_helper={<DownloadIcon fontSize="small" />}
                            tooltip_helper={'descargar'}
                        />
                    )
                }

                {
                    filterBy == '3' && (
                        <CustomTable 
                            title={'Compartidos por la Empresa'}
                            columns={columnshistory} 
                            loading={loading}
                            rows={sharedCompany || []}
                            onRowClick={() => {}}
                            onHelper={onDownload}
                            icon_helper={<DownloadIcon fontSize="small" />}
                            tooltip_helper={'descargar'}
                        />
                    )
                }
                
            </Grid>

            {
                showModalUploadField && (
                    <ModalUploadField
                        open={showModalUploadField}
                        closeModal={()=>{setShowModalUploadField(false)}}
                        userSelected={''}
                        getDocumentsApi={getDocumentsApi}
                    />
                )
            }

            {
                showModalReplace && (
                    <ModalReplaceField
                        open={showModalReplace}
                        closeModal={()=>{setShowModalReplace(false)}}
                        userSelected={''}
                        field={rowSelected}
                        getDocumentsApi={getDocumentsApi}
                    />
                )
            }

            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        rowSelected={rowSelected}
                        onConfirm={onDeleteConfirm}
                        status2={loadingDelete}
                    />
                )
            }
        </>
    )
}