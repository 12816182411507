import { FC, useState } from "react";
import { Grid, ButtonGroup, IconButton, Typography, Card, CardContent, Divider, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useReportStore } from "../../hooks/useReportStore";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { FilterComponent } from "./components/FilterComponent";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";
import { NotData } from "./components/NotData";
import { ReportAPI } from "../../apis/ReportAPI";

const columns = [
    { type: 'text', field: 'name_department', label: 'DEPARTAMENTO', align: 'center' },
    { type: 'text', field: 'name_concept', label: 'CONCEPTO DE PAGO', align: 'center' },
    { type: 'text', field: 'type', label: 'TIPO', align: 'center' },
    {
        type: 'text', field: 'total_department', label: 'TOTAL DEPARTAMENTO', align: 'center',
        format: (row) => moneyFormat(row.total_department, '$')
    },
    // {
    //     type: 'text', field: 'total_owner', label: 'TOTAL PROPIETARIO', align: 'center',
    //     format: (row) => moneyFormat(row.total_owner, '$')
    // },
]

export const ReportAdminRendition: FC = () => {

    const { status: statusApiDepartment, departments, getDepartments } = useDepartmentStore()
    const { status: statusOwnerApi, owners, getOwners } = useOwnerStore()
    const { getReportRendition, status, dataReportRendition, filter, department, building, owner, year, month } = useReportStore();
    const [params, setParams] = useState<any>();

    const downloadExcel = async () => {
        const response  = await ReportAPI.getReportRenditionExcel(params);

        if(response.status){
            const btn_excel = document.createElement('a');
            btn_excel.href = `${response.link}`
            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)
        }

    }
    const onListRendition = () => {
        let dataParams = {}
        switch (filter) {
            case '01':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idbuilding": building.id,
                    "idcompany": 1
                }
                getReportRendition(dataParams)
                setParams(dataParams)
                break;
            case '02':
                dataParams = {
                    "year": year?.name,
                    "idbuilding": building.id,
                    "idcompany": 1
                }
                getReportRendition(dataParams)
                setParams(dataParams)
                break;
            case '03':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idbuilding": owner.id,
                    "idcompany": 1
                }
                getReportRendition(dataParams)
                setParams(dataParams)
                break;
            case '04':
                dataParams = {
                    "year": year?.name,
                    "idowner": owner.id,
                    "idcompany": 1
                }
                getReportRendition(dataParams)
                setParams(dataParams)
                break;
            case '05':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idowner": owner.id,
                    "idbuilding": building.id,
                    "idcompany": 1
                }
                getReportRendition(dataParams)
                setParams(dataParams)
                break;
            case '06':
                dataParams = {
                    "year": year?.name,
                    "idowner": owner.id,
                    "idbuilding": building.id,
                    "idcompany": 1
                }
                getReportRendition(dataParams)
                setParams(dataParams)
                break;
            default:
                break;
        }
    }

    return (
        <>
            {statusApiDepartment === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}

            <Grid container>
                <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '10px' }}>
                    <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml: 1, fontWeight: 600 }}>
                        {('Reporte de Deudas').toUpperCase()}
                    </Typography>
                </Grid>
                <FilterComponent submit={onListRendition} />
                {
                    dataReportRendition?.data_department?.length > 0 ?
                        (dataReportRendition.data_department).map((departmentItem) => {
                            return (
                                <>
                                    <Grid item container sx={{ padding: 2 }} key={departmentItem.name_department}>
                                        
                                        <Grid xs={12} item container display='flex' justifyContent='center' alignItems='center'>

                                            {
                                                departmentItem && (departmentItem.data_category).length > 0 && departmentItem.data_category.map((dataCategory) =>(
                                                    <Card sx={{margin:'5px', width:'200px'}}>
                                                        <CardContent>
                                                            <Typography variant="h6" component="div" align="center">
                                                                {(dataCategory.name).charAt(0).toUpperCase() + (dataCategory.name).slice(1).toLowerCase()}
                                                            </Typography>
                                                            <Typography variant="h5" component="div" align="center">
                                                                {moneyFormat(dataCategory.total, '$')}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                ))
                                            }
                                            <Card sx={{margin:'5px', width:'200px'}}>
                                                <CardContent>
                                                    <Typography variant="h6" component="div" align="center">
                                                        {'Total'}
                                                    </Typography>
                                                    <Typography variant="h5" component="div" align="center">
                                                        {moneyFormat(departmentItem.total, '$')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {departmentItem.data_category.map((dataCategory) => (
                                                <>
                                                    <Divider/>
                                                    <Grid item xs={12} sx={{marginTop:'18px'}}>
                                                        <Typography variant="h6">{dataCategory.name} : {moneyFormat(dataCategory.total, '$')} </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CustomTable title={''} columns={columns} rows={dataCategory.data_item || []} onRowClick={() => { }} />
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </>

                            );
                        })
                        :
                        <NotData />
                }
            </Grid>
        </>
    )
}