import { Button, Card, CardContent, Divider, Grid, TextField, Typography, CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from 'react';
import { useContractStore } from '../../hooks/useContractStore';
import { ModalContractVariables } from "./ModalContractVariables";
import ReactDOMServer from 'react-dom/server';
import '../../assets/styles/pewen-app.css';
import { ContentPasteSearchOutlined } from "@mui/icons-material";

export const SettingRentalContract = () => {

    const { status: statusContract, contract, getContract, editContract, resetGetContract } = useContractStore();

    const [clausuleSelected, setClausuleSelected] = useState<number>(0)
    const [documentClausules, setDocumentClausules] = useState<any>([])
    const [variables,setVaribales] = useState<any>([])
    const [cursorActive, setCursorActive] = useState(null)
    const [idActive, setIdActive] = useState<any>(null)
    const [openModal, setOpenModal] = useState(false);
    const [variableSelected, setVariableSelected] = useState(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
    const [paragraphSelected, setParagraphSelected] = useState<any>('')

    // console.log(paragraphSelected)

    useEffect(() => {
        resetGetContract();
        getContractApi()
    },[])

    useEffect(()=>{
        if(contract){
            setFormated(contract?.variables)
            setDocumentClausules(contract?.detailContract)
        }
    },[contract])

    useEffect(()=>{
        if(variables.length > 0){
            setDocumentClausules(EstructurarData(documentClausules, true))
        }
    },[variables])
  
    const getContractApi = async() => {
        try {
            setLoading(true);
            const params: any = { type: '2' };
            const response = await getContract(params);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const setFormated = (data:[]) => {
        const objectsArray:any = [];
        for (const key in data) {
            objectsArray.push({key:`{{${key}}}`, value: data[key] });
        }
        setVaribales(objectsArray)
    }

    const TextToVariable = (text) => {
        let textChange = text;
        console.log(variables)
        variables && variables.map((item) => {
            textChange = textChange.replace(item.value, item.key)
            
        })
        console.log(textChange)
        return textChange
    }

    const VariableToText = (textVariable) => {
        let newText = textVariable
        variables && variables.map((item) => {
            newText = newText.replace(item.key, item.value)
        })
        return newText
    }

    const EstructurarData = (documentData, toText) => {
        const newArrayEstructura:any = [];
        documentData.map((item, i) => {
            const objNew = {
                id: item.id,
                clausule: toText ? VariableToText(item.clausule) : TextToVariable(item.clausule),
                position: i,
                title: toText ? VariableToText(item.title) : TextToVariable(item.title),
                status: item.status
            }
            newArrayEstructura.push(objNew);
        })

        return newArrayEstructura
    }

    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        let isDelete = false;

        document.addEventListener("keydown", function(event) {
            console.log(event.key)
            if (event.key == "Backspace") {
                isDelete = true;
                console.log('es delete')
            }else{
                isDelete = false;
            }
        });

        console.log(e.key)

        if(value[e.target.selectionEnd - 1] == "@" && value[e.target.selectionEnd - 2] == " ") {
            setCursorActive(e.target.selectionEnd);
            setIdActive(name)
            setOpenModal(true);
            return
        } else {
            let changedForm;
            let indice = 0;
            const newArray:any = []

            documentClausules.map((item, i) => {
                if (item.id == clausuleSelected) {
                    console.log('name', [name])
                    changedForm = {
                        ...item,
                        [name]: value //ya sea clausule o tittle
                    }
                    indice = i

                    // let isVariable = false;

                    // if(changedForm.clausule && variables){
                    //     let textChange = changedForm.clausule;
                    //     const cursorPosition = e.target.selectionStart;
            
                    //     console.log('current', cursorPosition)
            
                    //     variables.map((item) => {

                    //         let searchTerm = textChange.includes(item.value) ? item.value : ''; // ?

                    //         if(searchTerm){
                    //             const firstPosition = textChange.indexOf(searchTerm);
                    //             const lastPosition = firstPosition + searchTerm.length - 1;

                    //             // if(cursorPosition >= firstPosition && cursorPosition <= lastPosition){
                    //             //     isVariable = true;
                    //             //     console.log('first', firstPosition)
                    //             //     console.log('last', lastPosition)
                    //             //     console.log(searchTerm)
                    //             // }else{
                    //             //     isVariable = false;
                    //             // }
                    //             if(isDelete && cursorPosition >= firstPosition && cursorPosition <= lastPosition){
                    //                 textChange = textChange.slice(0, firstPosition) + textChange.slice(lastPosition);
                    //                 e.target.setSelectionRange(firstPosition, firstPosition);

                    //                 e.preventDefault();
                    //             }else{
                    //                 isVariable = false;
                    //             }
                    //         }
                    //     })
                    // }

                    // console.log('is variable', isVariable)

                    // if(isVariable) {
                    //     newArray.push(item)
                    // }else{
                    //     newArray.push(changedForm)
                    // }

                    // console.log(changedForm)

                    newArray.push(changedForm)

                }else{
                    newArray.push(item)
                }
            })
            setDocumentClausules([...newArray]);
        }
    }

    const editClausule = async (clausule) => {
        try {
            // console.log(clausule);
            setLoadingEdit(true);
            const textSend = {...clausule, clausule: TextToVariable(clausule.clausule), title: TextToVariable(clausule.title), type: contract?.contract?.type}
            await editContract(clausule.id, textSend)
            getContract({type: '2'})
            setClausuleSelected(0);
            setLoadingEdit(false);
        } catch (error) {
            setLoadingEdit(false);
            console.error(error);
        }
    }

    const evaluateVar = (e, obj) => {
        setOpenModal(false)
        let changedForm;
        let indice = 0;
        const newArray:any = []
        documentClausules.map((item, i) => {
            if (item.id == clausuleSelected) {
                const newText:any = item[idActive].split('');
                newText.splice(cursorActive, 0, ' ' + obj.value + ' ')
                changedForm = {
                    ...item,
                    [idActive]: newText.join('')
                }
                indice = i
                newArray.push(changedForm)
            }else{
                newArray.push(item)
            }
        })
        setDocumentClausules([...newArray]);
    }

    return (
        <Grid item xs={12}>
            <Grid item container xs={12}>
                <Grid xs={12}>
                    <Typography sx={{ fontSize:18, fontWeight:600}}>Configurar Documento Contrato de Renta</Typography>
                </Grid>
                <Typography className="text-info">
                    {'(*) Para utilizar las variables debe poner el cursor en uno de los inputs, y dar espacio seguido de "@", se abrirá un modal con las variables'}
                </Typography>
            </Grid>

            <Divider />
            <Card sx={{ padding: '10px', marginTop: '20px' }}>
                <CardContent>
                    {
                        loading && (
                        <>
                            <Skeleton variant="rectangular" width="100%" height={'60vh'} />
                        </>
                        )
                    }
                    {
                        (!loading && contract && Object.entries(contract).length > 0) && (
                            <>
                            <Grid xs={12} sx={{textAlign:'center'}}>
                                <Typography align='center' style={{ fontWeight: 'bold', fontSize: 13 }}><b>{contract?.contract?.title}</b></Typography>
                            </Grid>

                            <Grid xs={12} sx={{textAlign:'center'}}>
                            <Typography align='center' style={{ fontWeight: 'bold', fontSize: 12}}><span dangerouslySetInnerHTML={{ __html: VariableToText(contract?.contract?.description.replace(/\n/g, '<br />')) }} /></Typography>
                            </Grid>

                            <Grid sx={{ overflowY: 'scroll', height: '70vh', my: 2 }} container>
                                {
                                    documentClausules.length > 0 &&  documentClausules.map((item, key)=>(
                                        <>
                                        <Button onClick={() => { setClausuleSelected(item.id); setParagraphSelected(item) }}>Editar</Button>
                                        <Grid xs={12} sx={{ mb: 2 }} key={key}>
                                            {
                                                item.title != '' && (
                                                    <TextField sx={{ fontSize: 10 }}
                                                        multiline
                                                        fullWidth
                                                        id='name-clausula'
                                                        name='title'
                                                        value={item.title}
                                                        disabled={clausuleSelected!= item.id}
                                                        onChange={handleInput}
                                                    />
                                                )
                                            }
                                            
                                            {
                                                item.clausule != '' && (
                                                    <TextField sx={{ fontSize: 9 }}
                                                        multiline
                                                        fullWidth
                                                        id='text-clausula'
                                                        name={'clausule'}
                                                        disabled={clausuleSelected!= item.id}
                                                        value={item.clausule}
                                                        onChange={handleInput}
                                                    />
                                                )
                                            }
                                        </Grid>
                                        <Grid sx={{ width: '100%', textAlign: 'end' }}>
                                            <Button disabled={clausuleSelected != item.id} onClick={() => editClausule(item) }>
                                                Guardar Cambios
                                                {
                                                    loadingEdit && (
                                                    <CircularProgress color="primary" size={12} sx={{ marginLeft: "4px" }} />
                                                    )
                                                }
                                            </Button>
                                        </Grid>
                                        </>
                                    ))
                                }
                            </Grid>
                            </>
                        )
                    }
                    
                </CardContent>
            </Card>
            {
                openModal &&
                <ModalContractVariables
                    open={openModal}
                    closeModal={() => { setOpenModal(false) }}
                    dataVariables={variables}
                    variableSelected={variableSelected}
                    setVariableSelected={setVariableSelected}
                    evaluateVar={evaluateVar}
                />
            }
        </Grid>
    );
}