import { useState } from "react"
import { Divider, FormLabel, Grid, TextField } from "@mui/material"
import { Formik } from "formik"
import { useContractStore } from "../../hooks/useContractStore";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Modal } from "../../components/common/Modal/Modal";

type Values = {
    id: any,
    name: string,
    description: string,
}

export const ModalAddVariable: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, listCategoriesAPI, setCategorySelected } = props

    const { status: statusContract, listTablesOfDatabase, registerVariable } = useContractStore();

    const [ loading,  setLoading ] = useState<boolean>(false)

    const [data, setData] = useState<Values>({
        id: '',
        name: '',
        description: '',
    })

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "campo requerido";
        if(!values.description) errors.description = "campo requerido";
        return errors;
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        const regex = /^[A-Za-z\s]*$/;

        if (regex.test(value)) {
            return value;
        }else{
            return '';
        }
    };

    const onSubmit = async(values) => {

        setLoading(true)
        const formData = {
            name: `{{${(values.name).toLowerCase()}}}`,
            description: (values.description).toUpperCase(),
        }
        const response: any = await registerVariable(formData);
        
        if(!response){
            setLoading(false)
            return false;
        }else{
            setLoading(false)
            closeModal && closeModal();
        }
        setLoading(false)
    }

    return (
        <>
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalHeader
                text={'Crear nueva variable'}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>

                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>Nombre:</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <TextField
                                    id="description"
                                    type="text" 
                                    name="description"
                                    fullWidth
                                    size="small"
                                    value={(values.description).toUpperCase()}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                        const regex = /^[A-Za-z\s]*$/;
                                        if (e.target.value === "" || regex.test(e.target.value)) {
                                          setFieldValue('description', e.target.value)
                                        }
                                    }}
                                    error={errors.description && touched.description ? true : false}
                                    helperText={errors.description && touched.description ? errors.description : ''}
                                    placeholder="ingrese solo letras"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>Llave:</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <TextField
                                    id="name"
                                    type="text" 
                                    name="name"
                                    fullWidth
                                    size="small"
                                    value={(values.name).toLowerCase()}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                        const regex = /^[A-Za-z_\s]*$/;
                                        if (e.target.value === "" || regex.test(e.target.value)) {
                                          setFieldValue('name', e.target.value)
                                        }
                                    }}
                                    error={errors.name && touched.name ? true : false}
                                    helperText={errors.name && touched.name ? errors.name : ''}
                                    placeholder="ingrese solo letras"
                                />
                            </Grid>
                        </Grid>

                        <Divider />
                        <ModalFooter 
                            confirmText={'Crear'}
                            onConfirm={handleSubmit}
                            loadingConfirmText={loading}
                            cancelText={"Cancelar"}
                            onCancel={closeModal}
                        />

                        </form>
                    )}}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    )
}