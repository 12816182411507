import { FC, useEffect, useState } from "react";
import { Skeleton, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit'
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useConfigurationCompanyStore } from "../../hooks/useConfigurationCompanyStore";
import { readLocalStorage } from "../../toolbox/helpers/local-storage-helpers";
import { LocalStorageKey } from "../../types";
import { useConfigurationDepartmentStore } from "../../hooks/useConfigurationDepartmentStore";
import { ModalCancellation } from "./component/ModalCancellation";
import { ModalCycleRendition } from "./component/ModalCycleRendition";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import '../../assets/styles/pewen-app.css';

const columns = [
  { type: 'options',  field: 'options', align:'center', label: 'Opciones',  },
  { type: 'text', field: 'update_date', label: 'Fecha de Modificación' },
  { type: 'text', field: 'business_name_department', label: 'Departamento' },
  { type: 'text', field: 'start_cycle', label: 'Inicio de Ciclo' },
  { type: 'text', field: 'end_cycle', label: 'Fin de Ciclo' },
  { type: 'text', field: 'day_issuance_document', label: 'Emisión de Documento' },
  { type: 'text', field: 'last_day_payment', label: 'Último Día de Pago' },
  { type: 'detail',   field: 'detail',  align:'center', label: '' },
]

export const SettingAdminCycleRendition : FC = () => {
    
  const { getConfigurationCompany, configurationCompany, editConfigurationCompany, setLastDayPayment, setDayIssuanceDocument, setEndCycle, setStartCycle} = useConfigurationCompanyStore();
  const { status:statusApiDepartment, configurationDepartments, selectedConfigurationDepartment, getConfigurationDepartment, editConfigurationCompany: editConfigurationDepartment, setSelectedConfigurationDepartment} = useConfigurationDepartmentStore();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [loadingDepartment, setLoadingDepartment] = useState<boolean>(false);
  const [loadingDepartmentDelete, setLoadingDepartmentDelete] = useState<boolean>(false);

  useEffect(() => {
    getConfigurationCompanyApi();
    getConfigurationDepartmentApi();
  }, [])

  const getConfigurationCompanyApi = async() => {
    try {
      setLoadingCompany(true);
      const response = await getConfigurationCompany();
      setLoadingCompany(false);
    } catch (error) {
      setLoadingCompany(false);
    }
  }

  const getConfigurationDepartmentApi = async() => {
    try {
      setLoadingDepartment(true);
      const response = await getConfigurationDepartment("rendition");
      setLoadingDepartment(false);
    } catch (error) {
      setLoadingDepartment(false);
    }
  }

  const onEditCompany = async () => {
    setLoadingCompany(true);
    const response = await editConfigurationCompany(configurationCompany);
    setLoadingCompany(false);
  }

  const onAdd = () => {
    setOpenModal(true);
    setEditMode(false);
  }

  const onEdit = (rowSelected:any) => {
    setOpenModal(true);
    setEditMode(true);
    setSelectedConfigurationDepartment(rowSelected)
  }

  const onDelete = (rowSelected: any) => {
    setOpenModalDelete(true);
    setSelectedConfigurationDepartment(rowSelected)
  }

  const closeModal = (submit:boolean = false) => {
    submit && getConfigurationDepartmentApi();
    setOpenModal(false);
  }

  const confirmDelete = async () => {
    setLoadingDepartmentDelete(true);
    const response = await editConfigurationDepartment(selectedConfigurationDepartment.iddepartment, {
      status_rendition: "0"
    })
    setSelectedConfigurationDepartment({});
    getConfigurationDepartmentApi();

    setLoadingDepartmentDelete(false);
    setOpenModalDelete(false);
  }

  return (
    <Grid container sx={{ margin: '0px 20px' }}>
      <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
        <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
            POLÍTICAS DE CICLO DE RENDICIONES
        </Typography>
      </Grid>
      <Grid item container xs={12} md={12}>
        {
          loadingCompany ? (
            <>
              <Grid item container xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <Skeleton width="450px" height="40px" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Skeleton width="450px" height="40px" />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <Skeleton width="450px" height="40px" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Skeleton width="450px" height="40px" />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item container xs={12} md={12}>
                <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Inicio de Ciclo:</Typography>
                  <TextField 
                  size="small"
                  sx={{ width: '70px' }}
                  type="number"
                  value={configurationCompany.start_cycle}
                  onChange={(e) => setStartCycle(parseInt(e.target.value))}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>de cada mes.</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Fin de ciclo:</Typography>
                  <TextField 
                  size="small"
                  sx={{ width: '70px' }}
                  type="number"
                  value={configurationCompany.end_cycle}
                  onChange={(e) => setEndCycle(parseInt(e.target.value))}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>de cada mes.</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Emisión de Documento:</Typography>
                    <TextField 
                    size="small"
                    sx={{ width: '70px' }}
                    type="number"
                    value={configurationCompany.day_issuance_document}
                    onChange={(e) => setDayIssuanceDocument(parseInt(e.target.value))}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>de cada mes.</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Último dia de Pago:</Typography>
                    <TextField 
                    size="small"
                    sx={{ width: '70px' }}
                    type="number"
                    value={configurationCompany.last_day_payment}
                    onChange={(e) => setLastDayPayment(parseInt(e.target.value))}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>de cada mes.</Typography>
                    <IconButton size="small" color="inherit" aria-label="edit" onClick={onEditCompany}>
                        <EditIcon fontSize='small' />
                    </IconButton>
                </Grid>
              </Grid>
            </>
          )
        }
      </Grid>
      <Grid item xs={12} md={12} sx={{ margin: '15px 0px -20px 0px' }}>
        <Button
        onClick={onAdd}
        sx={{ float: 'right', height: 30, padding: '6px 16px'}}
        className="btn-secondary"
        >
            Configurar departamento
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomTable
          columns={columns} 
          rows={configurationDepartments || []}
          loading={loadingDepartment}
          onRowClick={() => {}}
          hasOptions
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Grid>
      {
        openModal &&
        <ModalCycleRendition
          open={openModal}
          editMode={editMode}
          closeModal={(e:boolean) => closeModal(e)}
          clearState={() => null}
        />
      }
      {
        openModalDelete &&
        <ModalConfirm
          open={openModalDelete}
          title={"Eliminar la Configuración por Departamento"}
          text={"¿Desea realmente eliminar la configuración por departamento?"}
          onConfirm={confirmDelete}
          closeModal={() => setOpenModalDelete(false)}
          status2={loadingDepartmentDelete}
        />
      }
    </Grid>
       
  )
}