import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { store } from './redux/store'
import App from './App'
import './index.css'

const theme =  createTheme({
  typography: {
    fontFamily: 'AvenirNextRounded, sans-serif',
    fontWeightBold: 'bolder'
  },
  components: {
    MuiInputBase:{
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro !important',
        },
      },
    },
    MuiListItemText:{
      styleOverrides: {
        root: {
          fontFamily: 'AvenirNextRounded, sans-serif !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'AvenirNextRounded, sans-serif !important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro !important',
        },
      },
    },
  },
})


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          {/* <Routes>
            <Route path="/*" element={<App />} />
          </Routes> */}
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
