import { DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import '../../../assets/styles/pewen-app.css'; 

export const CustomFormHeader = (props:any) => {
  const { title, text, text2, className="", clearState, goBack } = props;
  const navigate = useNavigate()

  return (
    <DialogTitle className="modal-header">
      <div className={className}>
        <div className={className}>
          {
              goBack && (
                  // <Grid item xs={12} display='flex' alignItems='center' sx={{ marginLeft:'-25px'}}>
                  //     <IconButton size="small" color="primary" aria-label="view" onClick={() =>  {clearState && clearState(); navigate({ pathname: goBack})} }>
                  //         <ArrowBackIcon fontSize='small'/>
                  //     </IconButton>
                  //     <Typography>Volver</Typography>
                  // </Grid>

                  <Grid item xs={12} display='flex' alignItems='center'>

                      <Grid className="btn-back-container" onClick={() =>  {clearState && clearState(); navigate({ pathname: goBack})} }>
                        <IconButton size="small" aria-label="view">
                            <ArrowCircleLeftIcon fontSize='small' sx={{color:'#188dcd'}}/>
                        </IconButton>
                        <Typography className='btn-back-text'>Volver</Typography>
                      </Grid>
                      
                  </Grid>
              )
          }
          
          <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ fontWeight:600, marginTop:'10px'}}>
            {title && (title).toUpperCase()}
          </Typography>
          {
            text && (
              <Typography className="paragraph">
                {text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
              </Typography>
            )
          }
          {
            text2 && (
              <Typography className="paragraph">
                {text2.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
              </Typography>
            )
          }
        </div>    
      </div>
    </DialogTitle>
  )
}