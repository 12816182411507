import { useState } from 'react';
import { Box, Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, Paper, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useAuthStore } from '../../hooks';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LocalStorageKey, RoutesMap } from '../../types';
import logo from '../../assets/image/pewen_login.png';
import { Formik } from 'formik';
import { saveLocalStorage } from '../../toolbox/helpers/local-storage-helpers';
import { ModalForgotPassword } from '../../components/common/ModalForgotPassword/ModalForgotPassword';
import './Login.css';

const themeAvenir = createTheme({
    typography: {
      fontFamily: 'Avenir',
      fontSize: 15
    },
})

type Values = {
  rut: string,
  password: string,
  perfil: number|undefined|null,
  idcompany: number|undefined|null,
}

export const LoginOwner: React.FC<any> = (props:any): JSX.Element | any => {

    const { login } = useAuthStore();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showModalForgotPassword, setShowModalForgotPassword] = useState<boolean>(false);
    const [data, setData] = useState<Values>({
        rut: '',
        password: '',
        perfil: 3,
        idcompany: 1
    });

    const validateForm = (values) => {
      let errors:any = {};
      if(!values.rut) errors.rut = "rut requerido";
      if(!values.password) errors.password = "contraseña requerida";
      return errors;
    }

    const handleClickShowPassword = () => {
        if(showPassword) setShowPassword(false)
        if(!showPassword) setShowPassword(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const onSubmit = async (values) => {
        const { rut, password, perfil, idcompany } = values
        onSendLogin(perfil, password, rut, idcompany);
    }
    
    const onSendLogin = async (perfil, password, rut, idcompany) => {
        setLoading(true)
        try {
            const success = await login({ iduser_type: perfil, password, rut, idcompany});

            if (!success?.status) {
            setLoading(false)
            return false;
            }

            const data = success?.detail;
            saveLocalStorage(LocalStorageKey.BUILDING_OWNER, data?.data?.building || []);

            navigate({ pathname: RoutesMap.DEPARTAMENT}, { replace: true })

            setLoading(false)
        } catch (error) {
            setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }))
            setLoading(false)
        }
    }
        
    return (
      <>
        <Box className='login-main-container'>
          <Grid container justifyContent="center">

            <Grid  item xs={12} sx={{height:'60px'}}></Grid>

            <Grid item xs={12} className='login-container' 
              sx={{ padding: {md:'30px 200px 30px 100px', lg:'30px 200px 30px 100px', xl: '30px 200px 30px 100px'}}} >
              <Grid item xs={12} sm={12} md={5} lg={5} 
              sx={{textAlign: { md: 'end', xs: 'start'}, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}> 
                <img style={{ width: '300px', border:'solid 1px rgba(255, 255, 255, 0.3)', backgroundColor: 'rgba(255, 255, 255, 0.3)' }} src={logo} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} p={ {md: '0px 5px', xs: '0px 20px', lg:'0px 80px 0px 0px'}}>
                <Paper elevation={5}  sx={{ borderRadius: '16px'}}>
                    <Grid xs={12} sx={{height:'40px', backgroundColor:'#212D39', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}></Grid>
                    <Grid sx={{padding:'20px 60px'}}>
                      {/* Title */}
                      <Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="start">
                            <Typography variant='h5' color='#212D39' sx={{ fontWeight:600}}>Ingresa tu</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="start">
                            <Typography variant='h5' color='#212D39' sx={{ fontWeight:600, paddingBottom: 2}}>Usuario</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="start">
                            <Typography variant='body2' color='#808080' sx={{ fontWeight:400, paddingBottom: 3}}>Si ya eres parte de nosotros, ingresa tus datos:</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                        {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                          return (
                            <form onSubmit={handleSubmit}>
                                <ThemeProvider theme={themeAvenir}>
                                  <Grid container spacing={2}>
                                    <Grid item container xs={12} spacing={2}>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="rut"
                                          name='rut'
                                          fullWidth
                                          variant='outlined'
                                          value={values.rut}
                                          onChange={handleChange}
                                          placeholder='Ingrese su RUT'
                                          size='small'
                                          type="text"
                                          InputProps={{
                                              inputProps: {
                                                maxLength: 10
                                            },
                                            startAdornment: (
                                              <InputAdornment position='start'>
                                                <PersonIcon sx={{ color: '#29333B' }} />
                                              </InputAdornment>)
                                          }}
                                          error={errors.rut && touched.rut ? true : false}
                                          helperText={errors.rut && touched.rut ? errors.rut : ''}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="password"
                                          name="password"
                                          fullWidth
                                          variant='outlined'
                                          type={showPassword ? 'text' : 'password'}
                                          value={values.password}
                                          onChange={handleChange}
                                          size='small'
                                          placeholder='Ingresa su clave'
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position='start'  >
                                                <LockIcon sx={{ color: '#29333B' }} />
                                              </InputAdornment>),
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowPassword}
                                                  onMouseDown={handleMouseDownPassword}
                                                  edge="end"
                                                  sx={{ color: '#29333B' }}
                                                >
                                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                          error={errors.password && touched.password ? true : false}
                                          helperText={errors.password && touched.password ? errors.password : ''}
                                        />
                                      </Grid>
                                      <Grid item xs={12} display='flex' justifyContent='center'>
                                        <a 
                                          style={{cursor:'pointer', textDecoration:'underline', fontSize:'13px'}}
                                          onClick={()=>{setShowModalForgotPassword(true)}}
                                        >
                                          !He olvidado mi contraseña!
                                        </a>
                                      </Grid>
                                      <Grid item xs={12} display='flex' justifyContent='center'>
                                        <Button
                                          type="submit"
                                          disabled={loading}
                                          fullWidth
                                          size="small"
                                          variant="contained"
                                          sx={{ width:'150px', background:'#73B2FF',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}}
                                          onClick={()=>{handleSubmit()}}
                                        >
                                          { loading ? <CircularProgress size={28} /> :  'Continuar' }
                                        </Button>
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Divider variant="middle" />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </ThemeProvider>
                            </form>
                          )
                        }} 
                      </Formik>
                    </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
      </Box>
      {
        showModalForgotPassword && (
          <ModalForgotPassword
              open={showModalForgotPassword}
              closeModal={()=>{setShowModalForgotPassword(false)}}
              role={3}
          />
        )
    }
  </>
    )
}
