import { Autocomplete, Box, Button, Card, CardContent, Divider, Grid, TextField, Typography, CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from 'react';
import { useContractStore } from '../../hooks/useContractStore';
import { ModalContractVariables } from "../SettingContract/ModalContractVariables";
import { readLocalStorage } from "../../toolbox/helpers/local-storage-helpers";
import { LocalStorageKey } from "../../types";
import SearchIcon from '@mui/icons-material/Search';
import { useAuthStore } from "../../hooks";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";

export const SettingRentalContractOwner = () => {

    const { status: statusContract, contract, getContractOwner, editContractOwner, editContractOwnerMassive, resetGetContract } = useContractStore();
    const { user } = useAuthStore()
    const idowner:any = user?.data?.id

    const [arrayBuildings, setArrayBuildings] = useState<any>([])
    const [selectedBuilding, setSelectedBuilding] = useState<any>('')
    const [arrayDepartments, setArrayDepartments] = useState<any>([])
    const [selectedDepartment, setSelectedDepartment] = useState<any>('')

    const [clausuleSelected, setClausuleSelected] = useState<number>(0)
    const [documentClausules, setDocumentClausules] = useState<any>([])
    const [variables,setVaribales] = useState<any>([])
    const [cursorActive, setCursorActive] = useState(null)
    const [idActive, setIdActive] = useState<any>(null)
    const [openModal, setOpenModal] = useState(false)
    const [variableSelected, setVariableSelected] = useState(null)

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
    const [loadingdelete, setLoadingDelete] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [clauseDelete, setClauseDelete] = useState<any>('')

    useEffect(() => {
        resetGetContract();
        setDocumentClausules([]);
        getBuildingsAPI();
        // getContractApi();
    },[])

    useEffect(()=>{
        if(contract){
            setFormated(contract?.variables)
            setDocumentClausules(contract?.detailContract)
        }
    },[contract])

    useEffect(()=>{
        if(variables.length > 0){
            setDocumentClausules(EstructurarData(documentClausules, true))
        }
    },[variables])

    const getBuildingsAPI = () => {
        setSelectedBuilding('')
        setSelectedDepartment('')
        const data = readLocalStorage(LocalStorageKey.BUILDING_OWNER);
        const building:any = [];
        data.map((item: any) => {
          building.push({
            id: item.idbuilding,
            name: item.name_building
          })
        });
        setArrayBuildings(building);
        // setSelectedBuilding(building[0])
    }
  
    const getDepartmentsAPI = (getBuilding: any) => {
        const data = readLocalStorage(LocalStorageKey.BUILDING_OWNER);
        const building = data.find((item) => item.idbuilding === getBuilding.id);
        setArrayDepartments(building.departments);
    }
  
    const getContractApi = async() => {
        try {
            if(!selectedBuilding) return CustomSnackbar('warning', 'seleccione un edificio.');
            if(!selectedDepartment) return CustomSnackbar('warning', 'seleccione un departamento.');

            setLoading(true);
            const params: any = { 
                type: '2',
                // idowner: idowner,
                iddepartment: selectedDepartment?.id,
            };
            const response = await getContractOwner(params);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const setFormated = (data:[]) => {
        const objectsArray:any = [];
        for (const key in data) {
            objectsArray.push({key:`{{${key}}}`, value: data[key] });
        }
        setVaribales(objectsArray)
    }

    const TextToVariable = (text) => {
        let textChange = text;
        variables && variables.length>0 && variables.map((item) => {
            textChange = textChange.replace(item.value, item.key)
            
        })
        return textChange
    }

    const VariableToText = (textVariable) => {
        let newText = textVariable
        variables && variables.length>0 && variables.map((item) => {
            newText = newText.replace(item.key, item.value)
        })
        return newText
    }

    const EstructurarData = (documentData, toText) => {
        const newArrayEstructura:any = [];
        documentData.map((item, i) => {
            const objNew = {
                id: item.id,
                clausule: toText ? VariableToText(item.clausule) : TextToVariable(item.clausule),
                position: i,
                title: toText ? VariableToText(item.title) : TextToVariable(item.title),
                status: item.status
            }
            newArrayEstructura.push(objNew);
        })

        return newArrayEstructura
    }

    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        if(value[e.target.selectionEnd - 1] == "@" && value[e.target.selectionEnd - 2] == " ") {
            setCursorActive(e.target.selectionEnd);
            setIdActive(name)
            setOpenModal(true);
            return
        } else {
            let changedForm;
            let indice = 0;
            const newArray:any = []
            documentClausules.map((item, i) => {
                if (item.id == clausuleSelected) {
                    changedForm = {
                        ...item,
                        [name]: value
                    }
                    indice = i
                    newArray.push(changedForm)
                }else{
                    newArray.push(item)
                }
            })
            setDocumentClausules([...newArray]);
        }
    }

    const editClausule = async (clausule) => {
        try {
            // setLoadingEdit(true);
            const textSend = {...clausule, clausule: TextToVariable(clausule.clausule), title: TextToVariable(clausule.title), type: contract?.contract?.type}
            await editContractOwner(clausule.id, textSend)
            getContractApi()
            setClausuleSelected(0);
            setLoadingEdit(false);
        } catch (error) {
            setLoadingEdit(false);
            console.error(error)
        }
    }

    const deleteClause = async(clause) => {
        setShowModalConfirm(true);
        setClauseDelete(clause)
    }

    const confirmDeleteClause = async() => {
        setLoadingDelete(true);
        let indice;
        documentClausules.map((item, i) => {
            if (item.position == clauseDelete.position) {
                indice = i
            }
        })
        documentClausules.splice(indice, 1)

        setDocumentClausules([...documentClausules]);
        let newDocumentClausules = [...documentClausules];
        let textSend = EstructurarData(newDocumentClausules, false);
        await editContractOwnerMassive({clauses: textSend, idowner: idowner, iddepartment: selectedDepartment?.id,})
        setShowModalConfirm(false)
        setLoadingDelete(false)
    }

    const evaluateVar = (e, obj) => {
        setOpenModal(false)
        let changedForm;
        let indice = 0;
        const newArray:any = []
        documentClausules.map((item, i) => {
            if (item.id == clausuleSelected) {
                const newText:any = item[idActive].split('');
                newText.splice(cursorActive, 0, ' ' + obj.value + ' ')
                changedForm = {
                    ...item,
                    [idActive]: newText.join('')
                }
                indice = i
                newArray.push(changedForm)
            }else{
                newArray.push(item)
            }
        })
        setDocumentClausules([...newArray]);
    }

    return (
        <Grid item xs={12}>

            <Grid item container xs={12} direction="row" sx={{ marginBottom:"20px", display:"flex", alignItems:"center" }}>
                <Autocomplete
                  id="combo-box-building"
                  size="small"
                  value={selectedBuilding}
                  options={arrayBuildings || []}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  sx={{width:'300px', padding: '8px'}}
                  renderInput={(params) => <TextField {...params} label="Edificios" size="small" />}
                  onChange={(e, newValue) => {
                    setSelectedBuilding(newValue)
                    newValue?.id && getDepartmentsAPI(newValue);
                  }}
                />
                <Autocomplete
                  id="combo-box-department"
                  size="small"
                  value={selectedDepartment}
                  options={arrayDepartments || []}
                  disabled={!selectedBuilding?.id}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  sx={{width:'300px', padding: '8px'}}
                  renderInput={(params) => <TextField {...params} label="Departamento" size="small" />}
                  onChange={(e, newValue) => {
                    setSelectedDepartment(newValue)
                  }}
                />
                <Button
                  onClick={ () => getContractApi() }
                  startIcon={<SearchIcon/>} 
                  sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none',height: '40px' , fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}
                >
                  ver contrato
                  {
                    loading && (
                      <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                    )
                  }
                </Button>
            </Grid>

            <Grid item container xs={12}>
                <Grid xs={12}>
                    <Typography sx={{ fontSize:18, fontWeight:600}}>Configurar Documento Contrato de Renta propietario</Typography>
                </Grid>
                <Typography className="text-info" >{'(*) Para utilizar las variables debe poner el cursor en uno de los inputs, y dar espacio seguido de "@", se abrirá un modal con las variables'}</Typography>
            </Grid>
            <Divider />
            <Card sx={{ padding: '10px', marginTop: '20px' }}>
                <CardContent>
                    {
                        loading && (
                        <>
                            <Skeleton variant="rectangular" width="100%" height={'60vh'} />
                        </>
                        )
                    }
                    {
                        (!loading && contract && Object.entries(contract).length > 0) && (
                            <>
                            <Grid xs={12} sx={{textAlign:'center'}}>
                                <Typography align='center' style={{ fontWeight: 'bold', fontSize: 13 }}><b>{contract?.contract?.title}</b></Typography>
                            </Grid>

                            <Grid xs={12} sx={{textAlign:'center'}}>
                                <Typography align='center' style={{ fontWeight: 'bold', fontSize: 12}}><span dangerouslySetInnerHTML={{ __html: VariableToText(contract?.contract?.description.replace(/\n/g, '<br />')) }} /></Typography>
                            </Grid>

                            <Grid sx={{ overflowY: 'scroll', height: '70vh', my: 2 }} container>
                                {
                                    documentClausules.length > 0 &&  documentClausules.map((item, key)=>(
                                        <>
                                            <Button onClick={() => { setClausuleSelected(item.id) }}>Editar</Button>
                                            <Button onClick={() => { deleteClause(item) }}>Eliminar</Button>
                                            <Grid xs={12} sx={{ mb: 2 }} key={key}>
                                                {
                                                    item.title != '' && (
                                                        <TextField sx={{ fontWeight: 'bold', fontSize: 10 }}
                                                            multiline
                                                            fullWidth
                                                            id='name-clausula'
                                                            name='title'
                                                            value={item.title}
                                                            disabled={clausuleSelected!= item.id}
                                                            onChange={handleInput}
                                                        />
                                                    )
                                                }
                                                
                                                {
                                                    item.clausule != '' && (
                                                        <TextField sx={{ fontSize: 9 }}
                                                            multiline
                                                            fullWidth
                                                            id='text-clausula'
                                                            name={'clausule'}
                                                            disabled={clausuleSelected!= item.id}
                                                            value={item.clausule}
                                                            onChange={handleInput}
                                                        />
                                                    )
                                                }
                                                
                                            </Grid>
                                            <Grid sx={{ width: '100%', textAlign: 'end' }}>
                                                <Button disabled={clausuleSelected != item.id} onClick={() => editClausule(item) }>
                                                    Guardar Cambios
                                                    {
                                                        loadingEdit && (
                                                        <CircularProgress color="primary" size={12} sx={{ marginLeft: "4px" }} />
                                                        )
                                                    }
                                                </Button>
                                            </Grid>
                                        </>
                                    ))
                                }
                            </Grid>
                            </>
                        )
                    }
                </CardContent>
            </Card>
            {
                openModal &&
                <ModalContractVariables
                    open={openModal}
                    closeModal={() => { setOpenModal(false) }}
                    dataVariables={variables}
                    variableSelected={variableSelected}
                    setVariableSelected={setVariableSelected}
                    evaluateVar={evaluateVar}
                />
            }
            {
                showModalConfirm && (
                    <ModalConfirm
                        title={'¿Esta seguro de eliminar la claúsula seleccionada?'}
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        onConfirm={confirmDeleteClause}
                        status2={loadingdelete}
                    />
                )
            }
        </Grid>
    );
}