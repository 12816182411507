import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, FormLabel, Autocomplete, InputAdornment } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { ConceptCategory } from "../../types/slices/conceptCategoryType"
import { SpentType } from "../../types/slices/spentTypeType"
import { useSpentTypeStore } from "../../hooks/useSpentTypeStore"
import { useConceptCategoryStore } from "../../hooks/useConceptCategoryStore"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../types/api/status"
import { usePaymentConceptStore } from "../../hooks/usePaymentConceptStore"
import { useNavigate } from "react-router-dom"
import { PaymentConcept } from "../../types/slices/paymentConceptType"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"
import { useBuildingSpentStore } from "../../hooks/useBuildingSpentStore"
import { useBuildingStore } from "../../hooks/useBuildingStore"
import { Building } from "../../types/slices/buildingType"

type BuildingSpentDataForm = {
    idconcept_spent: number | string,
    idbuilding: number | string,
    amount: number,
}

export const PaymentConceptBuildingForm = (props) => {
  const nagivate = useNavigate()
    
  const { status: statusPaymentConceptBuildingAPI, selectedBuildingSpent, buildingSpentsExisting, action, setActionBuildingSpent, createdBuildingSpent, editBuildingSpent } = useBuildingSpentStore();
  const { status: statusPaymentConceptAPI, paymentConcepts, selectedPaymentConcept, getPaymentConcepts, setSelectedPaymentConcept } = usePaymentConceptStore();
  const { status: statusBuildingAPI, buildings, selectedBuilding, getBuildings, setSelectedBuilding } = useBuildingStore();

    const [ isEdit, setIsEdit ] = useState<boolean>(false)
    const [data, setData] = useState<BuildingSpentDataForm>({
        idconcept_spent: '',
        idbuilding: "",
        amount: 0,
    })

    useEffect(()=>{
        if(!selectedBuildingSpent.id && action=='') {
            nagivate({ pathname: RoutesMap.PAYMENT_CONCEPT_BUILDING}, { replace: true })
        }
    }, [!selectedBuildingSpent.id])

    useEffect(()=>{
      setSelectedPaymentConcept({} as PaymentConcept)
      setSelectedBuilding({} as Building)
      getBuildings()

      let params:any = { idnotlevel: 3 };
      if (!selectedBuildingSpent?.id) {
        params = {...params, idExisting: buildingSpentsExisting}
      }
      getPaymentConcepts(params)
    },[])

    useEffect(()=>{
      if(selectedBuildingSpent.id && buildings.length > 0 && paymentConcepts.length > 0) {
        setIsEdit(true)
        setData({
          idconcept_spent: selectedBuildingSpent.idconcept_spent,
          idbuilding: selectedBuildingSpent.idbuilding,
          amount: selectedBuildingSpent.amount,
        })
        const conceptSpentSelect = setCustomPaymentConcepts(selectedBuildingSpent.idconcept_spent, paymentConcepts)
        const buildingSelect = setCustomBuildings(selectedBuildingSpent.idbuilding, buildings) 
        setSelectedPaymentConcept(conceptSpentSelect[0] || null)
        setSelectedBuilding(buildingSelect[0] || null)
        
      }
    },[selectedBuildingSpent, buildings, paymentConcepts])

    // PaymentConcepts
    const setCustomPaymentConcepts = (idconcept_spent:number | undefined, original_concept_spents:Array<PaymentConcept>) => {
      const data = idconcept_spent ? original_concept_spents.filter((value) => value.id === idconcept_spent) : [];
      return getCustomPaymentConcepts(data);
    }

    const getCustomPaymentConcepts = (original_concept_spents:Array<PaymentConcept>) => {
      const res:Array<PaymentConcept> = original_concept_spents ? original_concept_spents.map(concept => ({...concept})) : [];
      return res;
    }

    const customPaymentConcepts = getCustomPaymentConcepts(paymentConcepts || []);

    // buildings
    const setCustomBuildings = (idbuilding:number | undefined, original_buildings:Array<Building>) => {
      const data = idbuilding ? original_buildings.filter((value) => value.id === idbuilding) : [];
      return getCustomBuildings(data);
    }

    const getCustomBuildings = (original_buildings:Array<Building>) => {
      const res:Array<Building> = original_buildings ? original_buildings.map(building => ({...building})) : [];
      return res;
    }

    const customBuildings = getCustomBuildings(buildings || []);

    const validateForm = (values) => {
      let errors:any = {};
      if(!values.idconcept_spent) errors.idconcept_spent = "Campo requerido"
      if(!values.idbuilding) errors.idbuilding = "Campo requerido"
      if(!values.amount) errors.amount = "Campo requerido"
      return errors;
    }

    const onCancel = async ()  => {
      nagivate({ pathname: RoutesMap.PAYMENT_CONCEPT_BUILDING })
    }

    const onSubmit = async (values)  => {
      const method = !isEdit ? createdBuildingSpent(values) : editBuildingSpent(selectedBuildingSpent.id, values)
      
      const response = await method
      if (response === true) {
        setActionBuildingSpent('')
        nagivate({ pathname: RoutesMap.PAYMENT_CONCEPT_BUILDING })
      }
    }

    return (
        <CustomForm >
            {
                statusPaymentConceptBuildingAPI === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }
            {
                statusPaymentConceptAPI === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }
            {
                statusBuildingAPI === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }
            <CustomFormHeader
                title={ !isEdit ? 'Nuevo Concepto de Gasto' : 'Editar Concepto de Gasto'}
                text={ "Solo se puede editar el monto de los conceptos de tipo Fijos"}
                goBack={RoutesMap.PAYMENT_CONCEPT_BUILDING}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>
                                  <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={6}>
                                      <FormLabel>Edificio:</FormLabel>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-spent-type"
                                        value={selectedBuilding}
                                        options={customBuildings || []}
                                        getOptionLabel={(option) => option.name || ""}
                                        isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                        fullWidth
                                        renderInput={(params) => 
                                          <TextField
                                            {...params}
                                            label=""
                                            size="small"
                                            error={errors.idbuilding && touched.idbuilding ? true : false}
                                            helperText={errors.idbuilding && touched.idbuilding ? errors.idbuilding : ''}
                                          />}
                                        onChange={(e, newValue) => {
                                          setSelectedBuilding(newValue || {} as Building);
                                          setFieldValue("idbuilding", newValue?.id)
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <FormLabel>Concepto:</FormLabel>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-category"
                                        value={selectedPaymentConcept}
                                        options={customPaymentConcepts || []}
                                        getOptionLabel={(option) => option.description || ""}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        fullWidth
                                        renderInput={(params) => 
                                          <TextField
                                            {...params}
                                            label=""
                                            size="small"
                                            error={errors.idconcept_spent && touched.idconcept_spent ? true : false}
                                            helperText={errors.idconcept_spent && touched.idconcept_spent ? errors.idconcept_spent : ''}
                                          />}
                                        onChange={(e, newValue) => {
                                          setSelectedPaymentConcept(newValue || {} as PaymentConcept);
                                          setFieldValue("idconcept_spent", newValue?.id)
                                          newValue?.name_spent_type == "Fijos" && setFieldValue("amount", 0)
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <FormLabel>Tipo de concepto:</FormLabel>
                                      <TextField
                                        fullWidth
                                        type="text"
                                        value={ selectedPaymentConcept?.name_spent_type || "" }
                                        variant="standard"
                                        InputProps={{
                                          readOnly: true
                                        }}
                                      />
                                    </Grid>
                                    {
                                      !isEdit && (
                                        <Grid item xs={12} md={6}>
                                          <FormLabel>Monto:</FormLabel>
                                          <TextField
                                            fullWidth
                                            value={ values.amount }
                                            onChange={(e) => {
                                              const regex = /^\d+(\.\d{0,2})?$/
                                              const { value } = e.target
                                              if (value === "" || regex.test(value)) {
                                                setFieldValue('amount', value)
                                              }
                                            }}
                                            variant="standard"
                                            InputProps={{
                                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            error={errors.amount && touched.amount ? true : false}
                                            helperText={errors.amount && touched.amount ? errors.amount: ''}
                                            disabled={!!!selectedPaymentConcept?.id || (selectedPaymentConcept?.name_spent_type == "Variables")}
                                          />
                                        </Grid>
                                      )
                                    }
                                    
                                  </Grid>
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                  buttonType="submit"
                                  confirmText={ !isEdit ? 'Guardar' : 'Actualizar'}
                                  cancelText={"Cancelar"}
                                  onConfirm={handleSubmit}
                                  onCancel={onCancel}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
    )
}