import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { Grid, Divider, TextField, Autocomplete, Typography, Select, MenuItem } from "@mui/material"
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useVisitStore } from "../../hooks/useVisitStore";
import { RoutesMap } from "../../types";
import moment from "moment";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";

type Values = {
    id:number,
    rut: string,
    name: string,
    email: string,
    phone: string,
    created_by: string,
    qr: string,
    start_date: string,
    end_date: string | null,
    numbervisitors: number,
    idowner: number,
    iddepartment: number,
    idcompany: number,
}

export const VisitsByDepartmentModal: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, row, department } = props;

  const { search } = useSelector(selectHeaderState);

  const { status: statusVisitApi, action, getVisitsByDepartment, createVisit, editVisit } = useVisitStore();

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const currentDate = new Date().toISOString().split('T')[0];

  const [data, setData] = useState<Values>({
    id: row ? row.id : '',
    rut: row ? row.rut : '',
    name: row ? row.name : "",
    email: row ? row.email : "",
    phone: row ? row.phone : "",
    qr: row ? row.qr : "",
    start_date: row ? row.start_date : "",
    end_date: row ? row.end_date : null,
    created_by: row ? row.created_by : '-----',
    numbervisitors: row ? row.numbervisitors : 1,
    idowner: row ? row.idowner : "",
    iddepartment: row ? row.idowner : "",
    idcompany: row ? row.idowner : "",
  });

  const validateForm = (values) => {
    let errors: any = {};
    if(!values.rut) errors.rut = "Campo requerido";
    if(!values.name) errors.name = "Campo requerido";
    if(!values.email) errors.email = "Campo requerido";
    if(!values.start_date) errors.start_date = "Campo requerido";
    
    return errors;
  }
  
  const onSubmit = async (formData) => {
    if(formData.end_date){
      let dateStart = moment(formData.start_date, "YYYY-MM-DD");
      let dateEnd = moment(formData.end_date, "YYYY-MM-DD");
      if(dateStart > dateEnd) return CustomSnackbar('warning', 'La fecha de llegada no puede ser menor a la fecha de salida.');
    }

    setLoadingSubmit(true); 
    const ownerdepartment = (department?.owners && (department.owners).length > 0) ? department.owners.find(owner => owner.status == '1') : '';

    const urlqr = window.location.origin +''+ RoutesMap.SCANNER_QR +'?rut='+ formData.rut;

    const data = {
      ...formData,
      iddepartment: department.detail.iddepartment,
      idowner: ownerdepartment ? ownerdepartment.idowner : '',
    }
    if(row && action=='edit'){
      const response:any = await editVisit(row.id, data);
      setLoadingSubmit(false);
      if (response) { 
        getVisitsByDepartment(department.detail.iddepartment)
        closeModal && closeModal();
      }else{
        return false;
      }
    }else{
      const response:any = await createVisit({...data, qr:urlqr});
      setLoadingSubmit(false);
      if (response) { 
        getVisitsByDepartment(department.detail.iddepartment)
        closeModal && closeModal();
      }else{
        return false;
      }
    }
  }

  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={action=='add' ? "Crear visita" : (action=='edit' ? 'Editar visita' : 'Detalle de la visita')}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({values, errors, touched, handleSubmit, setFieldValue, handleChange}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"N° Rut"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="rut"
                      type="text" 
                      name="rut"
                      fullWidth
                      size="small"
                      value={values.rut}
                      onChange={handleChange}
                      error={errors.rut && touched.rut ? true : false}
                      helperText={errors.rut && touched.rut ? errors.rut : ''}
                      placeholder="rut del visitante"
                      inputProps={{ readOnly: action=='detail' ? true : false }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Nombres"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="name"
                      type="text" 
                      name="name"
                      fullWidth
                      size="small"
                      value={values.name}
                      onChange={handleChange}
                      error={errors.name && touched.name ? true : false}
                      helperText={errors.name && touched.name ? errors.name : ''}
                      placeholder="nombre completo"
                      inputProps={{ readOnly: action=='detail' ? true : false }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Correo"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="email"
                      type="text" 
                      name="email"
                      fullWidth
                      size="small"
                      value={values.email}
                      onChange={handleChange}
                      error={errors.email && touched.email ? true : false}
                      helperText={errors.email && touched.email ? errors.email : ''}
                      placeholder="correo del visitante"
                      inputProps={{ readOnly: action=='detail' ? true : false }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Teléfono"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="phone"
                      type="text" 
                      name="phone"
                      fullWidth
                      size="small"
                      value={values.phone}
                      onChange={handleChange}
                      error={errors.phone && touched.phone ? true : false}
                      helperText={errors.phone && touched.phone ? errors.phone : ''}
                      placeholder="teléfono del visitante"
                      inputProps={{ readOnly: action=='detail' ? true : false }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"N° de personas"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="numbervisitors"
                      type="number" 
                      name="numbervisitors"
                      fullWidth
                      size="small"
                      value={values.numbervisitors}
                      onChange={handleChange}
                      error={errors.numbervisitors && touched.numbervisitors ? true : false}
                      helperText={errors.numbervisitors && touched.numbervisitors ? errors.numbervisitors : ''}
                      inputProps={{ readOnly: action=='detail' ? true : false }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Llegada de la visita"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="start_date"
                      type="date" 
                      name="start_date"
                      fullWidth
                      size="small"
                      value={values.start_date}
                      onChange={handleChange}
                      error={errors.start_date && touched.start_date ? true : false}
                      helperText={errors.start_date && touched.start_date ? errors.start_date : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: action=='detail' ? true : false, min: currentDate, }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Salida de la visita"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="end_date"
                      type="date" 
                      name="end_date"
                      fullWidth
                      size="small"
                      value={values.end_date}
                      onChange={handleChange}
                      error={errors.end_date && touched.end_date ? true : false}
                      helperText={errors.end_date && touched.end_date ? errors.end_date : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: action=='detail' ? true : false, min: currentDate, }}
                    />
                  </Grid>
                </Grid>
                {
                  action=='edit' && (
                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>{"Creado por"}:</b></Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <TextField
                          id="created_by"
                          type="text" 
                          name="created_by"
                          fullWidth
                          size="small"
                          value={values.created_by}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ readOnly: action=='detail' ? true : false }}
                        />
                      </Grid>
                    </Grid>
                  )
                }
                
                <Divider />
                
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={action!='detail' && 'Guardar'}
                    onConfirm={action!='detail' && handleSubmit}
                    loadingConfirmText={loadingSubmit}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                  />
                </Grid>
              </form>
            )
          }} 
          </Formik>
        </ModalBody>
      </Modal>
    </>
    )
}