import { ApiStatus } from "../types/api/status"
import { useDispatch, useSelector } from "../redux/store"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { changeStatus, onFetchBuilding, onFetchBuildingByDepartment, onFetchBuildingFilter, onSetSelectedBuilding, onSetSelectedBuildingByDepartment, selectBuildingState } from "../redux/slices/buildingSlice"
import { BuildingAPI } from "../apis/BuildingAPI"
import { Building, BuildingByDepartment } from "../types/slices/buildingType"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useBuildingStore = () => {

  const dispatch = useDispatch()
  const { buildings, buildingsFilter, buildingByDepartment, status, selectedBuilding, selectedBuildingByDepartment } = useSelector(selectBuildingState)
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany
  
    const getBuildings = async (data?: any) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await BuildingAPI.getBuilding(data)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
        }
        const { detail } = response.data
        dispatch(onFetchBuilding(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
      } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
      }
    }
  
    const getBuildignFilter = async (data?: any) => {
      try {
        dispatch(onFetchBuildingFilter([]));
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await BuildingAPI.getBuilding()
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        const { detail } = response.data
        const newArray = detail.filter(obj => !data.some(idObj => idObj.idbuilding === obj.id));
        dispatch(onFetchBuildingFilter(newArray))
        dispatch(changeStatus(ApiStatus.FETCHED))
      } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
      }
    }
    
    const getBuildingByDepartments = async (id?: number) => {
      try {
        dispatch(onFetchBuildingByDepartment([]))
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await BuildingAPI.getBuildingByDepartments(id)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
        }
        const { detail } = response.data
        dispatch(onFetchBuildingByDepartment(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
      } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
      }
    }

    const createdBuilding = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await BuildingAPI.createBuilding(data, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return response?.data?.detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const createdRealtionBuildingAndDepa = async (data: any, idbuilding: number) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await BuildingAPI.createBuildingAndDepa(data, idbuilding)
          if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || '')
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message || '')
          return true
      } catch (error) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          console.log(error)
      }
  }

    const editBuilding = async (id_building: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await BuildingAPI.editBuilding(id_building, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return response?.data?.detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const deleteBuilding= async (id_building: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await BuildingAPI.deleteBuilding(id_building)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const setSelectedBuilding = (building: Building) => {
        try {
            dispatch(onSetSelectedBuilding(building))
        } catch (error) {
            console.log(error)
        }
    }
    
    const setSelectedBuildingByDepartment = (building: BuildingByDepartment) => {
        try {
            dispatch(onSetSelectedBuildingByDepartment(building))
        } catch (error) {
            console.log(error)
        }
    }

    const deleteBuildingByDepartment = async (id: number) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await BuildingAPI.deleteBuildingByDepartment(id)
          if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || '')
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message || '')
          return true
      } catch (error) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          console.log(error)
      }
  }

    return {
      //states
      buildings,
      buildingsFilter,
      buildingByDepartment,
      status,
      selectedBuilding,
      selectedBuildingByDepartment,
      //actions
      getBuildings,
      getBuildignFilter,
      getBuildingByDepartments,
      createdBuilding,
      createdRealtionBuildingAndDepa,
      editBuilding,
      deleteBuilding,
      setSelectedBuilding,
      setSelectedBuildingByDepartment,
      deleteBuildingByDepartment
    }
}