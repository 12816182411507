import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: any =
{
    status: ApiStatus.FETCHED,
    documents: [],
    setdocuments: [],
    historyDocuments: [],
    sethistoryDocuments: [],
    errorMessage: undefined,
    selectedDocument: {} as any,
    action: '',
}

const documentSlice = createSlice({
    name: 'documentSlice',
    initialState,
    reducers: {
        onFetchDocuments (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.documents  = payload
            state.errorMessage = undefined
        },
        onFetchHistoryDocuments (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.historyDocuments  = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedDocument (state, { payload }: { payload: any }) {
            state.selectedDocument = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectDocumentState = (state: RootState) => state.documentSlice
export default documentSlice.reducer

export const {
    onFetchDocuments,
    onFetchHistoryDocuments,
    changeStatus,
    onSetSelectedDocument,
    setAction,
} = documentSlice.actions