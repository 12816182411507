import { changeStatus, onFetchDepartmentByOwner, onFetchDepartments, onSetSelectedDepartment, selectDepartmentState, setAction, onFetchDepartmentsFilter, onSetSelectedBuildingOwner } from "../redux/slices/departmentSlice"
import { ApiStatus } from "../types/api/status"
import { DepartmentAPI } from "../apis/DepartmentAPI"
import { Department } from "../types/slices/departmentType"
import { useDispatch, useSelector } from "../redux/store"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { Role } from "../types/roles/roleTypes"
import { readLocalStorage, saveLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useDepartmentStore = () => {
  const dispatch = useDispatch()
  const { departments, departmentByOwner, status, selectedDepartment, selectedDepartmentByOwner, action, departmentsFilter, selectedBuildingOwner } = useSelector(selectDepartmentState)
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getDepartments = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.getDepartments(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      const user = readLocalStorage(LocalStorageKey.USER_DATA);
      
      if (user.userType == Role.OWNER || user.userType == Role.FAMILY) {
        saveLocalStorage(LocalStorageKey.DEPARTMENTS_OWNER, detail);
      }
      dispatch(onFetchDepartments(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }

  const deparmentWithOutBuilding = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      dispatch(onFetchDepartmentsFilter([]));
      const response = await DepartmentAPI.deparmentWithOutBuilding(data)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      dispatch(onFetchDepartmentsFilter(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }

  const getDepartmentsFilter = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      dispatch(onFetchDepartmentsFilter([]));
      const response = await DepartmentAPI.getDepartments()
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      const newArray = detail.filter(obj => !data.some(idObj => idObj.iddepartment === obj.id));
      dispatch(onFetchDepartmentsFilter(newArray))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }
  
  const getDepartmentsByOwner = async (id?: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.getDepartmentsByOwner(id)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      dispatch(onFetchDepartmentByOwner(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }

  const createDepartment = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.createDepartment(data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || '')
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return {
        status: true,
        data: response.data
      }
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const editDepartment = async (iddepartment: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.editDepartment(iddepartment, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const deleteDepartment = async (iddepartment: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.deleteDepartment(iddepartment)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || '')
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const setSelectedDepartment = (department: Department) => {
    try {
      dispatch(onSetSelectedDepartment(department))
    } catch (error) {
      console.log(error)
    }
  }

  const setActionDepartment = (state) => {
    try {
      dispatch(setAction(state))
    } catch (error) {
      console.log(error)
    }
  }

  const setSelectedBuildingOwner = (state) => {
    try {
      dispatch(onSetSelectedBuildingOwner(state))
    } catch (error) {
      console.log(error)
    }
  }

  const editDepartmentAll = async (iddepartment: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.editDepartmentAll(iddepartment, data)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const addDepartmentOwner = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.addDepartmentOwner(data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || '')
        return false
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return {
        status: true,
        data: response.data
      }
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const deleteDepartmentsByOwner = async (id:number,data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.deleteDepartmentsByOwner(id,data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || '')
        return false
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const editPercentage = async (id: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await DepartmentAPI.editPercentage(id, data)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return {
        status: true,
        data: response.data
      }
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const getBuilding = async (idowner:any) => {
    try {      
      const buildings = await DepartmentAPI.getBuilding(idowner, idcompany || 1);
      saveLocalStorage(LocalStorageKey.BUILDING_OWNER, buildings.detail || []);

      return true;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    //states
    departments,
    departmentByOwner,
    selectedBuildingOwner,
    status,
    selectedDepartment,
    selectedDepartmentByOwner,
    action,
    departmentsFilter,
    //actions
    getBuilding,
    getDepartments,
    getDepartmentsByOwner,
    deparmentWithOutBuilding,
    deleteDepartmentsByOwner,
    createDepartment,
    editDepartment,
    deleteDepartment,
    setSelectedDepartment,
    editDepartmentAll,
    addDepartmentOwner,
    setActionDepartment,
    setSelectedBuildingOwner,
    editPercentage,
    getDepartmentsFilter,
  }
}
