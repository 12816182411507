import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material"
import { useContractStore } from "../../hooks/useContractStore";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";

type Values = {
  type: number,
  idnewowner: number|string,
  start_date: string,
  end_date: string,
  description: string,
}

export const PreviewContractModal: React.FC<any> = (props): JSX.Element | any => { 
  const { open, closeModal, row, dataForm, confirm, contractSelected } = props;

  const { status: statusContract, contract, getContract, getContractOwner, resetGetContract } = useContractStore();
  const { user } = useAuthStore()
  const idowner:any = user?.data?.id
  const role:any = user?.userType

  const [variables,setVaribales] = useState<any>([])
  const [documentClausules, setDocumentClausules] = useState<any>([])
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    resetGetContract();
    getContractAPI();
  }, [])

  useEffect(()=>{
    if(contract){
      setFormated(contract?.variables)
      setDocumentClausules(contract?.detailContract)
    }
  },[contract])

  useEffect(()=>{
    if(variables.length > 0){
      setDocumentClausules(EstructurarData(documentClausules, true))
    }
  },[variables])

  const getContractAPI = async () => {
    try {
      setLoading(true);
      const params: any = {
        type: '2',
        iddepartment: row?.id,
        idnewowner: dataForm?.idnewowner,
        start_date: dataForm?.start_date,
        end_date: dataForm?.end_date
      };
      if(role == Role.ADMIN){
        const response = await getContract({...params, idcontract: contractSelected?.id});
        setLoading(false);
      }else if (role == Role.AGENT){
        const ownerdepartment = (row?.owners && (row.owners).length > 0) ? row.owners.find(owner => owner.status == '1') : '';
        if(ownerdepartment){
          if(ownerdepartment.is_admin == '1'){
            const response = await getContract({...params, idcontract: contractSelected?.id});
          }else{
            const response = await getContractOwner({...params, idowner:ownerdepartment.idowner, idcontract: contractSelected?.id});
          }
        }
      }else{
        const response = await getContractOwner({...params, idcontract: contractSelected?.id});
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const setFormated = (data:[]) => {
    const objectsArray:any = [];
    for (const key in data) {
      // objectsArray.push({key:`{{${key}}}`, value: data[key] });
      objectsArray.push({key:key, value: data[key] });
    }
    setVaribales(objectsArray)
  }

  const VariableToText = (textVariable) => {
    let newText = textVariable
    variables && variables.map((item) => {
        newText = newText.replace(item.key, item.value)
    })
    return newText
  }

  const TextToVariable = (text) => {
    let textChange = text;
    variables && variables.map((item) => {
      textChange = textChange.replace(item.value, item.key)
    })
    return textChange
  }

  const EstructurarData = (documentData, toText) => {
    const newArrayEstructura:any = [];
    documentData.map((item, i) => {
      const objNew = {
        id: item.id,
        clausule: toText ? VariableToText(item.clausule) : TextToVariable(item.clausule),
        position: i,
        title: toText ? VariableToText(item.title) : TextToVariable(item.title),
        status: item.status
      }
      newArrayEstructura.push(objNew);
    })

    return newArrayEstructura
  }

  const confirmContract = () => {
    confirm();
  }

  return (
    <>
      <Modal open={open} disableEscapeKeyDown disableBackdropClick size="md">
        <ModalHeader
          text={"Vista Previa de Contrato"}
          className="positionElements"
          disabledButton={true}
        />
        <ModalBody>
          <Card sx={{ padding: '5px 10px' }}>
            <CardContent>
              {
                loading ? (
                  <>
                    <Skeleton variant="rectangular" width="100%" height={'60vh'} />
                  </>
                ) : (
                  <>
                    <Grid xs={12} sx={{textAlign:'center'}}>
                      <Typography align='center' style={{ fontWeight: 'bold', fontSize: 13 }}><b>{contract?.contract?.title}</b></Typography>
                    </Grid>
                    <Grid xs={12} sx={{textAlign:'center'}}>
                      <Typography align='center' style={{ fontWeight: 'bold', fontSize: 12}}><span dangerouslySetInnerHTML={{ __html: VariableToText(contract?.contract?.description.replace(/\n/g, '<br />')) }} /></Typography>
                    </Grid>

                    <Grid sx={{ overflowY: 'scroll', height: '50vh', my: 2 }} container>
                      {
                        documentClausules.length > 0 &&  documentClausules.map((item, key)=>(
                          <>
                            <Grid xs={12} sx={{ mb: 2 }} key={key}>
                              {
                                item.title != '' && (
                                  <Grid>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 12}}>
                                      <span dangerouslySetInnerHTML={{ __html: VariableToText(item.title.replace(/\n/g, '<br />')) }} />
                                    </Typography>
                                  </Grid>
                                )
                              }
                              {
                                item.clausule != '' && (
                                  <Grid>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 12}}>
                                      <span dangerouslySetInnerHTML={{ __html: VariableToText(item.clausule.replace(/\n/g, '<br />')) }} />
                                    </Typography>
                                  </Grid>
                                )
                              }
                              
                          </Grid>
                          </>
                        ))
                      }
                    </Grid>
                  </>
                )
              }
            </CardContent>
          </Card>
          
          <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
            <ModalFooter 
              confirmText={'Confirmar'}
              onConfirm={confirmContract}
              loadingConfirmText={loadingSubmit}
              cancelText={"Cancelar"}
              onCancel={closeModal}
            />
          </Grid>
        </ModalBody>
      </Modal>
    </>
  )
}