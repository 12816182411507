import { ButtonGroup, Grid, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import FileUploadIcon from '@mui/icons-material/FileUpload'
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"

import 'react-image-crop/dist/ReactCrop.css';

import { withStyles } from "@mui/styles"
import CloseIcon from '@mui/icons-material/Close'
import { useAuthStore } from "../../hooks"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"
import { useIdentificacionStore } from "../../hooks/useIdentificacionStore"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalCrop } from "../../components/common/ModalIdentification/ModalCrop"

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

export const IdentificationModal: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal } = props

    const { user } = useAuthStore()
    const { identifications, getIdentification } = useIdentificacionStore()
    
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ validated, setValidated ] = useState<boolean>(false)
    const [ showModalCrop, setShowModalCrop ] = useState<boolean>(false)
    const [ typeImage, setTypeImage ] = useState<any>(null)
    const [data, setData] = useState<any>({
        front: '',
        reverse: '',
        signature: '',
        person: ''
    })

    useEffect(()=>{
        getIdentificationUser()
    },[])

    useEffect(()=>{
        setData({
            front: '',
            reverse: '',
            signature: '',
            person: ''
        })
    },[identifications])

    const getIdentificationUser = async () => {
        setLoading(true)
        const response = await getIdentification({ iduser: user.id })
        if (response === true) {
            setValidated(true)
        }
        setLoading(false)
    }

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.pin) errors.pin = "PIN requerido";
        return errors;
    }

    const onSubmit = async () => {
    }

    const openModalCrop = (type:string) => {
        setTypeImage(type)
        setShowModalCrop(true)
    }

    const viewDocument = (type:string) => {
        let url = ''
        switch (type) {
            case 'front':
                url = identifications[0]?.front_identification || ''
                break;

            case 'reverse':
                url = identifications[0]?.reverse_identification || ''
                break;

            case 'signature':
                url = identifications[0]?.identification_signature || ''
                break;

            case 'person':
                url = identifications[0]?.person_identification || ''
                break;
        
            default:
                break;
        }
        if (url !== '') {
            const win:any = window.open(url, '_blank')
            win.focus()
        } else {
            CustomSnackbar('warning', 'No se ha registrado un documento.')
        }
    }
    
    const statusDocument = (type_document:string) => {
        let status:string = 'No registrado'
        if (identifications[0]) {
            let document:any = null
            let statusDocument = ''
            switch (type_document) {
                case 'front':
                    document = identifications[0].front_identification
                    statusDocument = identifications[0].status_front
                    break;
    
                case 'reverse':
                    document = identifications[0].reverse_identification
                    statusDocument = identifications[0].status_reverse
                    break;
    
                case 'signature':
                    document = identifications[0].identification_signature
                    statusDocument = identifications[0].status_signature
                    break;
    
                case 'person':
                    document = identifications[0].person_identification
                    statusDocument = identifications[0].status_person
                    break;
            
                default:
                    break;
            }
            if (document !== null && statusDocument !== '') {
                switch (statusDocument) {
                    case '0':
                        status = 'Pendiente'
                        break;
                
                    case '1':
                        status = 'Aprobado'
                        break;
                    
                    case '2':
                        status = 'Rechazado'
                        break;
                
                    default:
                        break;
                }
            }
        }
        return status
    }
    
    return (
        <>
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalBody>
                <Grid item container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <Grid item xs={1}></Grid>
                    <Typography sx={{ fontWeight: 'bolder', textAlign: 'center', margin: '0px auto' }}>
                        IDENTIFICACION
                    </Typography>
                    <IconButton
                        size="small"
                        color="inherit"
                        aria-label="view"
                        onClick={() => closeModal()}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Grid>
                {
                    loading
                    ?
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ background: "#D8E8F7" }}>
                                <TableRow>
                                    <StyledTableCell className="headTable" align="center">TIPO DE IDENTIFICACIÓN</StyledTableCell>
                                    <StyledTableCell className="headTable" align="center">ESTADO</StyledTableCell>
                                    <StyledTableCell className="headTable" align="center">ACCIONES</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid item container xs={12} display="flex" justifyContent="center">
                                            <Skeleton variant="rectangular" width="60%" height={20} />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <>
                    <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                        {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{ background: "#D8E8F7" }}>
                                        <TableRow>
                                            <StyledTableCell className="headTable" align="center">TIPO DE IDENTIFICACIÓN</StyledTableCell>
                                            <StyledTableCell className="headTable" align="center">ESTADO</StyledTableCell>
                                            <StyledTableCell className="headTable" align="center">ACCIONES</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">Frontal</TableCell>
                                            <TableCell align="center">{statusDocument('front')}</TableCell>
                                            <TableCell align="center">
                                                <ButtonGroup
                                                    variant="contained"
                                                    aria-label="button-group-custom-table"
                                                    style={{
                                                        padding:"0px 5px",
                                                        color: "#808080",
                                                    }}
                                                >
                                                    {
                                                        identifications[0]?.status_front != '1' && (
                                                            <Tooltip title="Subir documento">
                                                                <IconButton
                                                                    size="small"
                                                                    color="inherit"
                                                                    aria-label="view"
                                                                    onClick={() => openModalCrop('front')}
                                                                >
                                                                    <FileUploadIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    <Tooltip title="Ver">
                                                        <IconButton
                                                            size="small"
                                                            color="inherit"
                                                            aria-label="view"
                                                            onClick={() => viewDocument('front')}
                                                        >
                                                            <VisibilityIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Reversa</TableCell>
                                            <TableCell align="center">{statusDocument('reverse')}</TableCell>
                                            <TableCell align="center">
                                                <ButtonGroup
                                                    variant="contained"
                                                    aria-label="button-group-custom-table"
                                                    style={{
                                                        padding:"0px 5px",
                                                        color: "#808080",
                                                    }}
                                                >
                                                    {
                                                        identifications[0]?.status_reverse != '1' && (
                                                            <Tooltip title="Subir documento">
                                                                <IconButton
                                                                    size="small"
                                                                    color="inherit"
                                                                    aria-label="view"
                                                                    onClick={() => openModalCrop('reverse')}
                                                                >
                                                                    <FileUploadIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    <Tooltip title="Ver">
                                                        <IconButton
                                                            size="small"
                                                            color="inherit"
                                                            aria-label="view"
                                                            onClick={() => viewDocument('reverse')}
                                                        >
                                                            <VisibilityIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Firma</TableCell>
                                            <TableCell align="center">{statusDocument('signature')}</TableCell>
                                            <TableCell align="center">
                                                <ButtonGroup
                                                    variant="contained"
                                                    aria-label="button-group-custom-table"
                                                    style={{
                                                        padding:"0px 5px",
                                                        color: "#808080",
                                                    }}
                                                >
                                                    {
                                                        identifications[0]?.status_signature != '1' && (
                                                            <Tooltip title="Subir documento">
                                                                <IconButton
                                                                    size="small"
                                                                    color="inherit"
                                                                    aria-label="view"
                                                                    onClick={() => openModalCrop('signature')}
                                                                >
                                                                    <FileUploadIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    <Tooltip title="Ver">
                                                        <IconButton
                                                            size="small"
                                                            color="inherit"
                                                            aria-label="view"
                                                            onClick={() => viewDocument('signature')}
                                                        >
                                                            <VisibilityIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Persona e Identificación</TableCell>
                                            <TableCell align="center">{statusDocument('person')}</TableCell>
                                            <TableCell align="center">
                                                <ButtonGroup
                                                    variant="contained"
                                                    aria-label="button-group-custom-table"
                                                    style={{
                                                        padding:
                                                            "0px 5px",
                                                        color: "#808080",
                                                    }}
                                                >
                                                    {
                                                        identifications[0]?.status_person != '1' && (
                                                            <Tooltip title="Subir documento">
                                                                <IconButton
                                                                    size="small"
                                                                    color="inherit"
                                                                    aria-label="view"
                                                                    onClick={() => openModalCrop('person')}
                                                                >
                                                                    <FileUploadIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    <Tooltip title="Ver">
                                                        <IconButton
                                                            size="small"
                                                            color="inherit"
                                                            aria-label="view"
                                                            onClick={() => viewDocument('person')}
                                                        >
                                                            <VisibilityIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}}
                    </Formik>
                    
                    </>
                }
            </ModalBody>
        </Modal>
        {
            showModalCrop && (
                <ModalCrop
                    open={showModalCrop}
                    closeModal={()=> setShowModalCrop(false)}
                    typeImage={typeImage}
                    onList={() => getIdentificationUser()}
                />
            )
        }
        </>
        )
}