import { Button, Card, CardContent, Divider, Grid, TextField, Typography, CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from 'react';
import { useContractStore } from '../../hooks/useContractStore';
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { ModalContractVariables } from "../SettingContract/ModalContractVariables";
import '../../assets/styles/pewen-app.css';
import '../../assets/styles/contract.css';
import { useAuthStore } from "../../hooks";

export const EditContractTemplateOwner = (props) => {

    const navigate = useNavigate();

    const { user } = useAuthStore()
    const idowner:any = user?.data?.id

    const { selectedContract, listVariablesContract, findContractTemplatesOwner, updateClausuleOwner } = useContractStore();

    
    const [documentClausules, setDocumentClausules] = useState<any>([])
    const [variables, setVaribales] = useState<any>([])

    const [clausuleSelected, setClausuleSelected] = useState<number>(0)
    const [cursorActive, setCursorActive] = useState(null)
    const [idActive, setIdActive] = useState<any>(null)
    const [openModal, setOpenModal] = useState(false);
    const [variableSelected, setVariableSelected] = useState(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
    const [name, setName] = useState<any>('')
    const [description, setDescription] = useState<any>('')
    const [varEdit, setVarEdit] = useState<any>(null)

    useEffect(() => {
        if(Object.entries(selectedContract).length == 0) return navigate({ pathname: RoutesMap.LIST_CONTRACT_TEMPLATE })
    },[Object.entries(selectedContract).length == 0])

    useEffect(()=>{
        findContractTemplatesApi();
    },[])

    useEffect(() => {
        getVariablesContractApi()
    },[])

    useEffect(()=>{
        if(variables.length > 0 && documentClausules.length > 0){
            setDocumentClausules(EstructurarData(documentClausules, true))
        }
    },[ variables.length > 0 && documentClausules.length > 0 ])

    const findContractTemplatesApi = async() => {
        try {
            if(selectedContract?.contract?.id){
                setLoading(true);
                const response = await findContractTemplatesOwner({idcontract: selectedContract?.contract?.id, idowner: idowner});
                if(response){
                    setDocumentClausules(response.detailContract || [])

                    if(variables.length > 0 && response.detailContract.length > 0 && (varEdit)){
                        setDocumentClausules(EstructurarData(response.detailContract, true));
                    }
                }
                setVarEdit(null);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const getVariablesContractApi = async() => {
        try {
            setLoading(true);
            const response = await listVariablesContract();
            if(response){
                setVaribales(response || [])
                setLoading(false);
            }else{
                navigate({ pathname: RoutesMap.TEMPLATE_VARIABLES })
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }


    const handleInputName = (e) => {
        setName(e.target.value)
    }

    const handleInputDescription = (e) => {
        setDescription(e.target.value)
    }

    const handleInputHead = (e) => {
        const value = e.target.value
        let changedForm;
        let indice = 0;
        documentClausules.map((item, i) => {
            if (item.position == e.target.name) {
                changedForm = {
                    ...item,
                    title: e.target.value
                }
                indice = i
            }
        })
        documentClausules.splice(indice, 1, changedForm)
        setDocumentClausules([...documentClausules]);
    }

    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        if(value[e.target.selectionEnd - 1] == "@" && value[e.target.selectionEnd - 2] == " ") {
            setCursorActive(e.target.selectionEnd);
            setIdActive(name)
            setOpenModal(true);
            return
        } else {
            let changedForm;
            let indice = 0;

            documentClausules.map((item, i) => {
                if (item.id == name) {
                    changedForm = {
                        ...item,
                        clausule: value
                    }
                    indice = i
                }
            })
            documentClausules.splice(indice, 1, changedForm)
            setDocumentClausules([...documentClausules]);
        }
    }

    const evaluateVar = (e, obj) => {
        setOpenModal(false)
        if(!obj) return false

        let changedForm;
        let indice = 0;
        
        documentClausules.map((item, i) => {
            if (item.id == idActive) {
                const newText:any = item.clausule.split('');
                newText.splice(cursorActive, 0, ' ' + obj.value + ' ')
                changedForm = {
                    ...item,
                    clausule: newText.join('')
                }
                indice = i
            }
        })
        documentClausules.splice(indice, 1, changedForm)
        setDocumentClausules([...documentClausules]);
    }

    const TextToVariable = (text) => {
        let textChange = text;
        variables && variables.map((item) => {
            textChange = textChange.replace(item.value, item.key)
            
        })
        return textChange
    }

    const VariableToText = (textVariable) => {
        let newText = textVariable
        variables && variables.map((item) => {
            newText = newText.replace(item.key, item.value)
        })
        return newText
    }

    const EstructurarData = (documentData, toText) => {
        const newArrayEstructura:any = [];
        documentData.map((item, i) => {
            const objNew = {
                id: item.id,
                clausule: toText ? VariableToText(item.clausule) : TextToVariable(item.clausule),
                position: i,
                title: toText ? VariableToText(item.title) : TextToVariable(item.title),
                status: item.status
            }
            newArrayEstructura.push(objNew);
        })

        setVarEdit(null);

        return newArrayEstructura
    }

    const NewClausula = async(type) =>{

        let newObj = {
            title: "",
            clausule:'',
            position:documentClausules.length +1 ,
            type: ''
        }

        switch (type) {
            case 'subtitle':
                newObj = {
                    ...newObj, 
                    title:'',
                    type: 'subtitle',
                }
                break;

            case 'paragraph':
                newObj = {
                    ...newObj, 
                    title: ``, 
                    clausule: ``,
                    type: 'paragraph',
                }
                break;

            default:
                break;
        }

        setDocumentClausules([...documentClausules, newObj ]);

        setTimeout(function(){
            const miElemento = window.document.getElementById(`clauses-${documentClausules.length}`)
            if(miElemento){
                miElemento.scrollIntoView({ behavior: 'smooth' })
            }
        }, 1000);
    }

    const editClausule = async (clausule) => {
        try {
            setLoadingEdit(true);
            
            const textSend = {...clausule, clausule: TextToVariable(clausule.clausule), title: TextToVariable(clausule.title), type: selectedContract?.contract?.type}
            
            await updateClausuleOwner(clausule.id, textSend)
            findContractTemplatesApi();
            
            setClausuleSelected(0);
            setLoadingEdit(false);
        } catch (error) {
            setLoadingEdit(false);
            console.error(error);
        }
    }

    return (
        <Grid item xs={12}>
            <CustomFormHeader
                title={'Editar plantilla de contrato'}
                text={'(*) Para utilizar las variables debe poner el cursor en uno de los inputs, y dar espacio seguido de "@", se abrirá un modal con las variables'}
                goBack={RoutesMap.LIST_CONTRACT_TEMPLATE_OWNER}
            >
            </CustomFormHeader>

            <Divider />

            <Card sx={{ padding: '10px', marginTop: '20px' }}>
                <CardContent>
                    {
                        loading && (
                        <>
                            <Skeleton variant="rectangular" width="100%" height={'60vh'} />
                        </>
                        )
                    }
                    {
                        !loading && (
                            <>
                            <Grid xs={12} sx={{textAlign:'center'}}>
                                <Typography align='center' style={{ fontWeight: 'bold', fontSize: 16 }}>
                                    <b>{selectedContract?.contract?.title}</b>
                                </Typography>
                            </Grid>

                            <Grid xs={12} sx={{textAlign:'center'}}>
                                <Typography align='center' style={{ fontWeight: 'bold', fontSize: 14}}>
                                    <span 
                                        dangerouslySetInnerHTML = {{ 
                                            __html: VariableToText(selectedContract?.contract?.description.replace(/\n/g, '<br />')) 
                                        }} 
                                    />
                                </Typography>
                            </Grid>
                            {
                                documentClausules.length > 0 &&  documentClausules.map((item, key)=>(
                                    <>

                                    <Button onClick={() => { setClausuleSelected(item.id); setVarEdit(1) }}>Editar</Button>
                                    {/* <Button onClick={()=>{ }} >Eliminar</Button> */}

                                    <Grid xs={12} sx={{ mb: 2 }} key={key} id={`clauses-${key}`}>
                                        {
                                            item.title != '' && (
                                                <Grid item className="contract-container-subtitle">
                                                    <TextField 
                                                        id='name-clausula'
                                                        type="text"
                                                        multiline
                                                        fullWidth
                                                        name={item.position}
                                                        value={item.title}
                                                        onChange={handleInputHead}
                                                        size='small'
                                                        sx={{ fontSize: 10 }}
                                                        placeholder="Escriba el texto"
                                                        disabled={clausuleSelected!= item.id}
                                                    /><span >:</span>
                                                </Grid>
                                            )
                                        }
                                        
                                        {
                                            item.clausule != '' && (
                                                <Grid item >
                                                    <TextField 
                                                        id='text-clausula'
                                                        type="text"
                                                        multiline
                                                        fullWidth
                                                        name={item.id}
                                                        value={item.clausule}
                                                        onChange={handleInput}
                                                        size='small'
                                                        sx={{ fontSize: 9, marginTop:'5px' }}
                                                        placeholder="Escriba el texto"
                                                        disabled={clausuleSelected!= item.id}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </Grid>

                                    <Grid sx={{ width: '100%', textAlign: 'end' }}>
                                        <Button disabled={clausuleSelected != item.id} onClick={() => editClausule(item) }>
                                            Guardar Cambios
                                            {
                                                loadingEdit && (
                                                <CircularProgress color="primary" size={12} sx={{ marginLeft: "4px" }} />
                                                )
                                            }
                                        </Button>
                                    </Grid>

                                    </>
                                ))
                            }
                            </>
                        )
                    }
                    
                </CardContent>
            </Card>
            {
                openModal &&
                <ModalContractVariables
                    open={openModal}
                    closeModal={() => { setOpenModal(false) }}
                    dataVariables={variables}
                    variableSelected={variableSelected}
                    setVariableSelected={setVariableSelected}
                    evaluateVar={evaluateVar}
                />
            }
        </Grid>
    );
}