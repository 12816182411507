import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { Grid, Divider, TextField, Autocomplete, Typography, Select, MenuItem } from "@mui/material"
import { Owner } from "../../types/slices/ownerType";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useAgentStore } from "../../hooks/useAgentStore";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import moment from "moment";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";

type Values = {
  type: number,
  idestateagent: number|string,
  start_date: string,
  end_date: string,
  description: string,
}

export const AgentByDepartmentModal: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, row, owners } = props;

  const { search } = useSelector(selectHeaderState);

  const { status: statusAgentApi, selectedAgent, agents, getAgents, setSelectAgent, createAgentDepartment, getAgentsByDepartment } = useAgentStore();

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const currentDate = new Date().toISOString().split('T')[0];

  const [data, setData] = useState<Values>({
    type: 3,
    idestateagent: "",
    start_date: "",
    end_date: "",
    description: "",
  });

  const typeFormat = [
    {
      id: 1,
      name: "Comprador"
    },
    {
      id: 2,
      name: "Arrendador"
    },
    {
      id: 3,
      name: "Corredor"
    }
  ];

  useEffect(() => {
    setSelectAgent({} as any);
    getAgentsApi();
  }, [])

  const getAgentsApi = async (type?:any) => {
    try {      
      setLoading(true);
      const response = await getAgents({search});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleChangeSelectAgent = (newValue:Owner|null, setFieldValue:any) => {
    setSelectAgent(newValue || {} as any);
    setFieldValue('idestateagent', newValue ? newValue.id : "")
  }

  const validateForm = (values) => {
    let errors: any = {};
    if(!values.idestateagent) errors.idestateagent = "Campo requerido";
    if(!values.start_date) errors.start_date = "Campo requerido";
    if(!values.end_date) errors.end_date = "Campo requerido";
    
    return errors;
  }
  
  const onSubmit = async (formData) => {

    let dateStart = moment(formData.start_date, "YYYY-MM-DD");
    let dateEnd = moment(formData.end_date, "YYYY-MM-DD");
    if(dateStart > dateEnd) return CustomSnackbar('warning', 'La fecha incial no puede ser menor a la fecha final.');

    setLoadingSubmit(true); 
    const ownerdepartment = (row?.owners && (row.owners).length > 0) ? row.owners.find(owner => owner.status == '1') : '';
    const data = {
      ...formData,
      iddepartment: row.detail.iddepartment,
      idowner: ownerdepartment ? ownerdepartment.idowner : '',
    }
    const response:any = await createAgentDepartment(data);
    setLoadingSubmit(false);
    
    if (response) { 
      getAgentsByDepartment(row.detail.iddepartment)
      closeModal(true);
    }else{
      return false;
    }
  }

  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={"Generar Contrato"}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({values, errors, touched, handleSubmit, setFieldValue, handleChange}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Tipo"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Select
                        id="type"
                        value={values.type}
                        size="small"
                        onChange={(event) => { 
                          setFieldValue("type", event.target.value);
                        }}
                        error={ errors.type && touched.type ? true : false }
                        fullWidth
                        inputProps={{
                          readOnly: true,
                        }}
                    >
                        {
                            typeFormat.map((type, i) => {
                                return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                            })
                        }
                    </Select>
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Corredor"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={selectedAgent?.id ? selectedAgent : null}
                      options={agents || []}
                      loading={loading}
                      getOptionLabel={(option) => option?.name || ""}
                      fullWidth
                      isOptionEqualToValue={(option, value) => option?.name === value?.name}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          size="small"
                          error={errors.idestateagent && touched.idestateagent ? true : false}
                          helperText={errors.idestateagent && touched.idestateagent ? errors.idestateagent : ''}
                        />
                      }
                      onChange={(e, newValue) => {
                        handleChangeSelectAgent(newValue,setFieldValue);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Observación"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="description"
                      type="text" 
                      name="description"
                      fullWidth
                      size="small"
                      value={values.description}
                      onChange={handleChange}
                      error={errors.description && touched.description ? true : false}
                      helperText={errors.description && touched.description ? errors.description : ''}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Fecha inicial"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="start_date"
                      type="date" 
                      name="start_date"
                      fullWidth
                      size="small"
                      value={values.start_date}
                      onChange={(e)=>{handleChange(e)}}
                      error={errors.start_date && touched.start_date ? true : false}
                      helperText={errors.start_date && touched.start_date ? errors.start_date : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: currentDate,
                      }}
                    />
                  </Grid>
                </Grid>
                {
                  
                }
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Fecha final"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="end_date"
                      type="date" 
                      name="end_date"
                      fullWidth
                      size="small"
                      value={values.end_date}
                      onChange={(e)=>{handleChange(e)}}
                      error={errors.end_date && touched.end_date ? true : false}
                      helperText={errors.end_date && touched.end_date ? errors.end_date : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: currentDate,
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={'Guardar'}
                    onConfirm={handleSubmit}
                    loadingConfirmText={loadingSubmit}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                  />
                </Grid>
              </form>
            )
          }} 
          </Formik>
        </ModalBody>
      </Modal>
    </>
    )
}