
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from '../redux/store';
import { AuthStatus, LocalStorageKey, LoginType } from '../types';
import { readLocalStorage, saveLocalStorage } from '../toolbox/helpers/local-storage-helpers';
import { onChangeAuthStatus, onLogin, onLogout, onRefreshToken, onVerifying } from '../redux/slices/authSlice';
import { AuthAPI } from '../apis/AuthAPI';
import { ProfileAPI } from '../apis/ProfileAPI';
import { CustomSnackbar } from '../components/common/CustomSnackbar/CustomSnackbar';
import { NotificationSnackBar } from '../components/common/CustomSnackbar/NotificationSnackbar';
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { NotificationAPI } from '../apis/NotificationAPI';
import { onCounter, onFetchNotifications } from '../redux/slices/notificationSlice';

export const useAuthStore = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const { saveCounter} = useNotificationStore()
  const a = [Pusher]

  const { status, user, errorMessage } = useSelector(state => state.authSlice)
  const getNotifications = async ({perPage, page, idcompany}: {perPage: number, page: number, idcompany:number}) => {
    try {
      const response = await NotificationAPI.getNotifications({perPage, page, idcompany: idcompany})
      if (response?.data?.detail?.data){
        dispatch(onFetchNotifications(response.data.detail.data))
        dispatch(onCounter(response?.data?.detail?.count))
      }
    } catch (error) {
      // dispatch(changeStatus(ApiStatus.FETCHED))
      // console.error(error)
    }
  }

  const saveCounter = async (count: number) => {
    try {
       console.log('count',count)
       dispatch(onCounter(count))
    } catch (error) {
    //  dispatch(changeStatus(ApiStatus.FETCHED))
    //  console.log(error)
    }
  }

  const setProfileData = async (access_token: string, iduser_type?:number) => {
    try {
      const perfil = await ProfileAPI.get(access_token);
      const { detail } = perfil;
      saveLocalStorage(LocalStorageKey.USER_DATA, detail);
      dispatch(onLogin(detail));
      // connectPusher(detail.id, `${access_token}`)
      getNotifications({ perPage: 5, page: 1, idcompany: 1 })
      return detail;

    } catch (error) {
      console.error(error);
    }
  }

  const login = async ({ iduser_type, password, rut, idcompany}: LoginType) => {
    try {
      const login = await AuthAPI.login(iduser_type, password, rut, idcompany);
      if (!login?.detail?.access_token) {
        CustomSnackbar('error', login.message || 'Credenciales Incorrectas');
        return { status: false }
      }
      const { access_token } = login.detail
      saveLocalStorage(LocalStorageKey.TOKEN_KYTE, access_token)
  
      const detail = await setProfileData(access_token, iduser_type);
      CustomSnackbar('success', 'Bienvenido');

      return {
        status: true,
        detail
      }

    } catch (error) {
      // dispatch(onLogout('Credenciales Incorrectas'))
      CustomSnackbar('error', 'Credenciales Incorrectas');
      return {
        status: false,
        detail: {}
      }
    }
  }

  const verifySession = async () => {
    dispatch(onVerifying());
    const token = readLocalStorage(LocalStorageKey.TOKEN_KYTE);
    if (!token) return dispatch(onLogout());
    const isValidToken = await AuthAPI.verifyToken(token);
    try {
      
      if (isValidToken === "true"){
        await setProfileData(token)
        dispatch(onChangeAuthStatus(AuthStatus.AUTHENTICATED));
        navigate({ pathname: location.pathname })
        return
      }else{
        await refreshToken(token)
      }
      localStorage.clear();
      dispatch(onLogout());
      CustomSnackbar('error', 'Su sesión expiró');
    } catch (error) {
      CustomSnackbar('error', 'Su sesión expiró');
      localStorage.clear();
      dispatch(onLogout());
    }
  }

  const refreshToken = async (token: string) => {
    try {
      const session = await AuthAPI.refresh(token);
      if (!session?.detail?.token) {
        localStorage.clear();
        dispatch(onLogout());
        CustomSnackbar('error', 'Su sesión expiró');
      } else {
        const { token, user } = session.detail
        await setProfileData(token)
        saveLocalStorage(LocalStorageKey.TOKEN_KYTE, token)
        dispatch(onRefreshToken(user))

        navigate({ pathname: location.pathname })
      }
    } catch (error) {
      localStorage.clear();
      dispatch(onLogout());
      console.error('[Error whilte refreshing token]', error)
    }
  }

  const logout = async () => {
    const logout = await AuthAPI.logout();
    const { detail } = logout;
    CustomSnackbar('success', detail || 'Sesión cerrada con éxito');
    localStorage.clear();
    dispatch(onLogout());
  }

  const connectPusher = (UserId, token) => {
    if (UserId) {
      window['Echo'] = new Echo({
        broadcaster: 'pusher',
        key: 'fractio_key',
        wsHost: window.location.hostname,
        wsPort: 6003,
        cluster: 'mt1',
        // wssPort: 6003,
        disableStats: true,
        forceTLS: false,
        encrypted: false,
        enabledTransports: ['ws', 'wss'],
        // authEndpoint: 'https://www.costamar.factories.cl/api/broadcasting/auth',
        // authEndpoint: 'http://localhost:8000/api/broadcasting/auth',
        authEndpoint: `${process.env.REACT_APP_HOST}/pewen_api/broadcasting/auth`,
        auth: {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        },
      })
      window['Echo'].private(`notification${UserId}`).listen('NotificationsEvent', (e) => {
        console.log(e)
          console.log('company')
          saveCounter(e.count)
          NotificationSnackBar(e)
          // toast(e.content,
          //   {
          //     icon: '👏',
          //     style: {
          //       borderRadius: '10px',
          //       background: '#333',
          //       color: '#fff',
          //     },
          //   }
          // )
      })
    }
  }

  return {
    /* states */
    status,
    user,
    errorMessage,
    /* actions */
    login,
    logout,
    verifySession,
    setProfileData,
    connectPusher
  }
}
