import { api } from "./configs/axiosConfigs"

export const DepartmentAPI ={
    getDepartments: async (data?: any) => {
        const response = await api.get(
            '/departments/',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    deparmentWithOutBuilding: async (data?: any) => {
        const response = await api.get(
            '/departments/deparmentWithOutBuilding',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    createDepartment: async (data: any, idcompany: number) => {
        const response = await api.post(
            '/departments/register', 
            {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    editDepartment: async (iddepartment: number, data: any) => {
        const response = await api.patch(
        `/departments/${iddepartment}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    deleteDepartment: async (iddepartment: number) => {
        const response = await api.delete(
        `/departments/${iddepartment}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    editDepartmentAll: async (iddepartment: number, data: any) => {
        const response = await api.patch(
        `/departments/editAll/${iddepartment}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    
    addDepartmentOwner: async (data: any, idcompany: number) => {
        const response = await api.post(
            '/departmentsOwner/register', 
            {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    
    getDepartmentsByOwner: async (id?: number) => {
      const response = await api.get(
        `/departmentsOwner/${id}`
      ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
      return response
    },

    editPercentage: async (id?: number, data?:any) => {
        const response = await api.patch(
          `/departmentsOwner/${id}`,
          {
              ...data
          }
        ).then(response => response.data)
        .catch((error) => {
          console.error(error)
          return error.response.data
        })
        return response
    },
    
    deleteDepartmentsByOwner: async (id?: number,data?:any) => {
      const response = await api.delete(
        `/departmentsOwner/${id}`,
        {
            ...data
        }
      ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
      return response
    },

    getBuilding: async ( idowner: any, idcompany: number) => {

      const response = await api.post(
          '/auth/meBuilding',
          {
            idowner,
            idcompany
          }
      ).then(response => response.data.data)
          .catch((error) => {
              console.error(error)
          })
      return response
    }
}
