import { FC } from "react";
import { Grid, Typography } from '@mui/material'
import { createTheme } from '@mui/material/styles';
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useReportStore } from "../../hooks/useReportStore";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { FilterComponent } from "./components/FilterComponent";
import { NotData } from "./components/NotData";
import moment from "moment";

const columns = [
    { type: 'text', field: 'name_owner', label: 'PROPIETARIO', align: 'center' },
    { type: 'text', field: 'name_label', label: 'TIPO DE RESERVA', align: 'center' },
    { type: 'text', field: 'arrival_date', label: 'FECHA INICIO', align: 'center',
    format:(row) => row.arrival_date && moment(row.arrival_date).format('YYYY-MM-DD') },
    { type: 'text', field: 'exit_date', label: 'FECHA FIN', align: 'center' ,
    format:(row) => row.exit_date && moment(row.exit_date).format('YYYY-MM-DD') },
    { type: 'text', field: 'number_days', label: 'N° DIAS', align: 'center' },
]

export const ReportAdminDepartment: FC = () => {

    const { status: statusApiDepartment } = useDepartmentStore()
    const { status: statusOwnerApi } = useOwnerStore()
    const {getReportReservation, dataReportReservation, filter, department, owner, year, month} = useReportStore();

    const onListReport = () => {
        switch (filter) {
            case '01':
                getReportReservation({
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "iddepartment": department.id,
                    "idcompany": 1
                })
                break;
            case '02':
                getReportReservation({
                    "year": year?.name,
                    "iddepartment": department.id,
                    "idcompany": 1
                })
                break;
            case '03':
                getReportReservation({
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idowner": owner.id,
                    "idcompany": 1
                })
                break;
            case '04':
                getReportReservation({
                    "year": year?.name,
                    "idowner": owner.id,
                    "idcompany": 1
                })
                break;
            case '05':
                getReportReservation({
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idowner": owner.id,
                    "iddepartment": department.id,
                    "idcompany": 1
                })
                break;
            case '06':
                getReportReservation({
                    "year": year?.name,
                    "idowner": owner.id,
                    "iddepartment": department.id,
                    "idcompany": 1
                })
                break;
            default:
                break;
        }
    }

    return (
        <>

            {statusApiDepartment === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} />}

            <Grid container>
                <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '10px' }}>
                    <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml: 1, fontWeight: 600 }}>
                        {('Reporte de Reservas').toUpperCase()}
                    </Typography>
                </Grid>
                <FilterComponent  submit={onListReport}/>
                {
                    dataReportReservation.data_department > 0 ?
                    (dataReportReservation.data_department ).map((data)=>{
                        return (
                            <>
                                <Grid item container sx={{ mt:3 }}>
                                    <Grid item xs={12}>
                                        <Typography> Departamento:</Typography> <Typography>{data?.name_department}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography> Total de Dias:</Typography> <Typography>{data?.total_days}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography> Total reservas:</Typography> <Typography>{data?.total_reservation}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <CustomTable
                                        title={''}
                                        columns={columns}
                                        rows={data.data_reservation || []}
                                        onRowClick={() => { }}
                                    />
                                </Grid>
                            </>
                        )
                    }):
                    <NotData/>
                }
                
               
            </Grid>
        </>
    )
}