import { Grid, Typography, Card, Button, Skeleton, CircularProgress, styled, Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { UploadTranfersModal } from './components/UploadTranfersModal';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, RoutesMap } from '../../types';
import { moneyFormat } from '../../toolbox/helpers/money-helpers';
import { RefusedDebtModal } from './components/RefusedDebtModal';
import { useDepartmentStore } from '../../hooks/useDepartmentStore';
import { useRenditionStore } from '../../hooks/useRenditionStore';
import { ApiStatus } from '../../types/api/status';
import { useAuthStore } from '../../hooks';
import { useRenditionBuildingStore } from '../../hooks/useRenditionBuildingStore';
import { MODE_BUILDING_OWNER } from '../../toolbox/defaults/app';
import { UploadTranfersBuildingModal } from '../RenditionBuilding/components/UploadTransferBuildingModal';
import { Building } from '../../types/slices/buildingType';
import { useBuildingStore } from '../../hooks/useBuildingStore';
import { Department } from '../../types/slices/departmentType';
import SearchIcon from '@mui/icons-material/Search'
import { readLocalStorage } from '../../toolbox/helpers/local-storage-helpers';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const RenditionView = () => {

    const navigate = useNavigate();
    const { status: renditionStatus, renditionOwner ,getRenditionOwner, getRenditionOwnerPDF } = useRenditionStore()
    const { status: renditionBuildingStatus, renditionBuildingOwner, getRenditionBuildingOwner, getRenditionDetailOwnerPDF, resetList } = useRenditionBuildingStore()
    
    // const { departments, selectedDepartment, selectedBuildingOwner, getDepartments, setSelectedDepartment } = useDepartmentStore()
    // const { buildings, selectedBuilding, getBuildings, setSelectedBuilding } = useBuildingStore();
    const { user } = useAuthStore()
    const idowner:any = user?.data?.id
    
    const [showModalTransfer, setShowModalTransfer] = useState<boolean>(false);
    const [showModalRefusedDebt, setShowModalRefusedDebt] = useState<boolean>(false);
    const [ downloading, setDownloading ] = useState<boolean>(false);
    const [ renditionList, setRenditionList ] = useState<any>(null);
    
    const [ loading, setLoading ] = useState<boolean>(false);
    const [initialRender, setInitialRender] = useState<boolean>(true);

    const [arrayBuildings, setArrayBuildings] = useState([]);
    const [arrayDepartments, setArrayDepartments] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState<any>('')
    const [selectedDepartment, setSelectedDepartment] = useState<any>('')

    useEffect(()=>{
      getBuildingsAPI();
    }, [])
  
    useEffect(()=>{
        if(selectedBuilding && initialRender){
          const department = getDepartmentsAPI(selectedBuilding, true)
          if (department.length > 0) {
            setSelectedDepartment(department[0])
          } 
        }
    },[selectedBuilding])
    
    useEffect(()=>{
        if(selectedDepartment && initialRender){
          validateRendition() 
        }
    },[selectedDepartment])
  
    const getBuildingsAPI = () => {
      setSelectedBuilding('')
      setSelectedDepartment('')
      const data = readLocalStorage(LocalStorageKey.BUILDING_OWNER);
      const building:any = [];
      data.map((item: any) => {
        building.push({
          id: item.idbuilding,
          name: item.name_building
        })
      });
      setArrayBuildings(building);
      if (building.length > 0) setSelectedBuilding(building[0])
    }

    const getDepartmentsAPI = (getBuilding: any, returnValue: boolean = false) => {
      const data = readLocalStorage(LocalStorageKey.BUILDING_OWNER);
      const building = data.find((item) => item.idbuilding === getBuilding.id);
      setArrayDepartments(building.departments);
      if (returnValue) return building.departments;
    }
  
    useEffect(()=>{
      if (MODE_BUILDING_OWNER === "building") {
        setRenditionList(renditionBuildingOwner)
      } else {
        setRenditionList(renditionOwner)
      }
    },[renditionOwner,renditionBuildingOwner])

    const validateRendition = async() => {
      resetList();
      setLoading(true);
      if (MODE_BUILDING_OWNER === "building") {
        const res1 = await getRenditionBuildingOwner({iddepartment: selectedDepartment?.id, idbuilding: selectedBuilding?.id})
      } else {
        const res2 = await getRenditionOwner(selectedDepartment?.id, idowner)
      }
      setLoading(false);
    }

    const downloadPDF = async () => {
      setDownloading(true)
      let response;
      if (MODE_BUILDING_OWNER === "building") {
        response = await getRenditionDetailOwnerPDF(renditionList?.id, selectedDepartment?.id)
      } else {
        response = await getRenditionOwnerPDF(renditionList?.id, idowner)
      }
        if (response !== false) {
            const blob = new Blob([response], { type: 'application/pdf' })
            const bloblURL = URL.createObjectURL(blob)
            window.open(bloblURL, '_blank')
        }
        setDownloading(false)
    }

    return (
      <>
        <Grid item container sx={{ padding: '10px 0px' }}>
          <Grid item container xs={12} direction="row" sx={{ marginBottom:"20px", display:"flex", alignItems:"center" }}>
            <Autocomplete
              id="combo-box-building"
              size="small"
              value={selectedBuilding}
              options={arrayBuildings || []}
              getOptionLabel={(option) => option.name || ""}
              isOptionEqualToValue={(option, value) => option?.name === value?.name}
              sx={{width:'300px', padding: '8px'}}
              renderInput={(params) => <TextField {...params} label="Edificios" size="small" />}
              onChange={(e, newValue) => {
                setSelectedBuilding(newValue || {} as Building)
                newValue?.id && getDepartmentsAPI(newValue);
                resetList();
              }}
            />
            <Autocomplete
              id="combo-box-department"
              size="small"
              value={selectedDepartment}
              options={arrayDepartments || []}
              disabled={!selectedBuilding?.id}
              getOptionLabel={(option) => option.name || ""}
              isOptionEqualToValue={(option, value) => option?.name === value?.name}
              sx={{width:'300px', padding: '8px'}}
              renderInput={(params) => <TextField {...params} label="Departamento" size="small" />}
              onChange={(e, newValue) => {
                setSelectedDepartment(newValue || {} as Department)
                resetList();
              }}
            />
            <Button
              onClick={ () => validateRendition() }
              startIcon={<SearchIcon/>} 
              sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none',height: '40px' , fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}
            >
              Buscar
              {
                loading && (
                  <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                )
              }
            </Button>
          </Grid>
        </Grid>
        {
            !loading
            ?
            <Grid item container sx={{ padding: '10px 0px' }}>
              {
                renditionList?.period && renditionList.message !== 'No hay deuda pendiente'
                ?
                <>
                    <Grid xs={12} display='flex' justifyContent='center' alignItems='center' pb={3}>
                      <Typography color='#808080' sx={{ fontSize:18, fontWeight:600}}>
                      {'Rendición de: ' + selectedDepartment.name}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={12} sx={{ margin: '0 auto', paddingLeft:'20px', paddingRight:'20px' }}>
                      <Card sx={{ display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #808080', padding:'10px', width:'100%' }}>
                        <Grid container display='flex' justifyContent='center' alignItems='center' sx={{margin: '10px'}}>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <Grid item mb={2} display='flex' justifyContent='start' alignItems='center'>
                                <Typography color='#212D39' sx={{ fontSize:18, fontWeight:600, textAlign: 'start'}}>
                                    {'Rendición de ' + renditionList?.period?.period}
                                </Typography>
                              </Grid>
                              {
                                renditionList?.data?.map(value => {
                                  return (
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='10px'>
                                      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                        <Typography variant='body1' color='#000000' sx={{ fontWeight:500, pr:2, width:250 }}>
                                          { value.category_name + ` (${value.spent_concept})` + ':' }
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{paddingRight:'5px'}}>
                                        <Typography variant='body1'>{moneyFormat(value.total_amount, '$')}</Typography>
                                      </Grid>
                                    </Grid>
                                  )
                                })
                              }
                              <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='10px'>
                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                  <Typography variant='h6' color='#808080' sx={{ fontWeight:600, pr:2, width:210 }}>Total de gastos:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                  <Typography variant='body1' color='#101010'>{moneyFormat(renditionList?.total, '$')}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Grid sx={{border: 'solid 1px #212D39', borderRadius:'40px', padding:'15px 5px 15px 5px'}}>
                                    <Grid display='flex' justifyContent='center' alignItems='center' mb={2}>
                                        <Typography color='#808080' sx={{ fontSize:18, fontWeight:600, textAlign: 'center'}}>
                                            {'TOTAL A PAGAR POR PROPIETARIO'}
                                        </Typography>
                                    </Grid>
                                    <Grid display='flex' justifyContent='center' alignItems='center'>
                                      {
                                        (renditionList?.deudabyrendition && renditionList?.deudabyrendition > 0) ? (
                                          <Typography variant='h2' color='#212D39' sx={{ fontWeight:700}}>
                                            {moneyFormat(renditionList?.deudabyrendition, '$')}
                                          </Typography>
                                        ) : (
                                          <Typography variant='h2' color='#212D39' sx={{ fontWeight:700}}>
                                            {moneyFormat(renditionList?.total, '$')}
                                          </Typography>
                                        )
                                      }
                                        
                                    </Grid>
                                    {
                                      (
                                        renditionList.message === 'Aun no hay pago registrado' || 
                                        renditionList.message === 'Su pago aun no ha sido aprobado' || 
                                        renditionList.message === 'No hay deuda pendiente') && (
                                          <Grid display='flex' justifyContent='center' alignItems='center'>
                                              <Typography color='#000000' sx={{ fontSize:15, fontWeight:500, textAlign: 'center'}}>
                                                  { renditionList.pending_payment ? 'Su pago aun no ha sido aprobado' : renditionList.message }
                                              </Typography>
                                          </Grid>
                                      )
                                    }
                                    {
                                        (renditionList.message === 'Su pago ha sido rechazado' || renditionList.message === 'Deuda pendiente') && (
                                            <Grid display='flex' justifyContent='center' alignItems='center'>
                                                <Typography color='#940e0e' sx={{ fontSize:15, fontWeight:700}}>
                                                    {renditionList.message || ''}
                                                </Typography>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Grid container spacing={2} padding='15px 20px 0px 20px' display='flex' justifyContent='center'>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Button onClick={()=>{ downloadPDF() }} size="small" variant="contained" sx={{background:'#73B2FF',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}} fullWidth>
                                            {
                                                downloading 
                                                ?
                                                <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                                :
                                                'Descargar recibo' 
                                            }
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Button onClick={()=>{setShowModalTransfer(true)}} size="small" variant="contained" sx={{background:'#73B2FF',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}} fullWidth
                                            disabled={renditionList.pending_payment || false}
                                        >
                                            {'Subir transferencia'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                </>
                :
                <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', marginBottom:'10px', width: '100%'}}>
                    <Div>{"NO HAY RENDICIÓN VIGENTE"}</Div>
                </Grid>
              }
                <Grid xs={12} display='flex' justifyContent='end' alignItems='end' pt={3}>
                    <Button disabled={!renditionList?.period} onClick={() => navigate({ pathname: RoutesMap.RENDITION_HISTORY})} size="small" variant="contained" sx={{background:'#fff',color:'#212D39', '&:hover':{bgcolor:'#808080', color:'#fff'}, border:'solid 1px #808080'}}>
                        {'Historial de rendiciones'}
                    </Button>
                </Grid>
            </Grid>
            :
            <>
                <Grid item container sx={{padding:'10px 0px'}}>
                    <Grid item xs={8} sm={5} md={3} sx={{ margin: '0 auto' }} >
                        <Skeleton width="100%" sx={{ fontSize: '1rem' }} variant="text"/>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={8} sm={8} md={8} sx={{ margin: '0 auto' }}>
                        <Skeleton variant="rectangular" width="100%" height={250} />
                    </Grid>
                </Grid>
                <Grid item container display='flex' justifyContent='end' alignItems='end' pt={3}>
                    <Grid item xs={8} sm={4} md={2} sx={{ marginRight: '40px'}}>
                        <Skeleton variant="rectangular" width="100%" height={20} />
                    </Grid>
                </Grid>
            </>
        }
        {
            showModalTransfer && (
              MODE_BUILDING_OWNER === "building" ? (
                <UploadTranfersBuildingModal
                  open={showModalTransfer}
                  closeModal={()=>{setShowModalTransfer(false)}}
                  isAdmin={false}
                  department={selectedDepartment}
                  building={selectedBuilding}
                />
              ) : (
                <UploadTranfersModal
                    open={showModalTransfer}
                    closeModal={()=>{setShowModalTransfer(false)}}
                    isAdmin={false}
                />
              )
            )
        }
        {
            showModalRefusedDebt && (
                <RefusedDebtModal
                    open={showModalRefusedDebt}
                    closeModal={()=>{setShowModalRefusedDebt(false)}}
                />
            )
        }
        </>
    )
}