import { DialogActions,  Button} from '@mui/material';
import '../../../assets/styles/pewen-app.css'


export const CustomFormFooter = (props:any) => {

  const { 
    confirmText, onConfirm, cancelText, onCancel, cancelColor, confirmColor, className, backgroundColor, buttonType,
    secondaryText, onSecondaryConfirm, clearState
  } = props;

    return (
      <DialogActions className={className || "modal-footer"} style={{position: 'sticky'}}>
        {
          cancelText && (
            <Button onClick={()=>{onCancel && onCancel(); clearState && clearState()}} size="small" variant="contained" className='btn-cancel'>
              {/* {cancelText} */}
              {cancelText.charAt(0).toUpperCase() + cancelText.slice(1).toLowerCase()}
            </Button>
          )
        }
        {
          confirmText && (
            <Button onClick={onConfirm} type={buttonType} size="small" autoFocus variant="contained" className='btn-confirm' >
              {/* {confirmText} */}
              {confirmText.charAt(0).toUpperCase() + confirmText.slice(1).toLowerCase()}
            </Button>
          )
        }
        {
          secondaryText && (
            <Button onClick={onSecondaryConfirm} type={buttonType} size="small" variant="contained" className='btn-secondary'>
              {/* {secondaryText} */}
              {secondaryText.charAt(0).toUpperCase() + secondaryText.slice(1).toLowerCase()}

            </Button>
          )
        }
      </DialogActions>
    )
}