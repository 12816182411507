import { Grid } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType";
import { useEffect, useState } from 'react';
import { useOwnerStore } from '../../hooks/useOwnerStore';
import { useNavigate } from 'react-router-dom';
import { Owner } from '../../types/slices/ownerType';
import { EditPercentageModal } from './EditPercentageModal';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { ApiStatus } from '../../types/api/status';
import { useAgentStore } from '../../hooks/useAgentStore';
import { AgentByDepartmentModal } from './AgentByDepartmentModal';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useContractStore } from '../../hooks/useContractStore';

const columns = [
    { type: 'options',  field: 'options', align:'center', label: 'Opciones' },
    { type: 'text', field: 'estateagent', label: 'Rut', align:'center', format:(row) => row.estateagent ? row.estateagent.rut : '-----'},
    { type: 'text', field: 'name', label: 'Nombre' , format:(row) => row.estateagent ? row.estateagent.name : '-----'},
    { type: 'text', field: 'email', label: 'Correo', format:(row) => row.estateagent ? row.estateagent.email : '-----', align:'center'},
    { type: 'text', field: 'email', label: 'Correo', format:(row) => row.estateagent ? row.estateagent.phone : '-----', align:'center'},
    { type: 'detail', field: 'detail', align:'center',     label: '' }
];

export const AgentByDepartment = () => {

  const navigate = useNavigate();
  const { status: statusApiDepartment, setSelectedDepartment, selectedDepartment, departmentByOwner, getDepartmentsByOwner, deleteDepartmentsByOwner} = useDepartmentStore();

  const { status: statusAgentApi, agentsByDepartment, getAgentsByDepartment, deleteAgentDepartment } = useAgentStore();

  const { setSelectedOwnerAdd } = useOwnerStore();
  const { status: statusContract, deleteContract} = useContractStore();

  const [rowSelected, setRowSelected] = useState<any>('');
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [showModalContrat, setShowModalContrat] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDepartment?.id) {
        getAgentsByDepartmentApi();
    } else {
      navigate({ pathname: RoutesMap.DEPARTAMENT }) 
    }
  }, []);

  const getAgentsByDepartmentApi = async() => {
    try {
      setLoadingTable(true);
      const response = await getAgentsByDepartment(selectedDepartment?.id);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  }

  const clearState = () => {
    setSelectedDepartment({} as Department);
  }

  const onDelete = async(rowSelected) => {
    setRowSelected(rowSelected)
    setShowModalConfirm(true);
  }

  const onDeleteConfirm = async() => {
    if (rowSelected.id) {
      const response = await deleteAgentDepartment(rowSelected.id)
      if(response){
        setShowModalConfirm(false)
        getAgentsByDepartmentApi()
      }else{
        return false;
      }
    }
  }
  
  const onCreate = () => {
    const departement:any = selectedDepartment?.id ? selectedDepartment?.owners : [];
    if(departement.length == 0) return CustomSnackbar('warning', 'El Departamento aún no ha sido relacionado con ningún edificio.')
    setShowModalCreate(true);
  }

  const onCloseModal = (onSubmit?: boolean) => {
    if (onSubmit) {
        getAgentsByDepartmentApi();
    }
    setShowModalCreate(false);
    setSelectedOwnerAdd({} as Owner);
  }

  const onCloseModalEdit = (onSubmit?: boolean) => {
    if (onSubmit) {
        getAgentsByDepartmentApi();
    }
    setShowModalEdit(false);
    setSelectedOwnerAdd({} as Owner);
  }

  const showContract = (rowSelected) => {
    console.log('ver')
  }

  const onDeleteContract = (rowSelected) => {
    setRowSelected(rowSelected)
    setShowModalContrat(true);
  }

  const deleteContractConfirm = async() => {
    if (!rowSelected) return CustomSnackbar("warning", "Seleccione un corredor.")
    const data = {
      idowner: rowSelected.idowner,
      iddepartment: rowSelected.iddepartment,
      idestateagent: rowSelected.idestateagent,
    }
    const response = await deleteContract(data)
    if(response){
      setShowModalContrat(false)
      getAgentsByDepartmentApi()
    }else{
      return false;
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <CustomFormHeader
          title={selectedDepartment.id ? `Agente Inmobiliario de ${selectedDepartment.name}` : '....'}
          goBack={RoutesMap.DEPARTAMENT}
          clearState={clearState}
        >
        </CustomFormHeader>

        <CustomTable 
          disabled_title
          columns={columns} 
          loading={loadingTable}
          rows={agentsByDepartment ||  []}
          onAddFn={onCreate}
          onDelete={onDelete}
          onRowClick={() => {}}
          hasOptions
          onBtnMenu
          iconBtnMenu={<PlagiarismIcon fontSize="small" />}
          tooltip_menu={'contrato'}
          options={[{id:1, name: 'ver contrato', action: showContract}, {id:2, name: 'eliminar contrato', action: onDeleteContract}]}
        />
      </Grid>
      {
        showModalCreate && (
          <AgentByDepartmentModal
            open={showModalCreate}
            closeModal={onCloseModal}
            row={selectedDepartment}
            owners={agentsByDepartment}
          />
        )
      }
      {
        showModalConfirm && (
            <ModalConfirm
                open={showModalConfirm}
                closeModal={()=>{
                  setShowModalConfirm(false)
                }}
                onConfirm={onDeleteConfirm}
                status2={statusAgentApi == ApiStatus.FETCHING ? true : false}
            />
        )
      }
      {
        showModalContrat && (
            <ModalConfirm
                title={'¿Esta seguro de eliminar el contrato?'}
                text={'Recuerda que al eliminar el contrato, estas eliminando toda relación que existe entre el departamento y el corredor seleccionado.'}
                open={showModalContrat}
                closeModal={()=>{
                  setShowModalContrat(false)
                }}
                onConfirm={deleteContractConfirm}
                status2={statusContract == ApiStatus.FETCHING ? true : false}
            />
        )
      }
    </>
  )
}