import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../types/api/status"
import { RootState } from "../store"

const initialState: any =
{
  status: ApiStatus.FETCHED,
  visits: [],
  errorMessage: undefined,
  validatedVisit: {} as any,
  selectedVisit: {} as any,
  action: '' as any,
}

const visitSlice = createSlice({
    name: 'visitSlice',
    initialState,
    reducers: {
        onFetchVisits (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.visits       = payload
            state.errorMessage = undefined
        },
        onFetchvalidatedVisit (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.validatedVisit = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedVisit (state, { payload }: { payload: any }) {
            state.selectedVisit = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectVisitState = (state: RootState) => state.visitSlice
export default visitSlice.reducer

export const {
    onFetchVisits,
    onFetchvalidatedVisit,
    changeStatus,
    onSetSelectedVisit,
    setAction,
} = visitSlice.actions