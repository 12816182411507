import { Autocomplete, Box, Fade, Grid, Modal, Paper, TextField } from '@mui/material';
import { useEffect } from 'react';

export const ModalContractVariables = (props) => {
    const {open, closeModal, dataVariables, variableSelected, setVariableSelected, evaluateVar} = props;

    console.log(dataVariables)
   
    let inputRef;

    useEffect(()=>{
        if(open) inputRef?.focus();
        setVariableSelected(null)
    }, [open])

    return (
        <>
        <Box height="100%" display="flex">
            <Modal
                open={open}
                onClose={closeModal}
            >
                <Fade in={open}>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} sm={10} md={6} lg={4} mt={2} p={1}>
                            <Paper elevation={5} sx={{ padding: '20px', borderRadius: '16px' }}>
                            {
                                dataVariables.length > 0 && (
                                    <Grid style={{height: '400px'}}>
                                        <Autocomplete
                                            id="variables"
                                            disablePortal
                                            value={variableSelected}
                                            options={dataVariables || []}
                                            getOptionLabel={(option) => option.value || ""}
                                            fullWidth
                                            openOnFocus
                                            renderInput={
                                                (params) => 
                                                    <TextField 
                                                    {...params}
                                                    inputRef={input => {
                                                        inputRef = input;
                                                    }}
                                                    autoFocus 
                                                    variant="standard"
                                                    label="Escribe para buscar" />
                                            }
                                            onChange={(e, newValue) =>{
                                                setVariableSelected(newValue || null)
                                                evaluateVar(e, newValue);
                                            }}
                                        />
                                    </Grid>
                                )
                            }
                            </Paper>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        </Box>
        </>
    );
}