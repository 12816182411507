
import { useEffect } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Divider, Typography, Accordion, AccordionSummary, AccordionDetails, ListItemButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";

export const TenantModal: React.FC<any> = (props): JSX.Element | any => {

  const { open, closeModal, rowSelected, clearState } = props;

  useEffect(() => {
      // getDepartments({ idowner: rowSelected?.idowner })
  },[])

  return (
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
        <ModalHeader
            text={'Detalle de propietario'}
            className='positionElements'
            onCancel={closeModal}
            clearState={clearState}
        >
        </ModalHeader>
        <ModalBody>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Información general</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ListItemButton>
              <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
              <Typography sx={{ color: 'text.secondary' }}>{rowSelected.name}</Typography>
              </ListItemButton>
              <Divider />
              <ListItemButton>
              <Typography sx={{ width: '40%', flexShrink: 0 }}>Rut</Typography>
              <Typography sx={{ color: 'text.secondary' }}>{rowSelected.rut}</Typography>
              </ListItemButton>
              <Divider />
              <ListItemButton>
              <Typography sx={{ width: '40%', flexShrink: 0 }}>Email</Typography>
              <Typography sx={{ color: 'text.secondary' }}>{rowSelected.email}</Typography>
              </ListItemButton>
              <Divider />
              <ListItemButton>
              <Typography sx={{ width: '40%', flexShrink: 0 }}>Celular</Typography>
              <Typography sx={{ color: 'text.secondary' }}>{rowSelected.phone}</Typography>
              </ListItemButton>
              <Divider />
            </AccordionDetails>
          </Accordion>
          
              {/* <Accordion>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                  >
                      <Typography>Departamentos</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      {
                          departments && (departments).length > 0
                          ?
                          (departments).map((item:any)=>{

                              const currentowner = item.owners && (item.owners).find(owner => (owner.idowner == rowSelected.idowner))

                              return (
                                  <>
                                  <ListItemButton>
                                      <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                      <Typography sx={{ color: 'text.secondary' }}>{item.name}</Typography>
                                  </ListItemButton>
                                  <ListItemButton>
                                      <Typography sx={{ width: '40%', flexShrink: 0 }}>Porcent. Departamento</Typography>
                                      <Typography sx={{ color: 'text.secondary' }}>
                                          {currentowner && currentowner.participation_percentage ? currentowner.participation_percentage  + '%' : '-----'}
                                      </Typography>
                                  </ListItemButton>
                                  <Divider />
                                  </>
                              )
                          })
                          :
                          <Typography sx={{ color: 'text.secondary' }}>No cuenta con departamentos afiliados</Typography>
                      }
                      

                  </AccordionDetails>
              </Accordion> */}
          </ModalBody>
          <Divider />
          <ModalFooter 
              buttonType="submit"
              cancelText={"Cancelar"}
              onCancel={closeModal}
          />
      </Modal>
    </>
  )
}