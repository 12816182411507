import { Grid } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { ApiStatus } from '../../types/api/status';
import { VisitsByDepartmentModal } from './VisitsByDepartmentModal';
import { useVisitStore } from '../../hooks/useVisitStore';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { ModalQr } from '../../components/common/ModalQR/ModalQr';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { useAuthStore } from '../../hooks';
import { Role } from '../../types/roles/roleTypes';

const columns = [
    { type: 'options',  field: 'options', align:'center', label: 'Opciones' },
    { type: 'text', field: 'rut', label: 'Rut', align:'center', format:(row) => row.rut ? row.rut : '-----'},
    { type: 'text', field: 'name', label: 'Nombre' , format:(row) => row.name ? row.name : '-----'},
    { type: 'text', field: 'email', label: 'Correo', format:(row) => row.email ? row.email : '-----', align:'center'},
    { type: 'text', field: 'start_date', label: 'Fecha llegada', format:(row) => row.start_date ? row.start_date : '-----', align:'center'},
    { type: 'text', field: 'confirm_status', label: 'Estado', 
      format:(row) => row.confirm_status == '0' ? 'pendiente' : (row.confirm_status == '1' ? 'asistida' : 'caducada'), align:'center'
    },
    { type: 'detail', field: 'detail', align:'center',     label: '' }
];

export const VisitsByDepartment = () => {

  const navigate = useNavigate();
  const { setSelectedDepartment, selectedDepartment } = useDepartmentStore();
  const { user } = useAuthStore()
  const role = user?.userType;

  const { status: statusVisitApi, visits, getVisitsByDepartment, deleteVisit, setActionVisit, confirmVisit } = useVisitStore();

  const [rowSelected, setRowSelected] = useState<any>('');
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [showModalQr, setShowModalQr] = useState<boolean>(false);
  const [showConfirmVisit, setShowConfirmVisit] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDepartment?.id) {
      getVisitByDepartmentApi();
    } else {
      navigate({ pathname: RoutesMap.DEPARTAMENT }) 
    }
  }, []);

  const getVisitByDepartmentApi = async() => {
    try {
      setLoadingTable(true);
      const response = await getVisitsByDepartment(selectedDepartment?.id);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  }

  const clearState = () => {
    setSelectedDepartment({} as Department);
  }

  const onAdd = () => {
    setActionVisit('add')
    setShowModalCreate(true);
  }
  const onEdit = (rowSelected) => {
    if(rowSelected && rowSelected.confirm_status != '0') return CustomSnackbar("warning","No se puede editar la visita.")
    setActionVisit('edit')
    setRowSelected(rowSelected)
    setShowModalEdit(true)
  }
  const onDelete = (rowSelected) => {
    if(rowSelected && rowSelected.confirm_status == '1') return CustomSnackbar("warning","No se puede eliminar la visita.")
    setActionVisit('delete')
    setRowSelected(rowSelected)
    setShowModalConfirm(true);
  }
  const onDetails = (rowSelected) => {
    setActionVisit('detail')
    setRowSelected(rowSelected)
    setShowModalEdit(true);
  }

  const onDeleteConfirm = async() => {
    if (rowSelected.id) {
      const response = await deleteVisit(rowSelected.id)
      if(response){
        setShowModalConfirm(false)
        getVisitByDepartmentApi()
      }else{
        return false;
      }
    }
  }

  const onConfirmVisit = async() => {
    if (rowSelected.id) {
      const response = await confirmVisit(rowSelected.id)
      if(response){
        setShowConfirmVisit(false)
        getVisitByDepartmentApi()
      }else{
        return false;
      }
    }
  }

  const onHelper = (rowSelected) => {
    console.log(rowSelected)
    if(rowSelected && rowSelected.confirm_status != '0') return CustomSnackbar("warning","No se puede confirmar la visita.")
    setRowSelected(rowSelected)
    setShowConfirmVisit(true)
  }

  const onHelper2 = (rowSelected) => {
    setRowSelected(rowSelected)
    setShowModalQr(true)
  }

  return (
    <>
      <Grid item xs={12}>
        <CustomFormHeader
          title={selectedDepartment.id ? `Visitas de ${selectedDepartment.name}` : '....'}
          goBack={RoutesMap.DEPARTAMENT}
          clearState={clearState}
        >
        </CustomFormHeader>

        <CustomTable 
          disabled_title
          columns={columns} 
          loading={loadingTable}
          rows={visits ||  []}
          onRowClick={() => {}}
          hasOptions
          onAddFn={onAdd}
          onDetails={onDetails}
          onDelete={onDelete}
          onEdit={onEdit}

          onHelper={role == Role.ADMIN ? onHelper : ''}
          icon_helper={<CheckCircleIcon fontSize="small" />}
          tooltip_helper={'confirmar visita'}

          onHelper2={onHelper2}
          icon_helper2={<QrCode2Icon fontSize="small" />}
          tooltip_helper2={'ver qr'}
        />
      </Grid>
      {
        showModalCreate && (
          <VisitsByDepartmentModal
            open={showModalCreate}
            department={selectedDepartment}
            closeModal={()=>{
              setRowSelected('')
              setShowModalCreate(false)
            }}
          />
        )
      }
      {
        showModalEdit && (
          <VisitsByDepartmentModal
            open={showModalEdit}
            department={selectedDepartment}
            row={rowSelected}
            closeModal={()=>{
              setRowSelected('')
              setShowModalEdit(false)
            }}
          />
        )
      }
      {
        showModalConfirm && (
            <ModalConfirm
                open={showModalConfirm}
                closeModal={()=>{
                  setShowModalConfirm(false)
                }}
                onConfirm={onDeleteConfirm}
                status2={statusVisitApi == ApiStatus.FETCHING ? true : false}
            />
        )
      }
      {
        showConfirmVisit && (
            <ModalConfirm
                title={'Confirmar asistencia'}
                text={'Si estas seguro, haz click en el boton "aceptar" y visita sera marcada como asistida.'}
                open={showConfirmVisit}
                closeModal={()=>{
                  setShowConfirmVisit(false)
                }}
                onConfirm={onConfirmVisit}
                status2={statusVisitApi == ApiStatus.FETCHING ? true : false}
            />
        )
      }
      {
        showModalQr && (
            <ModalQr
                open={showModalQr}
                data={rowSelected}
                closeModal={()=>{
                  setShowModalQr(false)
                }}
            />
        )
      }
    </>
  )
}