import { useNavigate } from "react-router-dom";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { OwnerModal } from "../Owner/OwnerModal";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { RoutesMap } from "../../types";
import { useEffect, useState } from "react";
import { useTenantStore } from "../../hooks/useTenantStore";
import { ApiStatus } from "../../types/api/status";
import { useAgentStore } from "../../hooks/useAgentStore";
import { TenantModal } from "../Tenant/TenantModal";
import { Role } from "../../types/roles/roleTypes";
import { useAuthStore } from "../../hooks";
import { IdentificationModalAdmin } from "../Departament/Identification/IdentificationModalAdmin";
import HowToRegIcon from '@mui/icons-material/HowToReg';

const columns = [
    { type: 'options',  field: 'options', align:'center', label: 'Opciones',  },
    { type: 'text',     field: 'name', align:'center', label: 'Nombre' },
    { type: 'text',     field: 'rut', align:'center', label: 'Rut' },
    { type: 'text',     field: 'email', align:'center', label: 'Correo' },
    { type: 'text',     field: 'phone', align:'center', label: 'Celular' },
    { type: 'detail',   field: 'detail',  align:'center', label: '' },
];

export const AgentView = () => {
  const navigate = useNavigate();
  const { search } = useSelector(selectHeaderState);
  const { user } = useAuthStore();

  const { status: statusAgentApi, agents, getAgents,deleteAgent, setSelectAgent, setActionAgent } = useAgentStore();

  const [ rows, setRows ] = useState<any>([])
  const [rowSelected, setRowSelected] = useState<any>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
  const [loading, setLoading] = useState(false);
  const [showModalIdentity, setShowModalIdentity] = useState<boolean>(false);

  const role = user?.userType;

  useEffect(() => {
    setSelectAgent({} as any)
    getAgentsApi()
  },[search])

  useEffect(() => {
    if (agents) {
      setRows(agents)
      return
    }
    setRows([])
  }, [agents])

  const getAgentsApi = async() => {
    try {
      setLoading(true);
      const response = await getAgents({search});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onAdd = () => {
    setActionAgent('add')
    navigate({ pathname: RoutesMap.AGENT_FORM })
  }
  const onEdit = (rowSelected) => {
    setActionAgent('edit')
    setSelectAgent(rowSelected)
    navigate({ pathname: RoutesMap.AGENT_FORM })
  }
  const onDelete = (rowSelected) => {
    setActionAgent('delete')
    setRowSelected(rowSelected)
    setShowModalConfirm(true);
  }
  const onDetails = (rowSelected) => {
    setActionAgent('detail')
    setRowSelected(rowSelected)
    setShowModal(true);
  }
  const onDeleteConfirm = async() => {
    const response = await deleteAgent(rowSelected.id)
    if(response){
      setSelectAgent({} as any)
      setShowModalConfirm(false)
      getAgentsApi()
    }else{
      return false;
    }
  }

  const showIdentification = async(rowSelected) => {
    if(user && user.rut && user.rut == rowSelected?.rut) return false;
    if (rowSelected.is_admin === "1") return false;
    setRowSelected(rowSelected)
    setShowModalIdentity(true)
  }

  return (
    <>
      <Grid item xs={12}>
        <CustomTable
            title={'Corredores'}
            columns={columns} 
            loading={loading}
            rows={rows || []}
            onRowClick={() => {}}
            hasOptions
            onAddFn={onAdd}
            onDetails={onDetails}
            onDelete={onDelete}
            onEdit={onEdit}
            onHelper2={role == Role.ADMIN ? showIdentification : ''}
            icon_helper2={<HowToRegIcon fontSize="small" />}
            tooltip_helper2={'validar documentos de identidad'}
        />
    </Grid>
    {
        showModalConfirm && (
            <ModalConfirm
                open={showModalConfirm}
                closeModal={()=>{setShowModalConfirm(false)}}
                rowSelected={rowSelected}
                onConfirm={onDeleteConfirm}
                status2={statusAgentApi == ApiStatus.FETCHING ? true : false}
            />
        )
    }
    {
        showModal && (
            <TenantModal
                open={showModal}
                closeModal={()=>{setShowModal(false)}}
                rowSelected={rowSelected}
            />
        )
    }
    {
      showModalIdentity && (
        <IdentificationModalAdmin
          open={showModalIdentity}
          closeModal={()=>{setShowModalIdentity(false)}}
          rut={rowSelected.rut}
        />
      )
    }
    </>
  )
}