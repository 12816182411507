import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { Grid, Divider, FormLabel, TextField, Autocomplete } from "@mui/material"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useBuildingStore } from "../../hooks/useBuildingStore";
import { Department } from "../../types/slices/departmentType";

type Values = {
  iddepartment: number|string,
}

export const DepartamentByBuildingModal: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, row } = props;

  const { buildingByDepartment, createdRealtionBuildingAndDepa } = useBuildingStore()
  const { departmentsFilter, getDepartmentsFilter, selectedDepartment, setSelectedDepartment, deparmentWithOutBuilding } = useDepartmentStore()

  const [selectDepartments, setSelectDepartments] = useState<any>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Values>({
    iddepartment: "",
  });

  useEffect(() => {
    setSelectedDepartment({} as Department);
    getDepartmentsFilterApi();
  }, [])

  const getDepartmentsFilterApi = async () => {
    try {      
      setLoading(true);
      // const response = await getDepartmentsFilter(buildingByDepartment);
      const response = await deparmentWithOutBuilding();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const validateForm = (values) => {
    let errors: any = {};
    // if(!values.iddepartment) errors.iddepartment = "Campo requerido";
    
    return errors;
  }
  
  const onSubmit = async (formData) => {

    if(selectDepartments.length == 0) return CustomSnackbar('warning', 'Seleccione al menos un departamento.')
    if((row.departments.length + selectDepartments.length ) > row.number_departments) return CustomSnackbar('warning', `La cantidad total de departamentos no puede ser mayor a ${row.number_departments}.`)

    setLoadingSubmit(true);

    const iddepartments = selectDepartments.map(department => department.id);

    const data = {
        ...formData,
        iddepartments: iddepartments ||  [],
    }
    const response:any = await createdRealtionBuildingAndDepa(data, row.id);
    setLoadingSubmit(false);
    
    if (response)  closeModal(true);
  }

  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={"Añadir un departamento"}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({errors, touched, handleSubmit, setFieldValue}) => {
            return (
              <>
                <Grid item xs={12} md={12}>
                  <FormLabel>Departamento</FormLabel>
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    multiple
                    value={selectDepartments|| []}
                    options={departmentsFilter || []}
                    loading={loading}
                    getOptionLabel={(option) => option?.name || ""}
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        size="small"
                        error={errors.iddepartment && touched.iddepartment ? true : false}
                        helperText={errors.iddepartment && touched.iddepartment ? errors.iddepartment : ''}
                      />
                    }
                    onChange={(e, newValue) => {
                      setSelectDepartments(newValue || []) 
                    }}
                    filterSelectedOptions
                />
                </Grid>
                <Divider />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={'Guardar'}
                    onConfirm={() => handleSubmit()}
                    loadingConfirmText={loadingSubmit}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                  />
                </Grid>
              </>
            )
          }} 
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}