import { useEffect, useState } from "react";
import { Grid, Typography } from '@mui/material'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { useVisitStore } from "../../hooks/useVisitStore";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import moment from "moment";

export const ConfirmVisitQrView = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const rut = urlParams.get('rut');

  
  const { status: statusVisitApi, validateVisit, validatedVisit } = useVisitStore();

  const [rowSelected, setRowSelected] = useState<any>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVisitValidateApi()
  },[])

  const getVisitValidateApi = async() => {
    try {
        if(rut){
            setLoading(true);
            const response = await validateVisit({rut:rut});
            setLoading(false);
        }
    } catch (error) {
        setLoading(false);
    }
  }

  return (
    <>
        <Grid xs={12} container justifyContent='center' textAlign='center' sx={{height:'500px', paddingTop:'30px'}}>
            {
                rut ? (
                    <Grid xs={12} justifyContent='center' >
                        {
                            validatedVisit?.id && !loading && (
                                <>
                                <Typography sx={{ 
                                    color: validatedVisit?.confirm_status == '0' ? '#b96307de' : (validatedVisit?.confirm_status == '1' ? '#156b14de' : '#840808de'), 
                                    fontSize: 20, 
                                    fontWeight: 600
                                    }}
                                >
                                    {validatedVisit?.confirm_status == '0' ?  'PASE DE VISITA' : (validatedVisit?.confirm_status == '1' ? 'VISITA VALIDADA' : '')}
                                </Typography>

                                <Grid>
                                    {
                                        validatedVisit?.confirm_status == '0' && (
                                            <InsertInvitationIcon sx={{fontSize: '100px', color:'#d99d5cde'}} />
                                        )
                                    }
                                    {
                                        validatedVisit?.confirm_status == '1' && (
                                            <CheckCircleIcon sx={{fontSize: '100px', color:'#40b81cde'}} />
                                        )
                                    }
                                </Grid>

                                <Grid mt={1}>
                                    <Typography sx={{ color:'#716f6fde', fontSize: 16 }}>
                                        Llegada del visitante: <span><b>{moment(validatedVisit.start_date).format('LL')}</b></span>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} container alignItems="center" justifyContent="center" mt={2}>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#002545', fontSize: 16 }}><span><b>Datos del visitantes</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}><span><b>Rut:</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>{validatedVisit.rut}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}><span><b>Nombre:</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>{validatedVisit.name}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container alignItems="center" justifyContent="center" mt={2}>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#002545', fontSize: 16 }}><span><b>Datos del departamento</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}><span><b>Departamento:</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>{validatedVisit.department.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}><span><b>Dirección:</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>{validatedVisit.department.address}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container alignItems="center" justifyContent="center" mt={2}>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#002545', fontSize: 16 }}><span><b>Datos del propietario del departamento</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}><span><b>Rut:</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>{validatedVisit.owner.rut}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}><span><b>Nombre:</b></span></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>{validatedVisit.owner.name}</Typography>
                                    </Grid>
                                </Grid>
                                </>
                            )   
                        }
                        {
                            !(validatedVisit?.id) && !loading && (
                                <>
                                <Typography sx={{ color:'#840808de', fontSize: 20, fontWeight: 600}}>{'VISITA NO VÁLIDA'}</Typography>
                                <Grid>
                                    <CancelIcon sx={{fontSize: '100px', color:'#d00909de'}} />
                                </Grid>
                                <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>
                                    {validatedVisit.error || ''}
                                </Typography>
                                </>
                            )   
                        }
                        {
                            validatedVisit?.id && validatedVisit?.confirm_status == '2' && !loading && (
                                <>
                                <Typography sx={{ color:'#840808de', fontSize: 20, fontWeight: 600}}>{'VISITA CADUCADA'}</Typography>
                                <Grid>
                                    <CancelIcon sx={{fontSize: '100px', color:'#d00909de'}} />
                                </Grid>
                                <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>
                                    {'La fecha de ingreso ha caducado'}
                                </Typography>
                                </>
                            )   
                        }
                    </Grid>
                ) :(
                    <Grid xs={12} justifyContent='center' >
                        <Grid>
                            <NotificationImportantIcon sx={{fontSize: '100px', color:'#cbcbcbde'}} />
                        </Grid>
                        <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>{'Cierre sesión en su navegador y vuelva a escanear el QR.'}</Typography>
                    </Grid>
                )
            }
            
        </Grid>
    </>
  )
}