import { Button, Card, CardContent, Divider, Grid, TextField, CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from 'react';
import { useContractStore } from '../../hooks/useContractStore';
import { ModalContractVariables } from "./ModalContractVariables";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import '../../assets/styles/pewen-app.css';
import '../../assets/styles/contract.css';

export const CreateContract = () => {

    const navigate = useNavigate();

    const { status: statusContract, listVariablesContract, createContract } = useContractStore();

    const [documentClausules, setDocumentClausules] = useState<any>([{
        title: '',
        clausule:'',
        type: 'description',
        position: 1 ,
    }])
    const [variables,setVaribales] = useState<any>([])

    const [cursorActive, setCursorActive] = useState(null)
    const [idActive, setIdActive] = useState<any>(null)
    const [openModal, setOpenModal] = useState(false);
    const [variableSelected, setVariableSelected] = useState(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSend, setLoadingSend] = useState<boolean>(false);


    const [nameTitleContract, setNameTitleContract] = useState<any>('')
    const [descriptionTitleContract, setDescriptionTitleContract] = useState<any>('')

    useEffect(() => {
        getVariablesContractApi();
    },[])
  
    const getVariablesContractApi = async() => {
        try {
            setLoading(true);
            const response = await listVariablesContract();
            if(response){
                setVaribales(response || [])
                setLoading(false);
            }else{
                navigate({ pathname: RoutesMap.TEMPLATE_VARIABLES })
                setLoading(false);
            }
            
        } catch (error) {
            setLoading(false);
        }
    }

    const TextToVariable = (text) => {
        let textChange = text;
        variables && variables.map((item) => {
            textChange = textChange.replace(item.value, item.key)
            
        })
        return textChange
    }

    const VariableToText = (textVariable) => {
        let newText = textVariable
        variables && variables.map((item) => {
            newText = newText.replace(item.key, item.value)
        })
        return newText
    }

    const EstructurarData = (documentData, toText) => {
        const newArrayEstructura:any = [];
        documentData.map((item, i) => {
            const objNew = {
                id: item.id,
                clausule: toText ? VariableToText(item.clausule) : TextToVariable(item.clausule),
                position: i,
                title: toText ? VariableToText(item.title) : TextToVariable(item.title),
                status: item.status
            }
            newArrayEstructura.push(objNew);
        })

        return newArrayEstructura
    }

    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        if(value[e.target.selectionEnd - 1] == "@" && value[e.target.selectionEnd - 2] == " ") {
            setCursorActive(e.target.selectionEnd);
            setIdActive(name)
            setOpenModal(true);
            return
        } else {
            let changedForm;
            let indice = 0;

            documentClausules.map((item, i) => {
                if (item.position == name) {
                    changedForm = {
                        ...item,
                        clausule: value
                    }
                    indice = i
                }
            })
            documentClausules.splice(indice, 1, changedForm)
            setDocumentClausules([...documentClausules]);
        }
    }

    const handleInputHead = (e) => {
        const value = e.target.value
        let changedForm;
        let indice = 0;
        documentClausules.map((item, i) => {
            if (item.position == e.target.name) {
                changedForm = {
                    ...item,
                    title: e.target.value
                }
                indice = i
            }
        })
        documentClausules.splice(indice, 1, changedForm)
        setDocumentClausules([...documentClausules]);
    }

    const evaluateVar = (e, obj) => {

        setOpenModal(false)
        if(!obj) return false

        let changedForm;
        let indice = 0;

        documentClausules.map((item, i) => {
            if (item.position == idActive) {
                const newText:any = item.clausule.split('');

                newText.splice(cursorActive, 0, ' ' + obj.value + ' ')
                changedForm = {
                    ...item,
                    clausule: newText.join('')
                }

                indice = i
            }
        })
        documentClausules.splice(indice, 1, changedForm)
        setDocumentClausules([...documentClausules]);
    }

    const NewClausula = async(type) =>{

        let newObj = {
            title: "",
            clausule:'',
            position:documentClausules.length +1 ,
            type: ''
        }

        switch (type) {
            case 'subtitle':
                newObj = {
                    ...newObj, 
                    title:'',
                    type: 'subtitle',
                }
                break;

            case 'paragraph':
                newObj = {
                    ...newObj, 
                    title: ``, 
                    clausule: ``,
                    type: 'paragraph',
                }
                break;

            default:
                break;
        }

        setDocumentClausules([...documentClausules, newObj ]);

        setTimeout(function(){
            const miElemento = window.document.getElementById(`clauses-${documentClausules.length}`)
            if(miElemento){
                miElemento.scrollIntoView({ behavior: 'smooth' })
            }
        }, 1000);
    }

    const deleteClause = async(clause) => {
        setLoading(true);
        let indice;
        documentClausules.map((item, i) => {
            if (item.position == clause.position) {
                indice = i
            }
        })
        documentClausules.splice(indice, 1)
        setDocumentClausules([...documentClausules]);
        setLoading(false)
    }

    const createTemplate = async() => {
        try {

            if(!nameTitleContract) return CustomSnackbar('error', 'Debe completar el campo del título.');

            let typedescription = documentClausules.find(doc => doc.type == 'description');
            if(typedescription.clausule == '') return CustomSnackbar('error', 'Debe completar el campo descripción de la plantilla.');

            let typesubtitle = documentClausules.find(doc => doc.type == 'subtitle' && doc.title == '');
            if(typesubtitle) return CustomSnackbar('error', 'asegurese de completar todos los subtítulos.');

            let typeparagraphsintitle = documentClausules.find(doc => doc.type == 'paragraph' && doc.title == '');
            if(typeparagraphsintitle) return CustomSnackbar('error', 'asegurese de completar todos los subtítulos de los párrafos.');

            let typeparagraphsintext = documentClausules.find(doc => doc.type == 'paragraph' && doc.clausule == '');
            if(typeparagraphsintext) return CustomSnackbar('error', 'asegurese de completar todos los textos de los párrafos.');

            setLoadingSend(true);

            let textSend = EstructurarData(documentClausules, false)

            const formData = {
                title: nameTitleContract,
                description: descriptionTitleContract,
                type: 4,
                details: textSend
            }

            const response = await createContract(formData);
            if(response){
                setDocumentClausules([{
                    title: '',
                    clausule:'',
                    type: 'description',
                    position: 1 ,
                }]);
                setNameTitleContract('');
                setDescriptionTitleContract('');
            }

            setLoadingSend(false);
        } catch (error) {
            setLoadingSend(false);
        }
    } 

    return (
        <Grid item xs={12}>
            <CustomFormHeader
                title={'Crear plantilla de contrato'}
                text={'(*) Para utilizar las variables debe poner el cursor en uno de los inputs, y dar espacio seguido de "@", se abrirá un modal con las variables'}
                // goBack={RoutesMap.LIST_CONTRACT_TEMPLATE}
            >
            </CustomFormHeader>

            <Divider />

            <Grid item container xs={12} className="contract-containers-btn" >
                <Grid item>
                    <Button onClick={() => { NewClausula('subtitle') }} size="small" variant="contained" className="btn-secondary" sx={{marginRight:'5px'}}>Agregar subtitulo</Button>
                    <Button onClick={() => { NewClausula('paragraph') }} size="small" variant="contained" className="btn-secondary" >Agregar parrafo</Button>
                </Grid>

                <Grid item>
                    <Button onClick={createTemplate} size="small" variant="contained" className="btn-confirm" >
                        Crear plantilla
                        {
                            loadingSend && (
                                <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                            )
                        }
                    </Button>
                </Grid>
                
            </Grid>

            <Card sx={{ padding: '10px', marginTop: '20px' }}>
                <CardContent>
                    {
                        loading && (
                        <>
                            <Skeleton variant="rectangular" width="100%" height={'30vh'} />
                        </>
                        )
                    }

                    {
                        !loading && (
                            <>
                            <Grid xs={12} sx={{textAlign:'center'}}>
                                <TextField
                                    size='small'
                                    id='title-contract'
                                    name='title-contract'
                                    value={nameTitleContract}
                                    onChange={(e) => {setNameTitleContract(e.target.value)}}
                                    placeholder='título'
                                    className="contract-title"
                                />
                            </Grid>

                            <Grid xs={12} sx={{textAlign:'center', marginTop:'10px', marginBottom:'20px'}}>
                                <TextField
                                    size='small'
                                    id='description-title-contract'
                                    name='description-title-contract'
                                    value={descriptionTitleContract}
                                    onChange={(e) => {setDescriptionTitleContract(e.target.value)}}
                                    placeholder='descripción del título (*)'
                                    className="contract-description-title"
                                />
                            </Grid>

                            {
                                documentClausules.length > 0 &&  documentClausules.map((item, key)=>(
                                    <>

                                    {
                                        item.type != 'description' && (
                                            <Button onClick={()=>{ deleteClause(item) }} >Eliminar</Button>
                                        )
                                    }

                                    <Grid xs={12} sx={{ mb: 2 }} key={key} id={`clauses-${key}`}>
                                        {
                                            item.position != 1 && (
                                                <Grid item className="contract-container-subtitle">
                                                    <TextField 
                                                        id='name-clausula'
                                                        type="text"
                                                        multiline
                                                        fullWidth
                                                        name={item.position}
                                                        value={item.title}
                                                        onChange={handleInputHead}
                                                        size='small'
                                                        sx={{ fontSize: 10 }}
                                                        placeholder={ 
                                                            item.type == 'subtitle' ? "subtítulo del contrato" : 
                                                                (item.type == 'paragraph'? "título del párrafo" : "Escriba el texto")
                                                        }
                                                    /><span >:</span>
                                                </Grid>
                                            )
                                        }
                                        
                                        {
                                            item.type != 'subtitle' && (
                                                <Grid item >
                                                    <TextField 
                                                        id='text-clausula'
                                                        type="text"
                                                        multiline
                                                        fullWidth
                                                        name={item.position}
                                                        value={item.clausule}
                                                        onChange={handleInput}
                                                        size='small'
                                                        sx={{ fontSize: 9, marginTop:'5px' }}
                                                        placeholder={
                                                            item.type == 'description' ? "descripción del contrato" : 
                                                                (item.type == 'paragraph'? "descripción del párrafo" : "Escriba el texto")
                                                        }
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </Grid>

                                    </>
                                ))
                            }
                            </>
                        )
                    }
                    
                    
                </CardContent>

            </Card>
            {
                openModal &&
                <ModalContractVariables
                    open={openModal}
                    closeModal={() => { setOpenModal(false) }}
                    dataVariables={variables}
                    variableSelected={variableSelected}
                    setVariableSelected={setVariableSelected}
                    evaluateVar={evaluateVar}
                />
            }
        </Grid>
    );
}