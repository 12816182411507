import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../types/api/status"
import { RootState } from "../store"

const initialState: any =
{
  status: ApiStatus.FETCHED,
  agents: [],
  agentsDistincs: [],
  agentsByDepartment: [],
  errorMessage: undefined,
  selectedAgent: {} as any,
  action: '',
}

const agentSlice = createSlice({
    name: 'agent',
    initialState,
    reducers: {
        onFetchAgents (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.agents       = payload
            state.errorMessage = undefined
        },
        onFetchAgentsDistincs (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.agentsDistincs       = payload
            state.errorMessage = undefined
        },
        onFetchAgentsByDepartment (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.agentsByDepartment = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedAgent (state, { payload }: { payload: any }) {
            state.selectedAgent = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
        setagents (state) {
            state.agents = initialState.agents
        },
    }
})

export const selectAgentState = (state: RootState) => state.agentSlice
export default agentSlice.reducer

export const {
  onFetchAgents,
  onFetchAgentsDistincs,
  changeStatus,
  onSetSelectedAgent,
  onFetchAgentsByDepartment,
  setAction,
  setagents,
} = agentSlice.actions