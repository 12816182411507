import { api } from "./configs/axiosConfigs"

export const DocumentsAPI = {
    
    listByUser: async (idcompany: number, data?: any) => {
        const response = await api.get(
            '/documents/listByUser',
            {
                params: {
                    ...data,
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    historyByUser: async (idcompany: number, data?: any) => {
        const response = await api.get(
            '/documents/historyByUser',
            {
                params: {
                    ...data,
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    listMyDocuments: async (idcompany: number) => {
        const response = await api.get(
            '/documents/listMyDocuments',
            {
                params: {
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    listMyHistory: async (idcompany: number) => {
        const response = await api.get(
            '/documents/listMyHistory',
            {
                params: {
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    listSharedCompany: async (idcompany: number) => {
        const response = await api.get(
            '/documents/listSharedCompany',
            {
                params: {
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    documentRegister: async (data: any) => {
        const response = await api.post(
            '/documents/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    documentDelete: async (iddetail: number, idcompany: number) => {
        const response = await api.delete(
        `/documents/delete/${iddetail}`,
        {
            params: {
                idcompany: idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    documentReplace: async (data: any) => {
        const response = await api.post(
            '/documents/replace', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    registerCategory: async (data: any) => {
        const response = await api.post(
            '/documents/registerCategory', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    listCategories: async (idcompany: number) => {
        const response = await api.get(
            '/documents/listCategories',
            {
                params: {
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
}