import { useDispatch, useSelector } from "react-redux"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { changeStatus, onFetchIdentify, onSetSelectedIdentify, selectIdentificationState, setAction, onFetchFindIdentify } from "../redux/slices/identificacionSlice";
import { UserIdentificationAPI } from "../apis/UserIdentificationAPI";

export const useIdentificacionStore = () => {
  const dispatch = useDispatch();
  
  const { status, identifications, selectedIdentify, action, findIdentify } = useSelector(selectIdentificationState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getIdentification = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserIdentificationAPI.getIdentification({...data, idcompany:idcompany})
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      dispatch(onFetchIdentify(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
      return true
    } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
    }
  }

  const findByUser = async (rut: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserIdentificationAPI.findByUser(rut)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('error', response.data.message || '')
          return false;
      }
      const { detail } = response.data
      // dispatch(onFetchFindIdentify(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
      return detail;
    } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
    }
  }

  const createIdentification = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserIdentificationAPI.createIdentification({...data, idcompany:idcompany})
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const editIdentification = async (id:number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserIdentificationAPI.editIdentification(id, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const changeStatusIdentification = async (id:number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserIdentificationAPI.changeStatusIdentification(id, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteIdentification = async (id:number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserIdentificationAPI.deleteIdentification(id)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setSelectAgent = async (data: any) => {
    try {
      dispatch(onSetSelectedIdentify(data))
    } catch (error) {
        console.log(error)
    }
  }

  const setActionAgent = async (state: any) => {
    try {
      dispatch(setAction(state))
    } catch (error) {
      console.log(error)
    }
  }

  const updateOrCreateIdentify = async (data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING));
          const response = await UserIdentificationAPI.updateOrCreate(data);
          if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED));
              return CustomSnackbar("error", "Error al guardar la imagen")
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar("success", response.data.message || "")
          return true
      } catch (error) {
          console.log(error);
          return dispatch(changeStatus(ApiStatus.FETCHED));
      }
  };

  const changeStatusIdentify = async (id:number, data:any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING));
        const response = await UserIdentificationAPI.changeStatus(id, data)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar("error", response.data.message || "")
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar("success", response.data.message || "")
        return true
    } catch (error) {
        console.log(error);
        return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  return {
    status,
    identifications,
    findIdentify,
    selectedIdentify,
    action,

    getIdentification,
    findByUser,
    createIdentification,
    changeStatusIdentification,
    editIdentification,
    deleteIdentification,
    setSelectAgent,
    setActionAgent,
    updateOrCreateIdentify,
    changeStatusIdentify
  }
}