import { useDispatch, useSelector } from "react-redux"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { changeStatus, onFetchVisits, onFetchvalidatedVisit, onSetSelectedVisit, selectVisitState, setAction } from "../redux/slices/visitSlice";
import { VisitApi } from "../apis/VisitApi";

export const useVisitStore = () => {
    const dispatch = useDispatch();
    const { status, visits, selectedVisit, validatedVisit, action } = useSelector(selectVisitState)

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    const idcompany = userData?.data?.idcompany

    const getVisitsByDepartment = async (iddepartment:number, data:any=null) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await VisitApi.getVisitsByDepartment(iddepartment, data)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false;
        }
        const { detail } = response.data
        dispatch(onFetchVisits(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
        }
    }

    const validateVisit = async (data:any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await VisitApi.validateVisit(data)
        if (!response?.status) {
            dispatch(onFetchvalidatedVisit({error: response.data.message || ''}))
            dispatch(changeStatus(ApiStatus.FETCHED))
            // CustomSnackbar('error', response.data.message || '')
            return false;
        }
        const { detail } = response.data
        dispatch(onFetchvalidatedVisit(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
        }
    }

    const confirmVisit = async (id:number) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await VisitApi.confirmVisit(id)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const createVisit = async (data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await VisitApi.createVisit({...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const editVisit = async (id:number, data: any) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await VisitApi.editVisit(id, {...data, idcompany: idcompany})
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const deleteVisit = async (idvisit: number) => {
        try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await VisitApi.deleteVisit(idvisit)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || '')
            return false;
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
        } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
        }
    }

    const setSelectVisit = async (data: any) => {
        try {
            dispatch(onSetSelectedVisit(data))
        } catch (error) {
            console.log(error)
        }
    }

    const setActionVisit = async (state: any) => {
        try {
            dispatch(setAction(state))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        status,
        visits,
        validatedVisit,
        selectedVisit,
        action,

        getVisitsByDepartment,
        validateVisit,
        confirmVisit,
        createVisit,
        editVisit,
        deleteVisit,
        setSelectVisit,
        setActionVisit,
    }
}