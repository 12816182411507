import { ApiStatus } from "../types/api/status"
import { useDispatch, useSelector } from "../redux/store"
import { changeStatus, onFetchTypeUsers, onFetchUsers, onSetSelectedUser, selectUserState, setAction } from "../redux/slices/UserSlice"
import { UserAPI } from "../apis/UserAPI"
import { User } from "../types/slices/userType"
import { UserDataForm } from "../types/api/UserApi.type"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useUserStore = () => {

  const dispatch = useDispatch()
  const { users, typeUsers, userSelected, status, action } = useSelector(selectUserState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getUsers = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.getUsers()
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchUsers(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getUserType = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.getUserType()
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchTypeUsers(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))

    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const createUser = async (data: UserDataForm) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.createUser(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setSelectedUser = (user: User) => {
    try {
      dispatch(onSetSelectedUser(user))
    } catch (error) {
      console.log(error)
    }
  }

  const editUser = async (iduser: number, data: UserDataForm) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.editUser(iduser, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteUser = async (iduser: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.deleteUser(iduser)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const findAdmin = async (idadmin) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.findAdmin(idadmin)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      dispatch(changeStatus(ApiStatus.FETCHED))
      return detail
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }

  const editAdmin = async (idadmin: number, data) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await UserAPI.editAdmin(idadmin, data)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message  || "");
        return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const changePassword = async (data: any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await UserAPI.changePassword(data)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('error', response.data.message || "ocurrió algún error, intentelo mas tarde");
          return false;
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message  || "");
        return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const forgotPassword = async (data: any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await UserAPI.forgotPassword(data)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('error', response.data.message || "ocurrió algún error, intentelo mas tarde");
          return false;
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message  || "");
        return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const findPIN = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.findPIN()
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        // CustomSnackbar('error', response.data.message || '')
        return false;
      }
      const { detail } = response.data
      dispatch(changeStatus(ApiStatus.FETCHED))
      // CustomSnackbar('success', response.data.message || '')
      return detail
    } catch (error) {
    dispatch(changeStatus(ApiStatus.FETCHED))
    console.log(error)
    }
  }

  const createPIN = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.createPIN(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "ocurrió algún error, intentelo mas tarde");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const validatePIN = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.validatePIN({...data, idcompany: idcompany})
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('error', response.data.message || '')
          return false;
      }
      const { detail } = response.data
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return detail
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const changePIN = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await UserAPI.changePIN(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "ocurrió algún error, intentelo mas tarde");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setActionUser = (state) => {
    try {
      dispatch(setAction(state))
    } catch (error) {
      console.log(error)
    }
  }

  return {
    //states
    users,
    typeUsers,
    userSelected,
    status,
    action,
    //actions
    getUsers,
    getUserType,
    createUser,
    setSelectedUser,
    editUser,
    deleteUser,
    findAdmin,
    editAdmin,
    changePassword,
    validatePIN,
    createPIN,
    findPIN,
    changePIN,
    forgotPassword,
    setActionUser,
  }
}