import { Formik } from "formik";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

type Values = {
    id: number | undefined,
    name: string
    idcompany: number | undefined
}

export const ConceptCategoryModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, clearState } = props;

    const [data, setData] = useState<Values>({
        id: rowSelected ? rowSelected.id :'',
        name: rowSelected ? rowSelected.name :'',
        idcompany: rowSelected ? rowSelected.idcompany :'',
    });

    const validateForm = (values) => {}

    const onSubmit = async(values)  => {}

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle del concepto de categoria'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Nombre"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            multiline
                                            fullWidth
                                            size='small'
                                            value={values.name}
                                            placeholder={"nombre"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    cancelText={"Cancelar"}
                                    onCancel={closeModal}
                                />
                            </form>
                        )
                    }} 
                </Formik>
            </ModalBody>
        </Modal>
    )
}