import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, FormLabel, Autocomplete, InputAdornment, Checkbox } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useDepartmentStore } from "../../hooks/useDepartmentStore"
import { useNavigate } from "react-router-dom"
import { Department } from "../../types/slices/departmentType"
import { useOwnerStore } from "../../hooks/useOwnerStore"
import { useServiceStore } from "../../hooks/useServiceStore"
import { Service } from "../../types/slices/serviceSlice"
import { Owner } from "../../types/slices/ownerType"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../types/api/status"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"
import { useBuildingStore } from "../../hooks/useBuildingStore"
import { Building } from "../../types/slices/buildingType"

type BuildingDataForm = {
    id: number
    name: string
    address: string
    iddepartments: number[] 
    departaments?: any,
    dimensions: number
    idcompany: number | null
    number_departments: number
}

export const BuildingForm = (props) => {

    const navigate = useNavigate()

    const { status: statusDepartamentApi, departments, departmentsFilter, getDepartments, deparmentWithOutBuilding} = useDepartmentStore()
    const { status: statusBuilding , setSelectedBuilding,createdBuilding, createdRealtionBuildingAndDepa, selectedBuilding, editBuilding } = useBuildingStore()
    const [ selectedDepartment, setSelectedDepartment ] = useState<Department[]>([])
    const [ isEdit, setIsEdit ] = useState<boolean>(false)

    const [ data, setData ] = useState<BuildingDataForm>({
        id: 0,
        name: "",
        address: "",
        iddepartments: [],
        departaments: [],
        dimensions: 0,
        idcompany: null,
        number_departments:0
    });

    useEffect(()=>{
        if(selectedBuilding?.id ){
            getDepartments()
        }else{
            deparmentWithOutBuilding()
        }
    }, [])

    useEffect(()=>{
        if(selectedBuilding.id && departments.length > 0) {
            setIsEdit(true)
            setData({
                id: selectedBuilding.id,
                name: selectedBuilding.name,
                address: selectedBuilding.address,
                iddepartments: [],
                dimensions: selectedBuilding.dimensions,
                idcompany: selectedBuilding.idcompany,
                number_departments: selectedBuilding.number_departments
            })
            const a_iddepartament = selectedBuilding?.departments.map(departament => departament.department_id)
            setSelectedDepartment(setCustomDepartaments(a_iddepartament, departments) || [])
        }
    },[selectedBuilding.id && departments.length > 0])

    /* custom departaments */
    const setCustomDepartaments = (iddepartaments, originaldepartament) => {
        const data = iddepartaments ? iddepartaments.map( iddepartament => originaldepartament.find(value => value.id == iddepartament)) : [];
        return getCustomDepartaments(data);
    }
    const getCustomDepartaments = (originalDepartaments) => {
        const res = originalDepartaments ? originalDepartaments.map(departament => ({...departament})) : [];
        return originalDepartaments ? originalDepartaments.map(departament => ({...departament})) : [];
    }
    const customDepartaments = getCustomDepartaments(departments || []);

    /* custom services */
    const onCancel = ()  => {
        navigate({ pathname: RoutesMap.BUILDING })
    }

    const clearState = () => {
        setSelectedBuilding({} as Building)
        setSelectedDepartment([])
    }

    const handleKeyDown = (event) => {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 189 || keyCode === 109) {
          event.preventDefault();
        }
    }

    const validateForm = (values) => {
        let errors:any = {};
        const regex = /^[0-9\b]+$/;
        if(!values.name) errors.name = "nombre requerido";
        if(!values.address) errors.address = "dirección requerido";
        // if(!values.dimensions) errors.dimensions = "dimensiones requerido";
        if(!values.number_departments) errors.number_departments = "cantidad requerido";
        if(values.number_departments.length == 0) errors.number_departments = "cantidad requerido";
        return errors;
    }

    const selectedDepartament = (departament, values) => {
        if(values?.number_departments == 0) return CustomSnackbar('warning', 'El N° de departamentos no puede ser 0.')
        if(departament.length > values?.number_departments) return CustomSnackbar('warning', 'La cantidad de departamentos seleccionados no puede ser mayor al n° de departamentos.')

        setSelectedDepartment(departament);
    }

    const onSubmit = async (values)  => {
        const a_iddepartament   = selectedDepartment.map(departament => departament.id)
        // if(a_iddepartament.length == 0) return CustomSnackbar('warning', 'El N° de departamentos no puede ser 0.')
        
        const formdata = {
            ...values,
            dimensions: (values.dimensions).toString(),
            iddepartments: a_iddepartament ||  [],
        }
            if (!isEdit) {
                const response = await createdBuilding(formdata);
                if (response?.id){
                    if (a_iddepartament.length > 0) {
                        await createdRealtionBuildingAndDepa({iddepartments:a_iddepartament, idowner:response?.idowner},response?.id)
                    }
                    navigate({ pathname: RoutesMap.BUILDING })
                }
            } else {
                const response = await editBuilding(data.id, formdata)
                if (response?.id) {
                    // if (a_iddepartament.length > 0) {
                    //     await createdRealtionBuildingAndDepa({iddepartments:a_iddepartament, idowner:response?.idowner},data?.id)
                    // }
                    navigate({ pathname: RoutesMap.BUILDING })
                }
            }
        // }
    }

    return (
        <>
        { statusDepartamentApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        { statusBuilding === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        <CustomForm >
            <CustomFormHeader
                title={!selectedBuilding.id ? 'Nuevo Edificio' : 'Editar Edificio'}
                text={!selectedBuilding.id ? 'Ingrese los datos del nuevo Edificio' : 'Ingrese los datos del Edificio'}
                text2={!selectedBuilding?.id ? '  - Asegurese que el campo "N° Departamentos" contenga el valor correcto, ya que una vez creado el edificio, no podrá ser editado.' : ''}
                goBack={RoutesMap.BUILDING}
                clearState={clearState}
            >
            </CustomFormHeader>
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values)} onSubmit={onSubmit} >
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name && touched.name ? true : false}
                                            helperText={errors.name && touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Dirección:</FormLabel>
                                        <TextField
                                            id="address"
                                            type="text" 
                                            name="address"
                                            fullWidth
                                            size="small"
                                            value={values.address}
                                            onChange={handleChange}
                                            error={errors.address && touched.address ? true : false}
                                            helperText={errors.address && touched.address ? errors.address : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Dimensión (*):</FormLabel>
                                        <TextField
                                        id="dimensions"
                                        type="number"
                                        name="dimensions"
                                        fullWidth
                                        size="small"
                                        value={values.dimensions}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                        error={errors.dimensions && touched.dimensions ? true : false}
                                        helperText={errors.dimensions && touched.dimensions ? errors.dimensions : ''}
                                        onKeyDown={handleKeyDown}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item container xs={12} spacing={2} sx={{marginTop:'10px'}}>
                                  
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>N° Departamentos:</FormLabel>
                                        <TextField
                                            id="number_departments"
                                            type="number" 
                                            name="number_departments"
                                            fullWidth
                                            size="small"
                                            value={values.number_departments}
                                            onChange={handleChange}
                                            error={errors.number_departments && touched.number_departments ? true : false}
                                            helperText={errors.number_departments && touched.number_departments ? errors.number_departments : ''}
                                            InputProps={{
                                                readOnly: !!selectedBuilding.id,
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Grid>

                                    {
                                        isEdit ? (
                                            <Grid item xs={12} md={8}>
                                                <FormLabel>Departamentos:</FormLabel>
                                                <Autocomplete
                                                    multiple={true}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    value={selectedDepartment|| []}
                                                    options={customDepartaments || []}
                                                    getOptionLabel={(option) => option?.name || ""}
                                                    fullWidth
                                                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                                    renderInput={
                                                        (params) => <TextField {...params} size="small" />
                                                    }
                                                    disabled={selectedBuilding?.id ? true : false}
                                                    onChange={(e, newValue) => {
                                                        selectedDepartament(newValue, values);
                                                    }}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li key={option.id} value={option.id} {...props} >
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                checked={selected}
                                                            />
                                                            { option.name }
                                                        </li>
                                                    )}
                                                    filterSelectedOptions
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} md={8}>
                                                <FormLabel>Departamentos:</FormLabel>
                                                <Autocomplete
                                                    multiple={true}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    value={selectedDepartment|| []}
                                                    options={departmentsFilter || []}
                                                    getOptionLabel={(option) => option?.name || ""}
                                                    fullWidth
                                                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                                    renderInput={
                                                        (params) => <TextField {...params} size="small" />
                                                    }
                                                    disabled={selectedBuilding?.id ? true : false}
                                                    onChange={(e, newValue) => {
                                                        selectedDepartament(newValue, values);
                                                    }}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li key={option.id} value={option.id} {...props} >
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                checked={selected}
                                                            />
                                                            { option.name }
                                                        </li>
                                                    )}
                                                    filterSelectedOptions
                                                />
                                            </Grid>
                                        )
                                    }

                                </Grid>
                                
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={ !selectedBuilding.id ? 'Guardar' : 'Actualizar' }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
        </>
    )
}
