import { useState } from "react";
import { Modal } from "../Modal/Modal";
import { ModalBody } from "../Modal/ModalBody";
import { ModalFooter } from "../Modal/ModalFooter";
import { ModalHeader } from "../Modal/ModalHeader";
import { Typography, Grid, FormLabel, TextField, Divider } from "@mui/material";
import { Formik } from "formik";
import { useUserStore } from "../../../hooks/UseUserStore";
import { ApiStatus } from "../../../types/api/status";
import { useLocation } from "react-router-dom";
import { RoutesMap } from "../../../types";

type DataForm = {
    rut: string
}

export const ModalForgotPassword: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, role } = props;

    const { pathname } = useLocation()

    const urlLogins = ['/login-arrendatario', '/login-propietario', '/login-corredor']

    const iscurrentURL = urlLogins.includes(pathname);

    const {status:statusUser, forgotPassword } = useUserStore();

    const [data, setData] = useState<DataForm>({
        rut: '',
    });

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.rut) errors.rut = "el rut es requerido";
        return errors;
    }

    const onSubmit = async (values)  => {
        const response = await forgotPassword({...values, iduser_type:role})
        if(response){
            closeModal && closeModal();
        }else{
            return false;
        }
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalHeader
                text={'¡No te preocupes!. En un momento volverás a iniciar sesión'}
                className='positionElements'
                onCancel={closeModal}
                font_size={12}
            >
            </ModalHeader>
            <ModalBody>
                <Typography sx={{color:'#101010', fontSize:'14px'}}> 
                    { !iscurrentURL ? 'Asegúrate de haber seleccionado tu tipo de usuario correcto.' : ''}
                </Typography>
                <Typography sx={{color:'#101010', fontSize:'12px'}}> 
                    {'- Las credenciales de tu cuenta se enviarán al correo registrado en el sistema.'}
                </Typography>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} >
                                    <Grid item xs={12}>
                                        <FormLabel>Ingrese su número de RUT:</FormLabel>
                                        <TextField
                                            id="rut"
                                            type="text" 
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            error={errors.rut && touched.rut ? true : false}
                                            helperText={errors.rut && touched.rut ? errors.rut : ''}
                                            InputProps={{
                                                inputProps: {
                                                    maxLength: 10
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={ 'confirmar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={closeModal}
                                    loadingConfirmText={statusUser == ApiStatus.FETCHING ? true : false}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </ModalBody>
        </Modal>
    );
}