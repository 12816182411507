import { api } from './configs/axiosConfigs'

export const ContractApi = {

    listVariablesContract: async (params:any) => {

        const response = await api.get(
        '/contract/listVariablesContract',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    listTablesOfDatabase: async () => {

        const response = await api.get(
        '/contract/listTablesOfDatabase',
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    registerVariable: async (data: any) => {
        const response = await api.post(
            '/contract/registerVariable', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    registerVariableMassive: async (data: any) => {
        const response = await api.post(
            '/contract/registerVariableMassive', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    /* ADMIN */

    getContract: async (params:any) => {

        const response = await api.get(
        '/contract/list',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    listContractTemplates: async (params:any) => {

        const response = await api.get(
        '/contract/listContractTemplates',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    findContractTemplates: async (params:any) => {

        const response = await api.get(
        '/contract/findContractTemplates',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    getContractHistory: async (params:any) => {

        const response = await api.get(
        '/contract/history',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    createContract: async (data: any) => {
        const response = await api.post(
            '/contract/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    createAsignature: async (data: any) => {
        const response = await api.post(
            '/contract/createAsignature', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    renewContract: async (data: any) => {
        const response = await api.post(
            '/contract/renewContract', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    downloadContract: async (data: any) => {
        const response = await api.post(
            '/contract/downloadContract', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    editContract: async (idcontract: number, data: any) => {
        const response = await api.patch(
        `/contract/update/${idcontract}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    updateContract: async (idcontract: number, data: any) => {
        const response = await api.patch(
        `/contract/updateContract/${idcontract}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    updateClausule: async (idclausule: number, data: any) => {
        const response = await api.patch(
        `/contract/updateClausule/${idclausule}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    deleteContract: async (data: any) => {
        const response = await api.delete(
        `/contract/delete`, {
            params: data,
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    updateContractStatus: async (data: any) => {
        const response = await api.post(
            '/contract/updateContractStatus', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    validateContract: async (data: any) => {
        const response = await api.post(
            '/contract/validateContract', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },


    /* PROFILE OWNER */

    listContractTemplatesOwner: async (params:any) => {

        const response = await api.get(
        '/contractOwner/listContractTemplates',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    findContractTemplatesOwner: async (params:any) => {

        const response = await api.get(
        '/contractOwner/findContractTemplates',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    updateClausuleOwner: async (idclausule: number, data: any) => {
        const response = await api.patch(
        `/contractOwner/updateClausule/${idclausule}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    getContractOwner: async (params:any) => {

        const response = await api.get(
        '/contractOwner/listRent',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    editContractOwner: async (idcontract: number, data: any) => {
        const response = await api.patch(
        `/contractOwner/updateRent/${idcontract}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    editContractOwnerMassive: async (data: any) => {
        const response = await api.patch(
        `/contractOwner/updateRentMassive`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    /* PROFILE AGENT */
    listContractTemplatesForAgent: async (params:any) => {

        const response = await api.get(
        '/contract/listContractTemplatesForAgent',
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

}