import { useDispatch, useSelector } from "react-redux"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { changeStatus, onFetchTenants, onSetSelectedTenant, selectTenantState, setAction, settenants } from "../redux/slices/tenantSlice";
import { ApiStatus } from "../types/api/status";
import { OwnerAPI } from "../apis/OwnerAPI";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

export const useTenantStore = () => {
  const dispatch = useDispatch();
  const { status, tenants, tenantsDistincs, selectedTenant, action } = useSelector(selectTenantState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getTenant = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await OwnerAPI.getOwners(idcompany, data)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      dispatch(onFetchTenants(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }

  const createTenant = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await OwnerAPI.createOwner(data, idcompany)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const editTenant = async (id:number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await OwnerAPI.editOwner(id, data, idcompany)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const deleteTenant = async (id:number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await OwnerAPI.deleteOwner(id, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || '')
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const findTenant = async (data: any) => {

  }

  const setSelectTenant = async (data: any) => {
    try {
      dispatch(onSetSelectedTenant(data))
    } catch (error) {
      console.log(error)
    }
  }

  const setActionTenant = async (state: any) => {
    try {
      dispatch(setAction(state))
    } catch (error) {
      console.log(error)
    }
  }

  const resetTenants = () => {
    try {
        dispatch(settenants());
    } catch (error) {
        console.error(error);
    }
  }

  return {
    status,
    tenants,
    tenantsDistincs,
    selectedTenant,
    action,

    getTenant,
    createTenant,
    editTenant,
    deleteTenant,
    findTenant,
    setSelectTenant,
    setActionTenant,
    resetTenants,
  }
}