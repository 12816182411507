import { Grid, CircularProgress } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType";
import { useEffect, useState } from 'react';
import { OwnerByDepartamentModal } from './OwnerByDepartmentModal';
import { useOwnerStore } from '../../hooks/useOwnerStore';
import { useNavigate } from 'react-router-dom';
import { Owner } from '../../types/slices/ownerType';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { ApiStatus } from '../../types/api/status';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useAuthStore } from '../../hooks';
import { useContractStore } from '../../hooks/useContractStore';
import { RenewContractModal } from './RenewContractModal';
import { Role } from '../../types/roles/roleTypes';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { ValidateIdentityModal } from './ValidateIdentityModal';
import { IdentificationModalAdmin } from './Identification/IdentificationModalAdmin';

const columns = [
  { type: 'options',  field: 'options', align:'center', label: 'Opciones' },
  { type: 'text', field: 'rut', label: 'Rut', align:'center'},
  { type: 'text', field: 'name', label: 'Nombre'},
  { type: 'text', field: 'email', label: 'Correo', align:'center'},
  { type: 'text', field: 'privilege', label: 'Tipo', align:'center',
    format: (row) => {
      switch (row?.privilege) {
        case "1":
          return "Comprador";
        case "2":
          return "Arrendatario";
        case "3":
          return "Dueño General";
        default:
          return "----";
      }
    }
  },
  { type: 'text', field: 'status', label: 'Estado', align:'center',
    format: (row) => {
      switch (row?.status) {
        case "1":
          return "Vigente";
        case "5":
          return "Reemplazado";
      }
    }
  },
  { type: 'detail',   field: 'detail',         align:'center',     label: '' }
];

export const OwnerByDepartament = () => {

  const { user } = useAuthStore();
  const navigate = useNavigate();
  const { status: statusApiDepartment, setSelectedDepartment, selectedDepartment, departmentByOwner, getDepartmentsByOwner, deleteDepartmentsByOwner} = useDepartmentStore();

  const { status: statusContract, deleteContract, validateContract} = useContractStore();
  const { setSelectedOwnerAdd } = useOwnerStore();

  const role = user?.userType;

  const [rowSelected, setRowSelected] = useState<any>('');
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [loadingContract, setLoadingContract] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [showModalContrat, setShowModalContrat] = useState<boolean>(false);
  const [showRenewContrat, setShowRenewContrat] = useState<boolean>(false);
  const [showModalIdentity, setShowModalIdentity] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDepartment?.id) {
      getDepartmentsByOwnerApi();
    } else {
      navigate({ pathname: RoutesMap.DEPARTAMENT }) 
    }
  }, []);

  const getDepartmentsByOwnerApi = async() => {
    try {
      setLoadingTable(true);
      const response = await getDepartmentsByOwner(selectedDepartment?.id);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  }

  const clearState = () => {
    setSelectedDepartment({} as Department);
  }

  const onEdit = (rowSelected) => {
    setRowSelected(rowSelected)

    if(rowSelected.is_admin === "1") { return CustomSnackbar("warning","No se puede editar directamente el porcentaje de propietarios.")}
    setShowModalEdit(true);
  }

  const onDelete = (rowSelected) => {
    if (user.iduser_type == 3 && rowSelected?.idowner == user.id) return CustomSnackbar("error","No se puede autoeliminar.")
    setRowSelected(rowSelected)
    setShowModalConfirm(true);
  }

  const onDeleteConfirm = async() => {
    if (rowSelected.id) {
      const response = await deleteDepartmentsByOwner(rowSelected.id)
      if(response){
        setShowModalConfirm(false)
        getDepartmentsByOwnerApi()
      }else{
        return false;
      }
    }
  }
  
  const onCreate = () => {
    setShowModalCreate(true);
  }

  const onCloseModal = (onSubmit?: boolean) => {
    if (onSubmit) {
      getDepartmentsByOwnerApi();
    }
    setShowModalCreate(false);
    setSelectedOwnerAdd({} as Owner);
  }

  const onCloseModalEdit = (onSubmit?: boolean) => {
    if (onSubmit) {
      getDepartmentsByOwnerApi();
    }
    setShowModalEdit(false);
    setSelectedOwnerAdd({} as Owner);
  }

  const showContract = async(rowSelected) => {

    if(rowSelected.is_admin == '1' || rowSelected.privilege == '1' ) return CustomSnackbar("warning","Ud. No tiene contrato.");

    setLoadingContract(true);
    const params: any = {
      type: rowSelected.privilege, 
      iddepartment: rowSelected.iddepartment,
      idnewowner: rowSelected.idowner,
    };

    const validation = await validateContract(params);

    if(validation && validation.confirmStatus == '0') {
      setLoadingContract(false);
      return CustomSnackbar("warning","El contrato aún no ha sido confirmado por el propietario/arrendatario.")
    }

    if(validation && validation.url_doc ){
      setLoadingContract(false);
      window.open(`${process.env.REACT_APP_ROOT_URL}/` + validation.url_doc, '_blank')
    }
    setLoadingContract(false);

  }

  const showIdentification = async(rowSelected) => {
    if(user && user.rut && user.rut == rowSelected?.rut) return false;
    if (rowSelected.is_admin === "1") return false;
    setRowSelected(rowSelected)
    setShowModalIdentity(true)
  }

  const onRenewContract = (rowSelected) => {
    setRowSelected(rowSelected)
    setShowRenewContrat(true);
  }

  const onDeleteContract = (rowSelected) => {
    if (user.iduser_type == 3 && rowSelected?.idowner == user.id) return CustomSnackbar("error","No se puede autoeliminar.")
    setRowSelected(rowSelected)
    setShowModalContrat(true);
  }

  const deleteContractConfirm = async() => {
    if (!rowSelected) return CustomSnackbar("warning", "Seleccione un propietario/arrendatario.")
    const data = {
      idowner: rowSelected.idowner,
      iddepartment: rowSelected.iddepartment
    }
    const response = await deleteContract(data)
    if(response){
        setShowModalContrat(false)
        getDepartmentsByOwnerApi()
    }else{
        return false;
    }
  }

  return (
    <>
    <Grid item xs={12}>
      <CustomFormHeader
        title={selectedDepartment.id ? `Propietarios de ${selectedDepartment.name}` : '....'}
        goBack={RoutesMap.DEPARTAMENT}
        clearState={clearState}
      >
      </CustomFormHeader>

      <CustomTable 
        disabled_title
        columns={columns} 
        loading={loadingTable}
        rows={departmentByOwner ||  []}
        onAddFn={onCreate}
        onDelete={onDelete}
        onRowClick={() => {}}
        hasOptions
        onHelper={role != Role.TENANT ? showContract : ''}
        icon_helper={loadingContract ? <CircularProgress color="primary" size={12} /> : <PlagiarismIcon fontSize="small" />}
        tooltip_helper={'ver contrato'}
        onHelper2={(role == Role.ADMIN || role == Role.OWNER) ? showIdentification : ''}
        icon_helper2={<HowToRegIcon fontSize="small" />}
        tooltip_helper2={'validar documentos de identidad'}

        // onBtnMenu
        // iconBtnMenu={<PlagiarismIcon fontSize="small" />}
        // tooltip_menu={'contrato'}
        // options={[{id:1, name: 'ver contrato', action: showContract}, {id:2, name: 'renovar contrato', action: onRenewContract}, {id:3, name: 'eliminar contrato', action: onDeleteContract}]}
      />
    </Grid>
    {
      showModalCreate && (
        <OwnerByDepartamentModal
          open={showModalCreate}
          closeModal={onCloseModal}
          row={selectedDepartment}
          owners={departmentByOwner}
        />
      )
    }
    {
      showRenewContrat && (
        <RenewContractModal
          open={showRenewContrat}
          closeModal={()=>{setShowRenewContrat(false)}}
          row={rowSelected}
        />
      )
    }
    {
      showModalConfirm && (
          <ModalConfirm
              open={showModalConfirm}
              closeModal={()=>{
                setShowModalConfirm(false)
              }}
              onConfirm={onDeleteConfirm}
              status2={statusApiDepartment == ApiStatus.FETCHING ? true : false}
          />
      )
    }
    {
      showModalContrat && (
          <ModalConfirm
              title={'¿Esta seguro de eliminar el contrato?'}
              text={'Recuerda que al eliminar el contrato, estas eliminando toda relación que existe entre el departamento y el propietario/arrendatario seleccionado.'}
              open={showModalContrat}
              closeModal={()=>{
                setShowModalContrat(false)
              }}
              onConfirm={deleteContractConfirm}
              status2={statusContract == ApiStatus.FETCHING ? true : false}
          />
      )
    }
    {/* {
      showModalIdentity && (
        <ValidateIdentityModal
          open={showModalIdentity}
          closeModal={()=>{setShowModalIdentity(false)}}
          row={rowSelected}
        />
      )
    } */}
    {
      showModalIdentity && (
        <IdentificationModalAdmin
          open={showModalIdentity}
          closeModal={()=>{setShowModalIdentity(false)}}
          rut={rowSelected.rut}
        />
      )
    }
    </>
  )
}