import { Formik } from "formik";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Divider, Accordion, AccordionSummary, AccordionDetails, ListItemButton, Typography } from "@mui/material";
import { useDispatch } from "../../redux/store";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Values = {
    id: number | undefined,
    name: string
    arrival_date: string
    exit_date: string
    status: string | undefined
}

export const SpecialDateModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, clearState } = props;
    const dispatch = useDispatch();

    console.log(rowSelected)

    const [data, setData] = useState<Values>({
        id: rowSelected ? rowSelected.id :'',
        name: rowSelected ? rowSelected.name :'',
        arrival_date: rowSelected ? rowSelected.arrival_date :'',
        exit_date: rowSelected ? rowSelected.exit_date :'',
        status: rowSelected ? rowSelected.status :'',
    });

    const validateForm = (values) => {}

    const onSubmit = async(values)  => {}

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle de la Fecha Especial'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Información general</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.name}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha de inicio</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.arrival_date}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha fin</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.exit_date}</Typography>
                        </ListItemButton>
                        <Divider />
                        <Divider />
                        
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Departamentos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            rowSelected.departments && (rowSelected.departments).length > 0
                            ?
                            (rowSelected.departments).map((item:any)=>{

                                return (
                                    <>
                                    <ListItemButton>
                                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>{item.name}</Typography>
                                    </ListItemButton>
                                    <Divider />
                                    </>
                                )
                            })
                            :
                            <Typography sx={{ color: 'text.secondary' }}>No cuenta con departamentos afiliados</Typography>
                        }
                        

                    </AccordionDetails>
                </Accordion>
            </ModalBody>
        </Modal>
    )
}