import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Year } from "../../types/slices/yearType";
import { reportRendition, reportType } from "../../types";

const initialState: reportType =
{
    status: ApiStatus.FETCHED,
    filter: '01',
    year: null,
    month: '' as string,
    department: null,
    building: null,
    owner: null,
    dataReportRendition: {} as reportRendition,
    dataReportRenditionPayment: {},
    dataReportReservation: {},
    dataReportAssignment: {},
    errorMessage: undefined,
}

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        onFetchDataReportRenditionPayment (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.dataReportRenditionPayment  = payload
            state.errorMessage = undefined
        },
        onFetchDataReportRendition (state, { payload }: { payload: reportRendition }) {
            state.status       = ApiStatus.FETCHED
            state.dataReportRendition  = payload
            state.errorMessage = undefined
        },
        onFetchDataReportReservation (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.dataReportReservation  = payload
            state.errorMessage = undefined
        },
        onFetchDataReportAssignment (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.dataReportAssignment  = payload
            state.errorMessage = undefined
        },
        onSetFilter (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.filter  = payload
        },
        onSetMonth (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.month  = payload
        },
        onSetYear (state, { payload }: { payload: Year | null }) {
            state.status       = ApiStatus.FETCHED
            state.year  = payload
        },
        onSetDepartment (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.department  = payload
        },
        onSetBuilding (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.building  = payload
        },
        onSetOwner (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.owner  = payload
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        clearFilters (state) {
            state.status = initialState.status
            state.filter = initialState.filter
            state.year = initialState.year
            state.month = initialState.month
            state.department = initialState.department
            state.building = initialState.building
            state.owner = initialState.owner
            state.dataReportRendition = initialState.dataReportRendition
            state.dataReportRenditionPayment = initialState.dataReportRenditionPayment
            state.dataReportReservation = initialState.dataReportReservation
            state.dataReportAssignment = initialState.dataReportAssignment
            state.errorMessage = initialState.errorMessage
        },
    }
})

export const selectReportState = (state: RootState) => state.reportSlice

export default reportSlice.reducer

export const {
  changeStatus,
  onFetchDataReportRendition,
  onFetchDataReportRenditionPayment,
  onFetchDataReportReservation,
  onFetchDataReportAssignment,
  onSetFilter,
  onSetMonth,
  onSetYear,
  onSetDepartment,
  onSetBuilding,
  onSetOwner,
  clearFilters,
} = reportSlice.actions