import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Accordion, AccordionDetails, AccordionSummary, Divider, ListItemButton, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDepartmentSpentStore } from "../../hooks/useDepartmentSpentStore";
import { useEffect } from "react";
import { ApiStatus } from "../../types/api/status";

export const DepartamentModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, onList, clearState } = props;

    const { status: departmentSpentStatus, departmentSpents, getDepartmentSpents } = useDepartmentSpentStore()

    useEffect(()=>{
        getDepartmentSpents({ iddepartment: rowSelected.id })
    },[])

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle de departamento'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Información general</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.name}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Dirección</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.address}</Typography>
                        </ListItemButton>
                        <Divider />
                        {/* <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Locación</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.location_url}</Typography>
                        </ListItemButton>
                        <Divider /> */}
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Capacidad</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.capacitance}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>N° max propietarios</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.num_owner}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>N° habitaciones</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.detail?.num_rooms}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>N° baños</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.detail?.num_bathrooms}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Dimensión</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.detail?.dimensions  + ' m²'} </Typography>
                        </ListItemButton>
                        <Divider />
                        
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Propietarios</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            rowSelected && (rowSelected.owners).length > 0
                            ?
                            (rowSelected.owners).map((item)=>(
                                <>
                                <ListItemButton>
                                    <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{item.name}</Typography>
                                </ListItemButton>
                                <ListItemButton>
                                    <Typography sx={{ width: '40%', flexShrink: 0 }}>Porcent. departamento</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        {item.participation_percentage ? item.participation_percentage + '%' : '-----'}
                                    </Typography>
                                </ListItemButton>
                                <Divider />
                                </>
                            ))
                            :
                            <Typography sx={{ color: 'text.secondary' }}>No cuenta con propietarios afiliados</Typography>
                        }

                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Servicios</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            rowSelected && (rowSelected.services).length > 0
                            ?
                            (rowSelected.services).map((item)=>(
                                <>
                                <ListItemButton>
                                    <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{item.service}</Typography>
                                </ListItemButton>
                                <Divider />
                                </>
                            ))
                            :
                            <Typography sx={{ color: 'text.secondary' }}>No cuenta con servicios afiliados</Typography>
                        }

                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Conceptos de gasto</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            departmentSpentStatus === ApiStatus.FETCHED && departmentSpents && (departmentSpents).length > 0 
                            ?
                            (departmentSpents).map((item)=>(
                                <>
                                <ListItemButton>
                                    <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{item.concept_spent}</Typography>
                                </ListItemButton>
                                <ListItemButton>
                                    <Typography sx={{ width: '40%', flexShrink: 0 }}>Monto</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{item?.amount ? item.amount : '-----'}</Typography>
                                </ListItemButton>
                                <Divider />
                                </>
                            ))
                            :
                            <Typography sx={{ color: 'text.secondary' }}>No cuenta con conceptos de gasto asociados</Typography>
                        }
                        {
                            departmentSpentStatus === ApiStatus.FETCHING && (
                                <ListItemButton>
                                    Espere un momento...
                                </ListItemButton>
                            )
                        }

                    </AccordionDetails>
                </Accordion>
            
            </ModalBody>
            
            <Divider />
            
            <ModalFooter 
                buttonType="submit"
                cancelText={"Cancelar"}
                onCancel={closeModal}
            />
        </Modal>
    )
}