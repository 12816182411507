import { toast } from 'react-hot-toast';
import WarningIcon from '@mui/icons-material/Warning';

export const CustomSnackbar = (alert_type:string,message:string) => {
  switch (alert_type) {
    case "warning":
      return toast(message, {
        duration: 2000,
        icon: <WarningIcon style={{color: "orange"}}/>,
      });
    case "success":
      return toast.success(message, {
        duration: 2000,
      });
    case "error":
      return toast.error(message, {
        duration: 2000,
      });
  }
}