import { UserDataForm } from '../types/api/UserApi.type'
import { api } from './configs/axiosConfigs'

export const UserIdentificationAPI = {

    getIdentification: async (data?: any) => {
        const response = await api
            .get('/identification/list', { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => { console.error(error); return error.response.data })
        return response;
    },

    findByUser: async (rut: any) => {
        const response = await api
            .get(`/identification/find/${rut}`)
            .then((response) => response.data)
            .catch((error) => { console.error(error); return error.response.data })
        return response;
    },

    createIdentification: async (data: any) => {
        const response = await api
            .post('/identification/register', { ...data },
                { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editIdentification: async (id: number, data: any) => {
        const response = await api
            .patch(`/identification/update/${id}`, { ...data },
                { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    changeStatusIdentification: async (id: number, data: any) => {
        const response = await api
            .patch(`/identification/changeStatus/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    deleteIdentification: async (id: number) => {
        const response = await api
            .delete(`/identification/delete/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },


    updateOrCreate: async (data: any) => {
        const response = await api
            .post('/identification/updateOrCreate', data)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    changeStatus: async (id:number,data:any) => {
        const response = await api.patch(
        `/identification/changeStatus/${id}`,
        {
            ...data
        })
        .then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
}