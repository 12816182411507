import { RoutesMap } from "../common/routes";

export enum Role {
  SUPER_ADMIN = 'superadministrador',
  ADMIN = 'administrador',
  OWNER = 'propietario',
  FAMILY = 'invitado',
  TENANT = 'arrendatario',
  AGENT = 'agenteinmobiliario',
  // FAMILY = 'familiar'
}

export const ROUTES_FOR_ADMIN = [
  {
    module: RoutesMap.HOME,
    navigators: []
  },
]