import { Formik } from "formik";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, ListItemButton, TextField, Typography } from "@mui/material";
import { useDispatch } from "../../redux/store";
import { useState } from "react";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const BuildingModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, onList, clearState } = props;

    console.log('rowSelected', rowSelected.departments)
    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle de Edificio'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Información general</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.name}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>N° max departamentos</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.number_departments}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Dimensión</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.dimensions  + ' m²'} </Typography>
                        </ListItemButton>
                        <Divider />
                        
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Departamentos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            rowSelected && (rowSelected.departments).length > 0
                            ?
                            (rowSelected.departments).map((item)=>(
                                <>
                                <ListItemButton>
                                    <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{item.department_name}</Typography>
                                </ListItemButton>
                                <Divider />
                                </>
                            ))
                            :
                            <Typography sx={{ color: 'text.secondary' }}>No cuenta con departamentos afiliados</Typography>
                        }

                    </AccordionDetails>
                </Accordion>

            </ModalBody>
            
            <Divider />
            
            <ModalFooter 
                buttonType="submit"
                cancelText={"Cancelar"}
                onCancel={closeModal}
            />
        </Modal>
    )
}