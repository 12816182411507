import { useState } from "react"
import { Button, Divider, FormLabel, Grid, TextField } from "@mui/material"
import { Formik } from "formik"
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useAuthStore } from "../../../hooks"
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar"
import { Modal } from "../../../components/common/Modal/Modal"
import { ModalHeader } from "../../../components/common/Modal/ModalHeader"
import { ModalBody } from "../../../components/common/Modal/ModalBody"
import { ModalFooter } from "../../../components/common/Modal/ModalFooter"
import { useDocumentsStore } from "../../../hooks/useDocumentsStore"
import AddBoxIcon from '@mui/icons-material/AddBox';

type Values = {
    id: any,
    name: string
}

export const ModalCategory: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, listCategoriesAPI, setCategorySelected } = props

    const { user } = useAuthStore()

    const { documentRegister, registerCategory } = useDocumentsStore()

    const [ loading,  setLoading ] = useState<boolean>(false)

    const [data, setData] = useState<Values>({
        id: '',
        name: '',
    })

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        return errors;
    }

    const onSubmit = async(values) => {

        setLoading(true)
        const response: any = await registerCategory(values);
        console.log(response)
        if(!response){
            return false;
        }else{
            setCategorySelected && setCategorySelected(response)
            listCategoriesAPI && listCategoriesAPI();
            closeModal && closeModal();
        }
        setLoading(false)
    }

    return (
        <>
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalHeader
                text={'Crear nueva categoría'}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>

                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>Categoría:</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <TextField
                                    id="name"
                                    type="text" 
                                    name="name"
                                    fullWidth
                                    size="small"
                                    value={values.name}
                                    onChange={handleChange}
                                    error={errors.name && touched.name ? true : false}
                                    helperText={errors.name && touched.name ? errors.name : ''}
                                />
                            </Grid>
                        </Grid>

                        <Divider />
                        <ModalFooter 
                            confirmText={'Crear'}
                            onConfirm={handleSubmit}
                            loadingConfirmText={loading}
                            cancelText={"Cancelar"}
                            onCancel={closeModal}
                        />

                        </form>
                    )}}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    )
}