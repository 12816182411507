import { useEffect, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, TextField, List, ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText, FormLabel, Button, CircularProgress, IconButton } from '@mui/material'
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useContractStore } from "../../hooks/useContractStore";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { ModalAddVariable } from "./ModalAddVariable";
import WarningIcon from '@mui/icons-material/Warning';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import '../../assets/styles/pewen-app.css';

const columns = [
    { type: 'options', field: 'options', label: 'Opciones', align:'center' },
    { type: 'text', field: 'name', label: 'Nombre', align:'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const CreateVariablesView = () => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { status: statusContract, listTablesOfDatabase, registerVariableMassive } = useContractStore();

    const [tablesDB, setTablesDB] = useState<any>([]);
    const [tableSelected, setTableSelected] = useState<any>('');
    const [columnsSelected, setcolumnsSelected] = useState<any>([]);
    const [filter, setFilter] = useState<any>([]);
    const [textSearch, setTextSearch] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState<boolean>(false);
    const [showModalAdd, setShowModalAdd] = useState<boolean>(false);

    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    const [page, setPage] = useState<any>(0);

    useEffect(() => {
        listTablesOfDatabaseApi()
    },[search]);

    useEffect(() => {
        if (textSearch) {
           let copyFilters = []
           copyFilters = tablesDB.filter(table => (table?.name_table.toLowerCase().includes(textSearch.toLowerCase())));
           setFilter([...copyFilters])
        }
    }, [textSearch])

    const listTablesOfDatabaseApi = async() => {
        try {
            setLoading(true);
            const response = await listTablesOfDatabase();
            if(response){
                setTablesDB(response)
                setFilter(response)
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleKeyDown = (event) => {
        let texto = `${event.target.value}`;
        setTextSearch(texto);
        if (!texto) {
            setFilter([...tablesDB])
        }
    }

    const handleToggle = (value: any) => () => {
        const currentIndex = columnsSelected.indexOf(value);
        const newChecked = [...columnsSelected];
        
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        const uniqueKeys = {};
        const filterArray = newChecked.filter(item => { 
            if(!uniqueKeys[item.key]){
                uniqueKeys[item.key] = true;
                return true;
            }else{
                return false;
            }
        })

        console.log(filterArray)

        setcolumnsSelected(filterArray);
    };

    const handleChange = (e) => {
        let changedForm;
        let indice = 0;
        const regex = /^[A-Za-z\s]*$/;

        if(regex.test(e.target.value)){
            columnsSelected.map((item, i) => {
                if (item.id == e.target.name) {
                    changedForm = {
                        ...item,
                        name: e.target.value
                    }
                    indice = i
                }
            })
            columnsSelected.splice(indice, 1, changedForm)
            setcolumnsSelected([...columnsSelected]);
        }
    }

    const handleChangeRowsPerPage = (event)   =>{
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePage = (event, newPage)  => {
        setPage(newPage);
    };

    const handleDeleteProduct = (index: number) => {
        columnsSelected.splice(index, 1);
        setcolumnsSelected([...columnsSelected]);
    }

    const createVariables = async()  => {
        try {
            if(columnsSelected.length == 0) return CustomSnackbar('error', 'Seleccione al menos una variable.');

            let enabled = columnsSelected.find(col => col.name == '');
            if(enabled) return CustomSnackbar('error', 'Asegúrese de que ningun campo se encuentre vacío.');

            setLoadingSend(true);
            let formData:any = [];

            console.log(columnsSelected)

            columnsSelected.map((item) => {
                let obj:any = { name : `{{${item.key}}}`, description: (item.name).toUpperCase(), column: item.column, table: item.table }
                formData.push(obj);
            })

            const response = await registerVariableMassive({variables: formData});
            if(response){
                setcolumnsSelected([]);
                setTableSelected('');
            }

            setLoadingSend(false);
        } catch (error) {
            setLoadingSend(false);
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <CustomFormHeader
                    title={'Crear variables' }
                    // text={'Ingrese los datos del Propietario'}
                    goBack={RoutesMap.TEMPLATE_VARIABLES}
                >
                </CustomFormHeader>
            </Grid>

            <Grid item container xs={12} sx={{alignItems:'center', padding:'0px 10px 10px 20px', justifyContent: 'end'}}>
                <Button onClick={() => {setShowModalAdd(true)}} size="small" variant="contained" className="btn-confirm" >
                    Personalizado
                </Button>
            </Grid>

            <Grid item container xs={12} sx={{alignItems:'center', padding:'10px 10px 10px 20px', justifyContent:'space-between'}}>

                <Grid item container xs={12} sx={{alignItems:'center', border:'solid 1px #c4bfbf', borderRadius:'5px', justifyContent:'space-between', padding:'10px'}}>
                    <Grid item xs={12} md={6}>

                        <Grid item xs={12} direction="row" sx={{ display:"flex", alignItems:"center", marginBottom:'10px' }}>
                            <TextField
                                type="text"
                                id="mi-codigo-de-barras"
                                placeholder="Escribe para buscar"
                                onChange={handleKeyDown}
                                className="H-card__fcontainer__filter__input"
                                style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                                size='small'
                            />
                        </Grid>

                        <Grid item >
                            <TableContainer >
                                <Table sx={{ minWidth: 300}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody >
                                        <Grid sx={{minHeight:'40vh', maxHeight:'40vh', overflowY:'scroll'}}>
                                            {
                                                filter && filter.map((table, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        classes={{
                                                            hover: 'hover-row-t'
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row" onClick={()=>{setTableSelected(table)}} sx={{cursor:'pointer'}}>
                                                            {table.name_table}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </Grid>
                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                count= {filter.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                rowsPerPageOptions={[10, 15, 20]}
                                                labelRowsPerPage={<span className="tabla">Filas:</span>}
                                                labelDisplayedRows={({ page }) => {
                                                return `Página: ${page+1}`;
                                                }}
                                                SelectProps={{
                                                    inputProps: {
                                                    "aria-label": "page number"
                                                    }
                                                }}
                                                showFirstButton={true}
                                                showLastButton={true}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={5.5}>
                        {
                            tableSelected && (tableSelected.columns).length > 0 && (
                                <>
                                <Grid item xs={12} direction="row" sx={{ display:"flex", alignItems:"center", marginBottom:'10px', justifyContent: 'center'}}>
                                    <Typography>Seleccione un valor</Typography>
                                </Grid>
                                <Grid sx={{minHeight:'40vh', maxHeight:'40vh', overflowY:'scroll', backgroundColor:'#f3f3f3'}}>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: '#f3f3f3' }}>
                                        {
                                            (tableSelected.columns).map((item, key) => (
                                                <ListItem key={key}>
                                                    <ListItemButton onClick={handleToggle(item)} >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                            edge="start"
                                                            checked={columnsSelected.find(col => col.key == item.key) ? true : false}
                                                            // checked={columnsSelected.indexOf(item) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={item.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                </Grid>
                                </>
                            )
                        }
                    </Grid>
                </Grid>

            </Grid>

            {
                columnsSelected && columnsSelected.length > 0 && (
                    <>
                    <Grid item container xs={12} sx={{alignItems:'center', padding:'10px 10px 10px 20px', marginTop:'5px', justifyContent: 'end'}}>
                        <Button onClick={createVariables} size="small" variant="contained" className="btn-confirm" >
                            Crear variables
                            {
                                loadingSend && (
                                    <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                                )
                            }
                        </Button>
                    </Grid>
                    <Grid item container xs={12} sx={{display:'flex', alignItems:'center', padding:'10px 10px 10px 20px', marginTop:'5px'}}>
                        <WarningIcon sx={{color: '#fe9607de', fontSize:'20px', marginRight:'8px'}}/>
                        <Typography className="paragraph">
                            {'Considere cambiar el valor de la llave, buscando una relacion entre ambos.'}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} sx={{alignItems:'center', padding:'10px 10px 10px 20px'}}>

                        <Grid item container xs={12} sx={{alignItems:'center', border:'solid 1px #c4bfbf', borderRadius:'5px', padding:'10px', minHeight:'40vh', maxHeight:'40vh', overflowY:'scroll', }}>
                            {
                                columnsSelected.map((item, key) => (
                                    <>
                                    <Grid item container xs={12} spacing={2} key={item.id} sx={{alignItems:'center'}}>
                                        <Grid item xs={12} md={5}>
                                            <FormLabel>Llave:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text" 
                                                fullWidth
                                                size="small"
                                                value={item.key}
                                                name={item.id}
                                                inputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <FormLabel>Valor:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text" 
                                                fullWidth
                                                size="small"
                                                value={(item.name).toUpperCase()}
                                                name={item.id}
                                                onChange={(e) => {handleChange(e)}}
                                                placeholder="ingrese solo letras"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={1} sx={{paddingTop:'10px'}}>
                                            <IconButton size="small" color="inherit" onClick={()=>handleDeleteProduct(key)}>
                                              <DeleteOutlineIcon fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    </>
                                ))
                            }
                        </Grid>
                    </Grid>
                    </>
                )
            }

            {
                showModalAdd && (
                    <ModalAddVariable
                        open={showModalAdd}
                        closeModal={()=>{setShowModalAdd(false)}}
                    />
                )
            }
        </>
    )
}