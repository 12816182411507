import { Grid } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType";
import { useEffect, useState } from 'react';
import { useOwnerStore } from '../../hooks/useOwnerStore';
import { useNavigate } from 'react-router-dom';
import { Owner } from '../../types/slices/ownerType';
import { DepartamentByBuildingModal } from './DepartamentByBuildingModal';
import { useBuildingStore } from '../../hooks/useBuildingStore';
import { Building } from '../../types/slices/buildingType';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { ApiStatus } from '../../types/api/status';

const columns = [
  { type: 'options',  field: 'options', align:'center', label: 'Opciones' },
  { type: 'text', field: 'department_name', label: 'Nombre'},
  { type: 'detail',   field: 'detail',         align:'center',     label: '' }
];

export const DepartamentByBuilding = () => {

  const navigate = useNavigate();
  const { status: buildStatus, buildings, setSelectedBuilding, selectedBuilding, buildingByDepartment, getBuildingByDepartments, deleteBuildingByDepartment} = useBuildingStore()

  const { setSelectedOwnerAdd } = useOwnerStore();

  const [rowSelected, setRowSelected] = useState<any>('');
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)

  useEffect(() => {
    if (selectedBuilding?.id) {
      getBuildingByDepartmentsApi();
    } else {
      navigate({ pathname: RoutesMap.BUILDING }) 
    }
  }, []);

  const getBuildingByDepartmentsApi = async() => {
    try {
      setLoadingTable(true);
      const response = await getBuildingByDepartments(selectedBuilding?.id);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  }

  const clearState = () => {
    setSelectedBuilding({} as Building);
  }

  const onEdit = (rowSelected) => {
    setRowSelected(rowSelected);
  }
  
  const onCreate = () => {
    setShowModalCreate(true);
  }

  const onCloseModal = (onSubmit?: boolean) => {
    if (onSubmit) {
        getBuildingByDepartmentsApi();
    }
    setShowModalCreate(false);
    setSelectedOwnerAdd({} as Owner);
  }

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected)
    setShowModalConfirm(true);
  }

  const onDeleteConfirm = async() => {
    const response = await deleteBuildingByDepartment(rowSelected.id)
    if(response){
      setShowModalConfirm(false)
      getBuildingByDepartmentsApi()
    }else{
      return false;
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <CustomFormHeader
          title={selectedBuilding.id ? `Departamentos de ${selectedBuilding.name}` : '....'}
          goBack={RoutesMap.BUILDING}
          clearState={clearState}
        >
        </CustomFormHeader>

        <CustomTable 
          disabled_title
          columns={columns} 
          loading={loadingTable}
          rows={buildingByDepartment ||  []}
          onAddFn={onCreate}
          onRowClick={() => {}}
          hasOptions
          onDelete={onDelete}
        />
      </Grid>
      {
        showModalCreate && (
          <DepartamentByBuildingModal
            open={showModalCreate}
            closeModal={onCloseModal}
            row={selectedBuilding}
          />
        )
      }
      {
          showModalConfirm && (
              <ModalConfirm
                  open={showModalConfirm}
                  closeModal={()=>{setShowModalConfirm(false)}}
                  rowSelected={rowSelected}
                  onConfirm={onDeleteConfirm}
                  status2={buildStatus === ApiStatus.FETCHING ? true : false}
              />
          )
      }
    </>
  )
}