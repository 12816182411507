import { Divider, Grid } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { Formik } from "formik";
import { useState } from "react";
import { useContractStore } from "../../../hooks/useContractStore";
import { PIN } from "../../../components/common/PIN/PIN";
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar";
import { useUserStore } from "../../../hooks/UseUserStore";

type Values = {
    id: number,
    pin: number,
}

export const ModalPin: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, row, type, iddepartment, clearState, getContractAPI } = props;

    const { validatePIN } = useUserStore();

    const { status: statusContract, updateContractStatus } = useContractStore();

    const [data, setData] = useState<Values>({
        id: row ? row.id : '',
        pin: row ? row.pin : '',
    });

    const [numberspin, setnumberpin] = useState(['', '', '', '', '', '']);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const validateForm = (values) => {
        let errors: any = {};
        
        return errors;
      }

    const updateContractStatusAPI = async(formData) => {
        try {

            if(numberspin.length <= 0) return CustomSnackbar('warning', 'El PIN debe contener 6 dígitos.')

            const numbers = numberspin.join("");
            const regex = /^\d{6}$/;
            if(!regex.test(numbers)) return CustomSnackbar('warning', 'El PIN debe contener 6 dígitos.')

            setLoadingSubmit(true);
            const params: any = {
                type: type, 
                iddepartment: iddepartment,
                pin: numbers,
            };

            const validatepin = await validatePIN({pin: numbers});
            if(validatepin){
              const response = await updateContractStatus(params);
              if(response) {
                  closeModal && closeModal();
                  getContractAPI && getContractAPI();
                  setLoadingSubmit(false);
              };
            }else{
              setLoadingSubmit(false);
              return false;
            }

            setLoadingSubmit(false);
        } catch (error) {
            setLoadingSubmit(false);
        }
    }
  
    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="xs">
          <ModalHeader
            text={'INGRESE SU PIN'}
            className='positionElements'
            onCancel={closeModal}
            clearState={clearState}
          >
          </ModalHeader>
          <ModalBody>
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={updateContractStatusAPI}>
              {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid>
                        <PIN setnumberpin={setnumberpin} numberspin={numberspin}/>
                    </Grid>
                    <Divider />
                    <ModalFooter 
                        buttonType="submit"
                        cancelText={"Cancelar"}
                        onCancel={closeModal}
                        onConfirm={handleSubmit}
                        confirmText={"Confirmar"}
                        loadingConfirmText={loadingSubmit}
                        justify='center'
                    />
                  </form>
                )
              }} 
            </Formik>
          </ModalBody>
        </Modal>
      )
}