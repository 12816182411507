import { useEffect, useState } from "react"
import { Autocomplete, Button, Divider, FormLabel, Grid, TextField, Tooltip, Typography } from "@mui/material"
import { Formik } from "formik"
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useAuthStore } from "../../../hooks"
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar"
import { Modal } from "../../../components/common/Modal/Modal"
import { ModalHeader } from "../../../components/common/Modal/ModalHeader"
import { ModalBody } from "../../../components/common/Modal/ModalBody"
import { ModalFooter } from "../../../components/common/Modal/ModalFooter"
import { useDocumentsStore } from "../../../hooks/useDocumentsStore"
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ModalCategory } from "./ModalCategory"
import Checkbox from '@mui/material/Checkbox';
import { Role } from "../../../types/roles/roleTypes"
import { useLocation } from "react-router-dom"
import { RoutesMap } from "../../../types"

const FILE_MAX_SIZE = 10000000;

export const ModalUploadField: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, userSelected, getDocumentsApi } = props

    const { user } = useAuthStore()
    const { documentRegister, listCategories } = useDocumentsStore()

    const role = user?.userType;

    const { pathname } = useLocation()

    const [ nameFile, setnameFile ] = useState<string>('')
    const [ currentFile,  setCurrentFile ] = useState<any>(null)
    const [ categories,  setCategories ] = useState<any>([])
    const [ categorySelected,  setCategorySelected ] = useState<any>('')

    const [ loading,  setLoading ] = useState<boolean>(false)
    const [ loadingCategories,  setLoadingCategories ] = useState<boolean>(false)
    const [ modalCategory,  setModalCategory ] = useState<boolean>(false)

    const [data, setData] = useState<any>({
        name: '',
        extension: '',
        type: '',
        filePath: '',
        size: 0,
        sharedCompany: false,
    })

    useEffect(() => {
        listCategoriesAPI()
    },[])

    const listCategoriesAPI = async() => {
        setLoadingCategories(true);
            const response = await listCategories();
            if(!response){
                setLoadingCategories(false);
                setCategories([])
                return false;
            }else{
                setLoadingCategories(false);
                setCategories(response)
            }
        setLoadingCategories(false);
    }

    const setNameFile = (e) => {

        const files = e.target && e.target.files || [];
        const currentFile = files[0];

        if(currentFile){

            if(currentFile.size > FILE_MAX_SIZE){
                return CustomSnackbar("warning", "El archivo seleccionado excede el tamaño requerido")
            }

            const nameAsArray = currentFile.name.split(".") || [];
            const extension = nameAsArray.slice(-1);

            if(extension[0] == 'pdf' || extension[0] == 'docx'){

                setCurrentFile(currentFile)
                setnameFile(currentFile.name)

                const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
                const nameFile = nameWithoutExtension.join(".") || "";

                return nameFile;

            }else{
                CustomSnackbar("error", "Solo se aceptan archivos con extensión .pdf, .docx.");
                return '';
            }

        }

        return '';
    }

    const validateForm = (values) => {
        let errors: any = {};
        return errors;
    }

    const onSubmit = async(values) => {
        if(!currentFile) return CustomSnackbar("warning", "Seleccione un archivo.");
        if(!categorySelected) return CustomSnackbar ("warning", 'Seleccione una categoria.');

        setLoading(true)
        
        const formData = new FormData();
        const idcompany:any = user?.data?.idcompany || 1;

        if(currentFile){
            if(currentFile.size > FILE_MAX_SIZE){
                setLoading(false)
                return CustomSnackbar ("error", 'No se puede subir el archivo. Excede el tamaño requerido');
            }

            const nameAsArray = currentFile.name.split(".") || [];
            const extension = nameAsArray[nameAsArray.length-1];
            
            formData.append("type", currentFile.type);
            formData.append("filePath", currentFile);
            formData.append("size", currentFile.size); //bytes
            formData.append("extension", extension);
        }

        formData.append("name", values.name);
        formData.append("idcompany", idcompany);
        formData.append("idcategory", categorySelected.id);
        formData.append("sharedCompany", values.sharedCompany);
        if(userSelected) formData.append("rut_user", userSelected.rut);

        const response: any = await documentRegister(formData);

        if(response){
            setLoading(false)
            getDocumentsApi && getDocumentsApi();
            closeModal && closeModal();
        }else{
            setLoading(false)
            return false;
        }

        setLoading(false)
    }

    const showDocument = (image) => {
        if(image){
            window.open(image, '_blank');
        }
    }

    return (
        <>
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalHeader
                text={'Subir archivo'}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>

                        {
                            role == Role.ADMIN ? (
                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <FormLabel>Categoría:</FormLabel>
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={7} lg={7} xl={7}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-category"
                                            value={categorySelected}
                                            options={categories || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(e, newValue) => {
                                                setCategorySelected(newValue);
                                            }}
                                            loading={loadingCategories}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ display:'flex', justifyContent: 'end'}}>
                                        <Tooltip title={'crear nueva categoria'}>
                                        <AddBoxIcon onClick={()=>{setModalCategory(true)}} sx={{backgroundColor:'#fff', color:'#3aff3a', fontSize:'30px', cursor:'pointer'}}/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <FormLabel>Categoría:</FormLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-category"
                                            value={categorySelected}
                                            options={categories || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(e, newValue) => {
                                                setCategorySelected(newValue);
                                            }}
                                            loading={loadingCategories}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }

                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>Seleccionar Archivo:</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Button 
                                    variant="contained"
                                    component="label"
                                    fullWidth
                                    // style={{ maxWidth: '100%', width: '100%' }}
                                    sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#212D39', "&:hover": {backgroundColor: "#212D39" } }}        
                                >
                                    <FileUploadIcon fontSize="small" />
                                        {nameFile ?  nameFile : ''}
                                    <input
                                        id='filePath'
                                        type='file'
                                        name='filePath'
                                        style={{ display: 'none' }}
                                        onChange={(event) => {
                                            setFieldValue("name", setNameFile(event))
                                        }}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>Nombre del Archivo:</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <TextField
                                    id="name"
                                    type="text" 
                                    name="name"
                                    fullWidth
                                    size="small"
                                    value={values.name}
                                    onChange={handleChange}
                                    // error={errors.name && touched.name ? true : false}
                                    // helperText={errors.name && touched.name ? errors.name : ''}
                                />
                            </Grid>
                        </Grid>

                        {
                            role == Role.ADMIN && pathname == RoutesMap.MY_DOCUMENTS && (
                                <Grid item xs={12} container>
                                    <Grid item xs={4}>
                                        <FormLabel>Compartir con la Empresa:</FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                    <Checkbox
                                        checked={values.sharedCompany}
                                        onChange={() => { setFieldValue("sharedCompany", !values.sharedCompany) }}
                                    />
                                    </Grid>
                                </Grid>
                            )
                        }

                        <Divider />
                        <ModalFooter 
                            confirmText={'Subir'}
                            onConfirm={handleSubmit}
                            loadingConfirmText={loading}
                            cancelText={"Cancelar"}
                            onCancel={closeModal}
                        />

                        </form>
                    )}}
                </Formik>
            </ModalBody>
        </Modal>
        {
            modalCategory && (
                <ModalCategory
                    open={modalCategory}
                    closeModal={()=>{setModalCategory(false)}}
                    listCategoriesAPI={listCategoriesAPI}
                    setCategorySelected={setCategorySelected}
                />
            )
        }
        </>
    )
}