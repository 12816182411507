import { api } from "./configs/axiosConfigs"

export const AgentAPI = {
    getEstateAgent: async (data?: any, idcompany?: number) => {
        const response = await api.get(
            '/estateAgent/list',
            {
                params: {
                    ...data,
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    createEstateAgent: async (data: any, idcompany?: number) => {
        const response = await api.post(
            '/estateAgent/register',
            {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    findAgent: async (idagent) => {
        const response = await api.get(
            `/estateAgent/find/${idagent}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    editEstateAgent: async (idagent: number, data: any) => {
      const response = await api.patch(
      `/estateAgent/update/${idagent}`,
      {
          ...data,
      }
      ).then(response => response.data)
      .catch((error) => {
          return error.response.data
      })
      return response
    },
    deleteEstateAgent: async (idagent: number, idcompany?: number) => {
        const response = await api.delete(
        `/estateAgent/delete/${idagent}`,
        {
            params: {
                idcompany: idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    // findOwner: async (idowner) => {
    //     const response = await api.get(
    //         `/owner/find/${idowner}`
    //     ).then(response => response.data)
    //     .catch((error) => {
    //         console.error(error)
    //         return error.response.data
    //     })
    //     return response
    // },
    registerDepartmentAgent: async (data: any, idcompany?: number) => {
        const response = await api.post(
            '/departmentAgent/register', {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    
    getAgentByDepartment: async (id?: number) => {
      const response = await api.get(
        `/departmentAgent/agentByDepartment/${id}`
      ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
      return response
    },
    
    deleteAgentDepartment: async (id?: number) => {
      const response = await api.delete(
        `/departmentAgent/delete/${id}`
      ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
      return response
    },
}