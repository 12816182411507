import { Autocomplete, Button, Card, CardContent, Divider, Grid, TextField, Typography, CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from 'react';
import { useContractStore } from '../../hooks/useContractStore';
import { readLocalStorage } from "../../toolbox/helpers/local-storage-helpers";
import { LocalStorageKey, RoutesMap } from "../../types";
import SearchIcon from '@mui/icons-material/Search';
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { ModalPin } from "./Components/ModalPin";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../hooks/UseUserStore";
import '../../assets/styles/pewen-app.css';

export const MyContract = () => {

    const navigate = useNavigate();

    const { status: statusContract, contract, getContract, getContractOwner, resetGetContract,validateContract } = useContractStore();
    const { user } = useAuthStore()
    const { findPIN } = useUserStore();
    const idowner:any = user?.data?.id
    const role:any = user?.userType

    const [withPIN, setWithPin] = useState<boolean>(false);

    const [variables,setVaribales] = useState<any>([])
    const [documentClausules, setDocumentClausules] = useState<any>([])
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModalPin, setShowModalPin] = useState<boolean>(false);
    const [initialRender, setInitialRender] = useState<boolean>(true);

    const [arrayBuildings, setArrayBuildings] = useState<any>([])
    const [selectedBuilding, setSelectedBuilding] = useState<any>('')
    const [arrayDepartments, setArrayDepartments] = useState<any>([])
    const [selectedDepartment, setSelectedDepartment] = useState<any>('')
    const [confirmStatusContract, setConfirmStatusContract] = useState<any>('0')
    const [urlDoc, setUrlDoc] = useState<any>('')

    // useEffect(() => {
    //     if(confirmStatusContract == '0' && !withPIN){
    //         navigate({ pathname: RoutesMap.MY_PROFILE}, { replace: true })
    //     }
    // }, [contract && Object.entries(contract).length > 0])

    useEffect(() => {
        findPINAPI();
        resetGetContract();
        getBuildingsAPI();
    }, [])

    useEffect(()=>{
        if(contract){
          setFormated(contract?.variables)
          setDocumentClausules(contract?.detailContract)
        }
    },[contract])
    
    useEffect(()=>{
        if(variables.length > 0){
            setDocumentClausules(EstructurarData(documentClausules, true))
        }
    },[variables])
    
    useEffect(()=>{
        if(selectedBuilding && initialRender){
          const department = getDepartmentsAPI(selectedBuilding, true)
          if (department.length > 0) {
            setSelectedDepartment(department[0])
          } 
        }
    },[selectedBuilding])
    
    useEffect(()=>{
        if(selectedDepartment && initialRender){
          getContractAPI() 
        }
    },[selectedDepartment])

    const findPINAPI = async () => {
        const res = await findPIN();
        if(res){
            setWithPin(true)
        }else{
            if(confirmStatusContract == '0'){
                navigate({ pathname: RoutesMap.MY_PROFILE}, { replace: true })
            }
            setWithPin(false)
        }
    }

    const getContractAPI = async () => {
        try {
            setLoading(true);
            const params: any = {
                type: role == Role.TENANT ? '2' : (role == Role.OWNER ? '1' : '3'), 
                iddepartment: selectedDepartment?.id,
                idbuilding: selectedBuilding?.id,
                idnewowner: idowner,
                start_date: null,
                end_date: null
            };
            const validation = await validateContract(params);
            if(validation) {
                setConfirmStatusContract(validation.confirmStatus || '')
                setUrlDoc(validation.url_doc || '')
            };
            if(validation && validation.createdBY == Role.ADMIN){
                const response = await getContract(params);
                setLoading(false);
            }else{
                const response = await getContractOwner(params);
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const getBuildingsAPI = () => {
        setSelectedBuilding('')
        setSelectedDepartment('')
        const data = readLocalStorage(LocalStorageKey.BUILDING_OWNER);
        const building:any = [];
        data.map((item: any) => {
          building.push({
            id: item.idbuilding,
            name: item.name_building
          })
        });
        setArrayBuildings(building);
        if (building.length > 0) setSelectedBuilding(building[0])
    }

    const getDepartmentsAPI = (getBuilding: any, returnValue: boolean = false) => {
        const data = readLocalStorage(LocalStorageKey.BUILDING_OWNER);
        const building = data.find((item) => item.idbuilding === getBuilding.id);
        setArrayDepartments(building.departments);
        if (returnValue) return building.departments;
    }

    const setFormated = (data:[]) => {
        const objectsArray:any = [];
        for (const key in data) {
            objectsArray.push({key:key, value: data[key] });
            // objectsArray.push({key:`{{${key}}}`, value: data[key] });
        }
        setVaribales(objectsArray)
    }
    
    const VariableToText = (textVariable) => {
        let newText = textVariable
        variables && variables.length>0 && variables.map((item) => {
            newText = newText.replace(item.key, item.value)
        })
        return newText
    }
    
    const TextToVariable = (text) => {
        let textChange = text;
        variables && variables.length>0 && variables.map((item) => {
            textChange = textChange.replace(item.value, item.key)
        })
        return textChange
    }
    
    const EstructurarData = (documentData, toText) => {
        const newArrayEstructura:any = [];
        documentData.map((item, i) => {
            const objNew = {
            id: item.id,
            clausule: toText ? VariableToText(item.clausule) : TextToVariable(item.clausule),
            position: i,
            title: toText ? VariableToText(item.title) : TextToVariable(item.title),
            status: item.status
            }
            newArrayEstructura.push(objNew);
        })

        return newArrayEstructura
    }

    // const updateContractStatusAPI = async() => {
    //     try {
    //         setLoadingSubmit(true);
    //         const params: any = {
    //             type: role == Role.TENANT ? '2' : (role == Role.OWNER ? '1' : '3'), 
    //             iddepartment: selectedDepartment?.id,
    //         };
    //         const response = await updateContractStatus(params);
    //         if(response) getContractAPI();
    //         setLoadingSubmit(false);
    //     } catch (error) {
    //         setLoadingSubmit(false);
    //     }
    // }

    const donwloadContract = async() => {
        try {
            setLoadingSubmit(true);
            window.open(`${process.env.REACT_APP_ROOT_URL}/` + urlDoc, '_blank')
            setLoadingSubmit(false);
        } catch (error) {
            setLoadingSubmit(false);
        }
    }

    const redirectMyProfile = () => {
        navigate({ pathname: RoutesMap.MY_PROFILE}, { replace: true })
    }

    return (
        <>
        <Grid item xs={12}>
            <Grid item container xs={12}>
                <Grid item container xs={12} direction="row" sx={{ marginBottom:"10px", display:"flex", alignItems:"center" }}>
                    <Autocomplete
                    id="combo-box-building"
                    size="small"
                    value={selectedBuilding}
                    options={arrayBuildings || []}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    sx={{width:'300px', padding: '8px'}}
                    renderInput={(params) => <TextField {...params} label="Edificios" size="small" />}
                    onChange={(e, newValue) => {
                        setSelectedBuilding(newValue)
                        newValue?.id && getDepartmentsAPI(newValue);
                        setInitialRender(false)
                    }}
                    />
                    <Autocomplete
                    id="combo-box-department"
                    size="small"
                    value={selectedDepartment}
                    options={arrayDepartments || []}
                    disabled={!selectedBuilding?.id}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    sx={{width:'300px', padding: '8px'}}
                    renderInput={(params) => <TextField {...params} label="Departamento" size="small" />}
                    onChange={(e, newValue) => {
                        setSelectedDepartment(newValue)
                        setInitialRender(false)
                    }}
                    />
                    <Button
                        onClick={ () => getContractAPI() }
                        startIcon={<SearchIcon/>} 
                        sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none',height: '40px' , fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}
                        // className="btn-secondary"
                        >
                        ver contrato
                        {
                            loading && (
                            <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                            )
                        }
                    </Button>
                </Grid>
                <Grid xs={12}>
                    <Typography sx={{ fontSize:18, fontWeight:600}}>Mi contrato</Typography>
                </Grid>
                <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center' sx={{marginBottom:'5px'}}>
                    {
                        confirmStatusContract == '0' && (
                            <Grid xs={7}>
                                <Typography className="text-info" >{'(*) Lee atentamente el documento y confirme su contrato.'}</Typography>
                                {
                                    (!loading && contract && Object.entries(contract).length > 0 && confirmStatusContract == '0' && !withPIN)  && (
                                        <div>
                                            <Typography className="text-info-red" >{'(*) No cuenta con un PIN. Cree uno para confirmar su contrato.'}</Typography>
                                        </div>
                                    )
                                }
                            </Grid>
                        )
                    }
                    {
                        confirmStatusContract == '1' && (
                            <Grid xs={7}>
                                <Typography className="text-info" >{'(*) Su contrato ya ha sido aprobado.'}</Typography>
                            </Grid>
                        )
                    }
                    {
                        (!loading && contract && Object.entries(contract).length > 0 && confirmStatusContract == '0' && withPIN) && (
                            <Grid xs={4} sx={{ width: '100%', textAlign:'end'}}>
                                <Button onClick={()=>{setShowModalPin(true)}} variant="contained" className='btn-confirm'>
                                    Firmar contrato
                                    {
                                        loadingSubmit && (
                                            <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                                        )
                                    }
                                </Button>
                            </Grid>
                        )
                    }
                    {
                        (!loading && contract && Object.entries(contract).length > 0 && confirmStatusContract == '0' && !withPIN) && (
                            <Grid xs={4} sx={{ width: '100%', textAlign:'end'}}>
                                <Button onClick={redirectMyProfile} variant="contained" className='btn-confirm' >
                                    Crear PIN
                                </Button>
                            </Grid>
                        )
                    }
                    {
                    (!loading && contract && Object.entries(contract).length > 0 && confirmStatusContract == '1') && (
                        <Grid xs={4} sx={{ width: '100%', textAlign: 'end' }}>
                            <Button onClick={donwloadContract} variant="contained" className='btn-confirm'>
                                Descargar contrato
                                {
                                    loadingSubmit && (
                                        <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                                    )
                                }
                            </Button>
                        </Grid>
                    )
                }
                </Grid>
            </Grid>
            <Divider />
            <Card sx={{ padding: '10px', marginTop: '20px' }}>
                <CardContent>
                    {
                        loading && (
                        <>
                            <Skeleton variant="rectangular" width="100%" height={'60vh'} />
                        </>
                        )
                    }
                    { (!loading && contract && Object.entries(contract).length > 0) && (
                        <>
                            <Grid xs={12} sx={{textAlign:'center'}}>
                            <Typography align='center' style={{ fontWeight: 'bold', fontSize: 13 }}><b>{contract?.contract?.title}</b></Typography>
                            </Grid>
                            <Grid xs={12} sx={{textAlign:'center'}}>
                            <Typography align='center' style={{ fontWeight: 'bold', fontSize: 12}}><span dangerouslySetInnerHTML={{ __html: VariableToText(contract?.contract?.description.replace(/\n/g, '<br />')) }} /></Typography>
                            </Grid>

                            <Grid sx={{ overflowY: 'scroll', height: '50vh', my: 2 }} container>
                            {
                                documentClausules.length > 0 &&  documentClausules.map((item, key)=>(
                                <>
                                    <Grid xs={12} sx={{ mb: 2 }} key={key}>
                                    {
                                        item.title != '' && (
                                        <Grid>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 12}}>
                                                <span dangerouslySetInnerHTML={{ __html: VariableToText(item.title.replace(/\n/g, '<br />')) }} />
                                            </Typography>
                                        </Grid>
                                        )
                                    }
                                    {
                                        item.clausule != '' && (
                                        <Grid>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 12}}>
                                                <span dangerouslySetInnerHTML={{ __html: VariableToText(item.clausule.replace(/\n/g, '<br />')) }} />
                                            </Typography>
                                        </Grid>
                                        )
                                    }
                                    
                                </Grid>
                                </>
                                ))
                            }
                            </Grid>
                        </>
                        )
                    }
                </CardContent>
            </Card>
        </Grid>
        {
            showModalPin && (
                <ModalPin
                    open={showModalPin}
                    closeModal={()=>{setShowModalPin(false)}}
                    type={role == Role.TENANT ? '2' : (role == Role.OWNER ? '1' : '3')}
                    iddepartment={selectedDepartment?.id}
                    getContractAPI={getContractAPI}
                />
            )
        }
        </>
    );
}