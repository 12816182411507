import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useContractStore } from "../../hooks/useContractStore";

const columns = [
    { type: 'options',  align:'center',  field: 'options', label: 'OPCIONES' },
    { type: 'text',     align: 'center', field:'contract', label: 'NOMBRE', format:(row) => row.contract ? row.contract.title : '-----'},
    { type: 'detail',   align:'center',  field: 'detail', label: '' },
]

export const ListContractTemplatesView = () => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);
  
    const { status: statusContract, selectedContract, listContractTemplates, setActionContract, setSelectContract } = useContractStore();

    const [ rowSelected, setRowSelected ] = useState<any>('');

    const [ contractTemplates, setContractTemplates ] = useState<any>([])
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    useEffect(() => {
        setSelectContract({} as any)
        listContractTemplatesApi()
    },[search])

    const listContractTemplatesApi = async() => {
        try {
          setLoading(true);
          const response = await listContractTemplates();
          if(response){
            setContractTemplates(response)
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionContract('add')
        navigate({ pathname: RoutesMap.CREATE_CONTRACT })
    }

    const onEdit = (rowSelected) => {
        setActionContract('edit')
        setRowSelected(rowSelected)
        setSelectContract(rowSelected)

        navigate({ pathname: RoutesMap.EDIT_CONTRACT_TEMPLATE })
    }

    return (
        <>

            <Grid item xs={12}>
                <CustomTable 
                    title={'Plantillas de contrato'}
                    columns={columns}
                    loading={loading}
                    rows={contractTemplates || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onEdit={onEdit}
                />
            </Grid>
        </>
    )
}