import { useState } from "react"
import { Button, Divider, FormLabel, Grid, CircularProgress, Typography, TextField } from "@mui/material"
import { Formik } from "formik"
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useAuthStore } from "../../../hooks"
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar"
import { Modal } from "../../../components/common/Modal/Modal"
import { ModalHeader } from "../../../components/common/Modal/ModalHeader"
import { ModalBody } from "../../../components/common/Modal/ModalBody"
import { ModalFooter } from "../../../components/common/Modal/ModalFooter"
import { useDocumentsStore } from "../../../hooks/useDocumentsStore"

const FILE_MAX_SIZE = 10000000;

export const ModalReplaceField: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, userSelected, field, getDocumentsApi } = props

    const { user } = useAuthStore()
    const { documents, historyDocuments, selectedDocument, listByUser, documentReplace, setActionDocument} = useDocumentsStore()

    const dataUser:any = user?.data || ''
    const role:any = user?.userType || ''

    const [ nameFile, setnameFile ] = useState<string>('')
    const [ currentFile,  setCurrentFile ] = useState<any>(null)

    const [ loading,  setLoading ] = useState<boolean>(false)

    const [data, setData] = useState<any>({
        name: field ? field.namedocument : '',
        extension: '',
        type: '',
        filePath: '',
        size: 0,
    })

    const setNameFile = (e) => {

        const files = e.target && e.target.files || [];
        const currentFile = files[0];

        if(currentFile){

            if(currentFile.size > FILE_MAX_SIZE){
                return CustomSnackbar("warning", "El archivo seleccionado excede el tamaño requerido")
            }

            const nameAsArray = currentFile.name.split(".") || [];
            const extension = nameAsArray.slice(-1);

            if(extension[0] == 'pdf' || extension[0] == 'docx'){

                setCurrentFile(currentFile)
                setnameFile(currentFile.name)

                const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
                const nameFile = nameWithoutExtension.join(".") || "";

                return nameFile;

            }else{
                CustomSnackbar("error", "Solo se aceptan archivos con extensión .pdf, .docx.");
                return '';
            }

        }

        return '';
    }

    const validateForm = (values) => {
        let errors: any = {};
        return errors;
    }

    const onSubmit = async(values) => {
        if(!currentFile) return CustomSnackbar("warning", "Seleccione un archivo.")

        setLoading(true)
        
        const formData = new FormData();
        const idcompany:any = user?.data?.idcompany || 1;

        if(currentFile){
            if(currentFile.size > FILE_MAX_SIZE){
                return CustomSnackbar ("error", 'No se puede subir el archivo. Excede el tamaño requerido');
            }

            const nameAsArray = currentFile.name.split(".") || [];
            const extension = nameAsArray[nameAsArray.length-1];
            
            formData.append("iddocument", field.iddocument);
            formData.append("iddetail", field.id);

            formData.append("type", currentFile.type);
            formData.append("filePath", currentFile);
            formData.append("size", currentFile.size); //bytes
            formData.append("extension", extension);
        }

        formData.append("name", values.name);
        formData.append("idcompany", idcompany);
        if(userSelected) formData.append("rut_user", userSelected.rut);

        const response: any = await documentReplace(formData);

        if(response){
            getDocumentsApi && getDocumentsApi();
            closeModal && closeModal();
        }else{
            return false;
        }

        setLoading(false)
    }

    const showDocument = (image) => {
        if(image){
            window.open(image, '_blank');
        }
    }

    return (
        <>
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalHeader
                text={'Reemplazar Archivo'}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>

                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>Seleccionar Archivo:</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Button 
                                    variant="contained"
                                    component="label"
                                    fullWidth
                                    // style={{ maxWidth: '100%', width: '100%' }}
                                    sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#212D39', "&:hover": {backgroundColor: "#212D39" } }}        
                                >
                                    <FileUploadIcon fontSize="small" />
                                        {nameFile ?  nameFile : ''}
                                    <input
                                        id='filePath'
                                        type='file'
                                        name='filePath'
                                        style={{ display: 'none' }}
                                        onChange={(event) => { setNameFile(event) }}
                                    />
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <FormLabel>Nombre:</FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <TextField
                                    id="name"
                                    type="text" 
                                    name="name"
                                    fullWidth
                                    size="small"
                                    value={values.name}
                                    onChange={handleChange}
                                    inputProps={{ readOnly: true }}
                                    // error={errors.name && touched.name ? true : false}
                                    // helperText={errors.name && touched.name ? errors.name : ''}
                                />
                            </Grid>
                        </Grid>

                        <Divider />
                        <ModalFooter 
                            confirmText={'Reemplazar'}
                            onConfirm={handleSubmit}
                            loadingConfirmText={loading}
                            cancelText={"Cancelar"}
                            onCancel={closeModal}
                        />

                        </form>
                    )}}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    )
}