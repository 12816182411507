import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { Grid, Divider, TextField, Autocomplete, Typography, Select, MenuItem } from "@mui/material"
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { Owner } from "../../types/slices/ownerType";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useContractStore } from "../../hooks/useContractStore";

type Values = {
  start_date: string,
  end_date: string,
  description: string,
}

export const RenewContractModal: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, row } = props;

  console.log(row)

  const { departmentByOwner, addDepartmentOwner } = useDepartmentStore();
  const { status: statusContract, renewContract, deleteContract} = useContractStore();

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [typeRegister, setTypeRegister] = useState<any>(1);

  const [data, setData] = useState<Values>({
    start_date: "",
    end_date: "",
    description: "",
  });

  const validateForm = (values) => {
    let errors: any = {};
    if(!values.idnewowner) errors.idnewowner = "Campo requerido";
    
    return errors;
  }

  const onSubmit = async (formData) => {
    setLoadingSubmit(true); 

    const ownerdepartment = (row?.owners && (row.owners).length > 0) ? row.owners.find(owner => owner.status == '1') : '';

    const data = {
      ...formData,
      idcontract: row?.idcontract
    }
    const response:any = await renewContract(data);
    setLoadingSubmit(false);
    
    if (response?.status){
        closeModal && closeModal();
    }else{
        return false;
    };
    
  }

  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={"Renovar Contrato"}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({values, errors, touched, handleSubmit, setFieldValue, handleChange}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Descripción"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="description"
                      type="text" 
                      name="description"
                      fullWidth
                      size="small"
                      value={values.description}
                      onChange={handleChange}
                      error={errors.description && touched.description ? true : false}
                      helperText={errors.description && touched.description ? errors.description : ''}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Fecha inicial"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="start_date"
                      type="date" 
                      name="start_date"
                      fullWidth
                      size="small"
                      value={values.start_date}
                      onChange={(e)=>{handleChange(e)}}
                      error={errors.start_date && touched.start_date ? true : false}
                      helperText={errors.start_date && touched.start_date ? errors.start_date : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Fecha final"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="end_date"
                      type="date" 
                      name="end_date"
                      fullWidth
                      size="small"
                      value={values.end_date}
                      onChange={(e)=>{handleChange(e)}}
                      error={errors.end_date && touched.end_date ? true : false}
                      helperText={errors.end_date && touched.end_date ? errors.end_date : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                {
                  typeRegister == 2 && (
                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>{"Fecha final"}:</b></Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <TextField
                          id="end_date"
                          type="date" 
                          name="end_date"
                          fullWidth
                          size="small"
                          value={values.end_date}
                          onChange={(e)=>{handleChange(e)}}
                          error={errors.end_date && touched.end_date ? true : false}
                          helperText={errors.end_date && touched.end_date ? errors.end_date : ''}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )
                }
                <Divider />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={'Guardar'}
                    onConfirm={handleSubmit}
                    loadingConfirmText={loadingSubmit}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                  />
                </Grid>
              </form>
            )
          }} 
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}