import { useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import CustomTable from '../../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../../types";
import { useNavigate } from "react-router-dom";
import { Owner } from "../../../types/slices/ownerType";
import { useOwnerStore } from "../../../hooks/useOwnerStore";
import { useSelector } from "../../../redux/store";
import { selectHeaderState } from "../../../redux/slices/headerSlice";
import { useTenantStore } from "../../../hooks/useTenantStore";
import DescriptionIcon from '@mui/icons-material/Description';
import { useAgentStore } from "../../../hooks/useAgentStore";

const columns = [
    { type: 'text',     field: 'name', align:'center', label: 'Nombre' },
    { type: 'text',     field: 'rut', align:'center', label: 'Rut' },
    { type: 'text',     field: 'email', align:'center', label: 'Correo' },
    { type: 'text',     field: 'phone', align:'center', label: 'Celular' },
    { type: 'detail',   field: 'detail',  align:'center', label: '' },
];

export const ListUserByTypeView = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { status: statusOwnerApi, owners, getOwners, setSelectedOwner, resetOwners } = useOwnerStore()
    const { status: statusTenantApi, tenants, getTenant, setSelectTenant, resetTenants } = useTenantStore()
    const { status: statusAgentApi, agents, getAgents, setSelectAgent, resetAgents } = useAgentStore();

    const [ rows, setRows ] = useState<Owner[]>([])
    const [selectedTypeUser, setSelectedTypeUser] = useState<any>('3');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedOwner({} as Owner)
        setSelectTenant({} as any)
        setSelectAgent({} as any)
        setSelectedTypeUser('3')
        onChangeSelect('3')
    },[])

    useEffect(() => {
        if (selectedTypeUser == '3' && owners.length > 0) {
          setRows(owners)
          return
        }
        if (selectedTypeUser == '5' && tenants.length > 0) {
            setRows(tenants)
            return
        }
        if (selectedTypeUser == '6' && agents.length > 0) {
            setRows(agents)
            return
        }
        setRows([])
        console.log(selectedTypeUser)

    }, [owners.length > 0 || tenants.length > 0 || agents.length > 0 ])

    const onChangeSelect = async(type) => {
        setSelectedTypeUser(type);
        try {
            switch (type) {
                case '3':
                    resetTenants();
                    resetAgents();
                    getOwnersApi();
                    break;
                case '5':
                    resetOwners();
                    resetAgents();
                    getTenantsApi();
                    break;
                case '6':
                    resetOwners();
                    resetTenants();
                    getAgentsApi();
                    break;
                default:
                    break;
            }
          } catch (error) {
            setRows([]);
            return false;
        }
    }

    const getOwnersApi = async() => {
        setSelectTenant({} as any)
        setSelectAgent({} as any)
        try {
          setLoading(true);
          const response = await getOwners({search, privilege: 1});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const getTenantsApi = async() => {
        setSelectedOwner({} as Owner)
        setSelectAgent({} as any)
        try {
          setLoading(true);
          const response = await getTenant({search, privilege: 2});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const getAgentsApi = async() => {
        setSelectedOwner({} as Owner)
        setSelectTenant({} as any)
        try {
          setLoading(true);
          const response = await getAgents({search});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onHelper = (rowSelected) => {
        setSelectedOwner(rowSelected)
        navigate({ pathname: RoutesMap.DOCUMENTS_BY_USER })
    }

    return (
        <>
            {/* { statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> } */}

            <Grid item xs={12}>
                <Grid item container xs={12} direction="row" sx={{ marginBottom:"10px", display:"flex", alignItems:"center" }}>
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }}>tipos de usuario</InputLabel>
                    <Select
                        id="combo-box-department"
                        size="small"
                        value={selectedTypeUser}
                        onChange={(event) => {onChangeSelect(event.target.value)}}
                        sx={{width:'240px', fontSize:"12px"}}
                        label='tipos de usuario'
                    >
                        <MenuItem value={'3'}>{"Propietarios"}</MenuItem>
                        <MenuItem value={'5'}>{"Arrendatarios"}</MenuItem>
                        <MenuItem value={'6'}>{"Corredores"}</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
                <CustomTable 
                    // title={'Lista de usuarios'}
                    columns={columns} 
                    loading={loading}
                    rows={rows || []}
                    onRowClick={() => {}}
                    onHelper={onHelper}
                    icon_helper={<DescriptionIcon fontSize="small" />}
                    tooltip_helper={'ver docuemtnos'}
                />
            </Grid>
        </>
    )
}