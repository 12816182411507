import { api } from './configs/axiosConfigs'

export const VisitApi = {

    getVisitsByDepartment: async (iddepartment:number, params:any) => {

        const response = await api.get(
        `/visitor/list/${iddepartment}`,
        {
            params: params,
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    createVisit: async (data: any) => {
        const response = await api.post(
            '/visitor/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    validateVisit: async (data: any) => {
        const response = await api.post(
            '/visitor/validatevisit', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    confirmVisit: async (idvisit: number) => {
        const response = await api.patch(
        `/visitor/confirmvisit/${idvisit}`,
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    editVisit: async (idvisit: number, data: any) => {
        const response = await api.patch(
        `/visitor/update/${idvisit}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    deleteVisit: async (idvisit: number) => {
        const response = await api.delete(
        `/visitor/delete/${idvisit}`,
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

}