import { useDispatch, useSelector } from "react-redux"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { changeStatus, onFetchAgents, onFetchAgentsByDepartment, onSetSelectedAgent, selectAgentState, setAction, setagents } from "../redux/slices/agentSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { AgentAPI } from "../apis/AgentApi";

export const useAgentStore = () => {
  const dispatch = useDispatch();
  const { status, agents, agentsDistincs, selectedAgent, action, agentsByDepartment } = useSelector(selectAgentState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getAgents = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await AgentAPI.getEstateAgent(data, idcompany)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      dispatch(onFetchAgents(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
    }
  }

  const createAgent = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await AgentAPI.createEstateAgent(data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const findAgent = async (idowner) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await AgentAPI.findAgent(idowner)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        const { detail } = response.data
        dispatch(changeStatus(ApiStatus.FETCHED))
        return detail
    } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
    }
  }

  const editAgent = async (id:number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await AgentAPI.editEstateAgent(id, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteAgent = async (id:number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await AgentAPI.deleteEstateAgent(id, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setSelectAgent = async (data: any) => {
    try {
      dispatch(onSetSelectedAgent(data))
    } catch (error) {
        console.log(error)
    }
  }

  const getAgentsByDepartment = async (iddepartment: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await AgentAPI.getAgentByDepartment(iddepartment)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      dispatch(onFetchAgentsByDepartment(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
    }
  }

  const createAgentDepartment = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await AgentAPI.registerDepartmentAgent(data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteAgentDepartment = async (id:number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await AgentAPI.deleteAgentDepartment(id)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setActionAgent = async (state: any) => {
    try {
      dispatch(setAction(state))
    } catch (error) {
      console.log(error)
    }
  }

  const resetAgents = () => {
    try {
        dispatch(setagents());
    } catch (error) {
        console.error(error);
    }
  }

  return {
    status,
    agents,
    agentsDistincs,
    selectedAgent,
    agentsByDepartment,
    action,

    getAgents,
    createAgent,
    editAgent,
    deleteAgent,
    findAgent,
    getAgentsByDepartment,
    createAgentDepartment,
    deleteAgentDepartment,
    setSelectAgent,
    setActionAgent,
    resetAgents,
  }
}