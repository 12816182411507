import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../types/api/status"
import { RootState } from "../store"

const initialState: any =
{
  status: ApiStatus.FETCHED,
  contract: undefined,
  contractHistory: [],
  errorMessage: undefined,
  selectedContract: {} as any,
  action: '' as any,
}

const contractSlice = createSlice({
    name: 'contractSlice',
    initialState,
    reducers: {
        onFetchContracts (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.contract       = payload
            state.errorMessage = undefined
        },
        onFetchContractHistory (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.contractHistory       = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedContract (state, { payload }: { payload: any }) {
            state.selectedContract = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectContractState = (state: RootState) => state.contractSlice
export default contractSlice.reducer

export const {
    onFetchContracts,
    onFetchContractHistory,
    changeStatus,
    onSetSelectedContract,
    setAction,
} = contractSlice.actions