import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useContractStore } from "../../hooks/useContractStore";

const columns = [
    // { type: 'options', field: 'options', label: 'Opciones', align:'center' },
    { type: 'text', field: 'key', label: 'Llave', align:'center' },
    { type: 'text', field: 'value', label: 'Valor', align:'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const TemplateVariablesView = () => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { status: statusContract, listVariablesContract, setActionContract } = useContractStore();

    const [variables,setVaribales] = useState<any>([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getVariablesContractApi()
    },[search])

    const getVariablesContractApi = async() => {
        try {
            setLoading(true);
            const response = await listVariablesContract();
            if(response){
                setVaribales(response)
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const onAdd = () => {
        setActionContract('add')
        navigate({ pathname: RoutesMap.CREATE_VARIABLES })
    }

    return (
        <>

            <Grid item xs={12}>
                <CustomTable 
                    title={'Variables'}
                    columns={columns} 
                    loading={loading}
                    rows={variables || []}
                    onRowClick={() => {}}
                    onAddFn={onAdd}
                />
            </Grid>
        </>
    )
}