import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { Grid, Divider, TextField, Autocomplete, Typography, Select, MenuItem } from "@mui/material"
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { Owner } from "../../types/slices/ownerType";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { PreviewContractModal } from "./PreviewContractModal";
import { useContractStore } from "../../hooks/useContractStore";
import moment from "moment";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { PreviewContractTemplateModal } from "./PreviewContractTemplateModal";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";

type Values = {
  type: number,
  idnewowner: number|string,
  start_date: string,
  end_date: string,
  description: string,
  contract: string,
}

export const OwnerByDepartamentModal: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, row, owners } = props;

  console.log(row)

  const { ownersDistincs, selectedOwnerAdd, setSelectedOwnerAdd, getOwnersFilter, setOwnerDistincs } = useOwnerStore();
  const { departmentByOwner, addDepartmentOwner } = useDepartmentStore();
  const { createAsignature, listContractTemplates, listContractTemplatesOwner, listContractTemplatesForAgent } = useContractStore();
  const { user } = useAuthStore()
  const role = user?.userType;

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPreviewContract, setShowPreviewContract] = useState<boolean>(false);
  const [confirmPreviewContract, setConfirmPreviewContract] = useState<boolean>(false);

  const [typeRegister, setTypeRegister] = useState<any>(1);
  const [dataForm, setDataForm] = useState<any>({});

  const currentDate = new Date().toISOString().split('T')[0];

  const [ contractTemplates, setContractTemplates ] = useState<any>([])
  const [ contractSelected, setContractSelected ] = useState<any>('')

  const [data, setData] = useState<Values>({
    type: 1,
    idnewowner: "",
    start_date: "",
    end_date: "",
    description: "",
    
    contract: "",
  });

  const typeFormat = [
    {
      id: 1,
      name: "Comprador"
    },
    {
      id: 2,
      name: "Arrendatario"
    }
  ];

  useEffect(() => {
    getOwnersFilterApi();
    //nuevo formato de contrato
    listContractTemplatesApi();
  }, [])

  const getOwnersFilterApi = async (type?:any) => {
    try {      
      setLoading(true);
      const response = await getOwnersFilter(departmentByOwner,{privilege: type || 1});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const listContractTemplatesApi = async() => {
    try {
      setLoading(true);
      let response:any = '';
      if(role == Role.ADMIN){
        response = await listContractTemplates();
      }else if (role == Role.AGENT){
        response = await listContractTemplatesForAgent(row?.detail?.iddepartment);
      }else{
        response = await listContractTemplatesOwner();
      }

      let arrayContract:any = [];
      if(response && response.length > 0){
        response.map((item) => {
          let obj:any = {
            id: item?.contract?.id,
            title: item?.contract?.title,
            description: item?.contract?.description,
            type: item?.contract?.type,
          }
          arrayContract.push(obj)
        })
      }
      setContractTemplates(arrayContract || [])
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleChangeSelectOwner = (newValue:any, setFieldValue:any) => {
    setSelectedOwnerAdd(newValue || {} as Owner);
    setFieldValue('idnewowner', newValue ? newValue.idowner : "")
  }

  const validateForm = (values) => {
    let errors: any = {};
    if(!values.idnewowner) errors.idnewowner = "Campo requerido";
    if(!values.start_date) errors.start_date = "Campo requerido";
    if(!values.end_date && values.type == 2) errors.end_date = "Campo requerido";
    if(!values.contract) errors.contract = "Campo requerido";
    return errors;
  }

  const validateFilterType = (event: any, setFieldValue: any) => {
    setFieldValue("type", event.target.value);
    setOwnerDistincs();
    getOwnersFilterApi(event.target.value);
    setTypeRegister(event.target.value);

    //Propietario
    setSelectedOwnerAdd({} as Owner);
    setFieldValue('idnewowner', "")
    setFieldValue('description', "")
    setFieldValue('start_date', "")
    setFieldValue('end_date', "")

    //nuevo formato de contrato
    // if(event.target.value == 2){
    //   listContractTemplatesApi();
    // }
  }
  
  const onSubmit = async (formData) => {
    if(formData.end_date){
      let dateStart = moment(formData.start_date, "YYYY-MM-DD");
      let dateEnd = moment(formData.end_date, "YYYY-MM-DD");
      if(dateStart > dateEnd) return CustomSnackbar('warning', 'La fecha incial no puede ser menor a la fecha final.');
    }

    if (formData.type == 2 && !confirmPreviewContract) {
      setDataForm(formData)
      return setShowPreviewContract(true);
    }
    setLoadingSubmit(true);

    const ownerdepartment = (row?.owners && (row.owners).length > 0) ? row.owners.find(owner => owner.status == '1') : '';

    const data = {
      ...formData,
      iddepartment: row.detail.iddepartment,
      idowner: ownerdepartment ? ownerdepartment.idowner : '',
      idcontract: contractSelected ? contractSelected.id : '',
    }
    const response: any = await addDepartmentOwner(data);
    confirmPreviewContract && createAsignature(data);
    setLoadingSubmit(false);
    
    if (response?.status)  closeModal(true);
    
  }

  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={"Generar Contrato"}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({values, errors, touched, handleSubmit, setFieldValue, handleChange}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Tipo"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Select
                        id="type"
                        value={values.type}
                        size="small"
                        onChange={(event) => { 
                          validateFilterType(event, setFieldValue);
                          setConfirmPreviewContract(false);
                        }}
                        error={ errors.type && touched.type ? true : false }
                        fullWidth
                    >
                        {
                            typeFormat.map((type, i) => {
                                return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                            })
                        }
                    </Select>
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{ typeRegister == 2 ? "Arrendatario" :"Propietario"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={selectedOwnerAdd?.id ? selectedOwnerAdd : null}
                      options={ownersDistincs || []}
                      loading={loading}
                      getOptionLabel={(option) => option?.name || ""}
                      fullWidth
                      isOptionEqualToValue={(option, value) => option?.name === value?.name}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          size="small"
                          error={errors.idnewowner && touched.idnewowner ? true : false}
                          helperText={errors.idnewowner && touched.idnewowner ? errors.idnewowner : ''}
                        />
                      }
                      onChange={(e, newValue) => {
                        handleChangeSelectOwner(newValue, setFieldValue);
                        setConfirmPreviewContract(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Observación"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="description"
                      type="text" 
                      name="description"
                      fullWidth
                      size="small"
                      value={values.description}
                      onChange={handleChange}
                      error={errors.description && touched.description ? true : false}
                      helperText={errors.description && touched.description ? errors.description : ''}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Fecha inicial"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <TextField
                      id="start_date"
                      type="date" 
                      name="start_date"
                      fullWidth
                      size="small"
                      value={values.start_date}
                      onChange={(e) => {
                        handleChange(e);
                        setConfirmPreviewContract(false);
                      }}
                      error={errors.start_date && touched.start_date ? true : false}
                      helperText={errors.start_date && touched.start_date ? errors.start_date : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: currentDate,
                      }}
                    />
                  </Grid>
                </Grid>
                {
                  typeRegister == 2 && (
                    <>
                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>{"Fecha final"}:</b></Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <TextField
                          id="end_date"
                          type="date" 
                          name="end_date"
                          fullWidth
                          size="small"
                          value={values.end_date}
                          onChange={(e) => {
                            handleChange(e);
                            setConfirmPreviewContract(false);
                          }}
                          error={errors.end_date && touched.end_date ? true : false}
                          helperText={errors.end_date && touched.end_date ? errors.end_date : ''}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: currentDate,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                  )
                }
                {/* para el nuevo formato de contrato */}

                {
                  typeRegister == 1 && (
                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                      <Typography className="paragraph">
                        {'El contrato seleccionado será compartido con el propietario para que pueda usarlo como plantilla para sus futuros contratos.'}
                      </Typography>
                    </Grid>
                  )
                }

                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{typeRegister == 1 ? "Seleccione plantilla" : "Seleccione contrato"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={contractSelected}
                      options={contractTemplates || []}
                      loading={loading}
                      getOptionLabel={(option) => option?.title || ""}
                      fullWidth
                      isOptionEqualToValue={(option, value) => option?.title === value?.title}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          size="small"
                          error={errors.contract && touched.contract ? true : false}
                          helperText={errors.contract && touched.contract ? errors.contract : ''}
                        />
                      }
                      onChange={(e, newValue) => {
                        setContractSelected(newValue || '')
                        setFieldValue('contract', newValue || '')
                        setConfirmPreviewContract(false)
                      }}
                    />
                  </Grid>
                </Grid>

                <Divider />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={values.type == 2 && !confirmPreviewContract ? 'Vista Previa' : 'Guardar'}
                    onConfirm={handleSubmit}
                    loadingConfirmText={loadingSubmit}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                  />
                </Grid>
              </form>
            )
          }}
          </Formik>
        </ModalBody>
      </Modal>
      {
        showPreviewContract && !confirmPreviewContract && (
          // <PreviewContractTemplateModal
          <PreviewContractModal
            open={showPreviewContract}
            row={row}
            dataForm={dataForm}
            closeModal={() => setShowPreviewContract(false)}
            confirm={() => {
              setConfirmPreviewContract(true);
              setShowPreviewContract(false)
            }}
            contractSelected={contractSelected || ''}
          />
        )
      }
    </>
  )
}